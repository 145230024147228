<template>
  <div>
      <b-modal id="recipe-modal" hide-header scrollable :static="isStatic" >
          <b-img v-if="recipe.imageUrl" class="p-2" :src="recipe.imageUrl" center fluid-grow/>
          <div class="mx-3 mb-4">
            <h2 class="text-grey mt-4 mb-2" style="font-weight: bold">{{recipe.title}}</h2>
            <p class="mb-3">{{recipe.description}}</p>
            <div class="mx-3">
              <b-row class="" align-h="start">
                <b-col class="p-0" cols="auto" align-self="start" v-if="fullRecipe">
                  <b-dropdown variant="transparent" >
                    <template #button-content class="servings-dropdown-button">
                      <div>
                        <p class="grey-text" id="servings-text"><i class="material-icons grey-text icon-middle" style="font-size:22px !important"> people</i> {{ fullRecipe.servings || 2 }}</p>
                      </div>
                    </template>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(1)">1</b-dropdown-item>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(2)">2</b-dropdown-item>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(3)">3</b-dropdown-item>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(4)">4</b-dropdown-item>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(5)">5</b-dropdown-item>
                    <b-dropdown-item v-if="fullRecipe" @click.stop="updateServings(6)">6</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <b-row class="" align-h="start">
                <b-col class="p-0" cols="auto" align-self="start">
                  <p class="grey-text body-text">{{recipe.time}} mins</p>
                </b-col>
              </b-row>
              <b-row align-h="start">
                <b-col class="p-0"  cols="auto" align-self="start" v-if="fullRecipe && fullRecipe.getCalories()">
                  <p class="grey-text body-text">{{fullRecipe.getCalories().toFixed(0)}}cals</p>
                </b-col>
              </b-row>
            </div>
            <h3 class="mt-3 mb-2 text-grey">Ingredients</h3>
            <b-row align-h="center">
              <b-spinner v-if="!fullRecipeLoaded" class="text-center" label="Spinning" ></b-spinner>
            </b-row>
            <div v-if="fullRecipeLoaded">
              <ShoppingListItem v-for="ingredient of fullRecipe.ingredients" v-bind:key="ingredient.id" :item="ingredient" :servings="fullRecipe.servings"/>
            </div>
          </div>
          <div slot="modal-footer">
            <b-button-group>
              <b-button class="full-button recipe-button-close" @click="closeModal()" squared><i class="material-icons" style="font-size:30px;">close</i></b-button>
              <b-button class="full-button recipe-button-more" squared>
              <b-dropdown dropup>
                <template #button-content>
                  <i class="material-icons nav-icon align-bottom" >more_horiz</i>
                </template>
                <b-dropdown-item @click.stop="showPlanChooser">Add to another plan</b-dropdown-item>
                <b-dropdown-item v-if="fullRecipe && (!fullRecipe.servings || fullRecipe.servings === 2)" @click.stop="updateServings(4)">Increase servings</b-dropdown-item>
                <b-dropdown-item v-if="fullRecipe && fullRecipe.servings === 4" @click.stop="updateServings(2)">Decrease servings</b-dropdown-item>

              </b-dropdown>
            </b-button>
              <b-button id="button-add-to-plan" v-if="plan.planStatuses.indexOf('COOKING') === -1 && !recipeInPlan()"  class="full-button recipe-button-add" @click="addRecipeToPlan()" squared>ADD TO PLAN</b-button>
              <b-button id="button-remove-from-plan" v-if="plan.getStatus() === 'SHOPPING' && recipeInPlan()" class="full-button recipe-button-remove" @click="removeRecipeFromPlan()" squared>REMOVE FROM PLAN</b-button>
              <b-button v-if="plan.planStatuses.indexOf('COOKING') > -1 && recipeInPlan()" class="full-button recipe-button-add bold" @click="showMethod" variant="primary"  squared>COOK</b-button>
              <b-button v-if="plan.planStatuses.indexOf('COOKING') > -1 && !recipeInPlan()" class="full-button recipe-button-add bold" @click="addRecipeToPlan()" squared>ADD TO PLAN</b-button>
            </b-button-group>
          </div>
      </b-modal>
  </div>
</template>

<script>
import ShoppingListItem from './ShoppingListItem'
import {getRecipe} from '../clients/RecipeClient'
export default {
  props: ['recipe', 'plan', 'userState', 'isStatic'],
  components: {
    ShoppingListItem
  },
  data() {
    return {
      fullRecipe: undefined,
      fullRecipeLoaded: false
    }
  },
  methods: {
    addRecipeToPlan() {
      this.$emit("addRecipeToPlan", {plan: this.plan, recipe:this.recipe})
      this.closeModal()
    },
    async showPlanChooser() {
      this.$emit('showPlanChooser', this.recipe)
    },
    removeRecipeFromPlan() {
      this.$emit("removeRecipeFromPlan", {plan: this.plan, recipe:this.recipe})
      this.closeModal()
    },
    showMethod() {
      this.$bvModal.hide('recipe-modal')
      this.$router.push({ name: 'MethodPage', params:  {recipeId: this.recipe.id} })
    },
    recipeInPlan() {
      return this.plan.recipes.map(obj => obj.id).indexOf(this.recipe.id) > -1
    },
    closeModal() {
      this.$router.replace({query: {activeRecipe: undefined}})
      this.$bvModal.hide('recipe-modal')
    },
    updateServings(servings) {
      this.fullRecipe.servings = servings
      this.$emit('updateServings',  {plan: this.plan, recipe: this.fullRecipe})
    }
  },
  async mounted() {
    
    this.$bvModal.show('recipe-modal')
    if (this.recipe.ingredients) {
      this.fullRecipe = this.recipe
    }else {
      this.fullRecipe = await getRecipe(this.recipe.id)
      this.fullRecipe.servings = this.recipe.servings
    }
    this.fullRecipeLoaded = true
  },

}

</script>

<style>

.dropdown-toggle {
  padding: 0px !important;
  font-weight: 400;
}
.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit; /* [1] */
  z-index: 1055; /* [2] */
}

.full-button {
  min-height: 80px;
  margin: 0px !important;
  width: 50%
}

.modal-footer {
  padding: 0px !important;
  border: 0px;
}

.modal-footer div{
  margin: 0px !important;
  width: 100%
}

.modal-body {
  padding: 0px !important
}

@media (min-width: 576px) {
.modal-dialog {
    max-width: 400px !important;
    margin: 1.75rem auto;
}
}

.dropdown-toggle::after {
  display:none !important;
}

</style>
