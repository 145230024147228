<template>
  <div>
    <h4 id="recipe-uuid" @click="copyUUID()" v-if="recipe">{{this.form.id}}</h4>
    <h2 v-if="!recipe">Add Recipe</h2>
    <b-button class="my-1 mx-1" @click="onSubmit" size="sm" variant="primary">Submit</b-button>
    <b-button class="my-1 mx-1" @click="nextRecipe" size="sm" variant="primary">Next</b-button>
    <b-button class="my-1 mx-1" @click="submitAndNext" size="sm" variant="primary">Submit and Next</b-button>
    <b-form @submit="onSubmit" >
        <b-row>
          <b-col cols="2">
           <label for="input-1">Name</label>
         </b-col>
          <b-col cols="10">
           <b-form-input
             id="input-1"
             v-model="form.name"
             placeholder="Enter Name"
             required
           ></b-form-input>
         </b-col>
       </b-row>
       <b-row class="mt-2">
          <b-col cols="2">
           <label for="input-1">Cuisine</label>
         </b-col>
          <b-col cols="5">
            <b-form-select
            ref ="cuisineType"
             v-model="form.cuisine.type"
            :options="cuisineTypeOptions"
          ></b-form-select>
         </b-col>
         <b-col cols="5">
           <b-form-input v-on:keydown.enter.prevent="submitAndNext"
             id="input-1"
             v-model="form.cuisine.name"
             placeholder="Name"
             required
           ></b-form-input>
         </b-col>
       </b-row>
       <b-row class="mt-2" >
         <b-col cols="2">
          <label for="input-1">Description</label>
        </b-col>
         <b-col cols="10">
          <b-form-textarea
            id="input-1"
            v-model="form.description"
            placeholder="Enter description"
            rows="4"
          ></b-form-textarea>
        </b-col>
      </b-row>
       <b-row class="mt-2">
         <b-col cols="2">
          <label for="input-2">Time (mins)  </label>
        </b-col>
         <b-col cols="3">
          <b-form-input
            id="input-2"
            v-model="form.time"
            type = "number"
            required
          ></b-form-input>
        </b-col>
        <b-col cols="2">
         <label >Hidden </label>
       </b-col>
        <b-col cols="2">
         <b-form-checkbox
           v-model="form.hidden"
         ></b-form-checkbox>
       </b-col>
       <b-col cols="1">
          <i class="material-icons nav-icon" @click="downloadRecipe">download</i>
        </b-col>
        <b-col cols="1">
           <i class="material-icons nav-icon" @click="saveRecipe">save</i>
         </b-col>
      </b-row>
       <h3> Ingredients (cals={{getRunningCalories()}})</h3>
       <div v-if="ingredients" >
       <b-row class="mt-2" v-for="(ingredient, index) of form.ingredients" v-bind:key="index">
         <b-col cols="1">
          <label for="input-1">{{index + 1}}</label>
        </b-col>
         <b-col cols="4">
           <b-form-select
             v-model="ingredient.name"
             :options="getIngredientOptions()"
             v-on:change="ingredientSelected(ingredient, index)"
           ></b-form-select>
        </b-col>
        <b-col cols="3">
          <b-form-select
            v-model="ingredient.unit"
            :options="listAvailableUnits(ingredient)"
            v-on:change="unitSelected(ingredient, index)"
          ></b-form-select>
       </b-col>
       <b-col cols="2">
         <b-form-select
           v-model="ingredient.quantity"
           :options="listAvailableQuantities(ingredient)"
           v-on:change="quantitySelected(ingredient, index)"
         ></b-form-select>
      </b-col>
      <b-col cols="2">
        <div>{{getCalories(ingredient, ingredient.quantity)}}</div>
     </b-col>
      </b-row>
      <b-row class="mt-2" v-for="(step, index) of form.method" v-bind:key="'step_' +  index">

        <b-col cols="1">
         <label for="input-1">{{index}}</label>
       </b-col>
        <b-col cols="11">
          <b-form-textarea
            id="input-3"
            v-model="step.text"
            rows="3"
          ></b-form-textarea>
       </b-col>
     </b-row>
     <b-row class="mt-2" >
       <h3>Siblings</h3>
       <b-input v-model="filter" placeholder="filter"></b-input>
       <b-button class="mt-2" v-for="(sibling, siblingIndex) of siblings" v-bind:key="siblingIndex" @click="removeSibling(sibling.id)">{{sibling.title}} {{sibling.id.substr(0,5)}} <i class="material-icons nav-icon">close</i></b-button>
       <b-table :fields="siblingFields" hover :items="recipesToShow" selectable select-mode="single" v-on:row-selected="addSibling"></b-table>
    </b-row>
   </div>
     <b-button class="mt-2" type="submit" variant="primary">Submit</b-button>
     <b-button v-if="recipe" class="mt-2 ml-2" type="button" variant="primary" @click="onSubmit('createNew')">Create New</b-button>
   </b-form>
   <b-alert :show="errorAlert" dismissible @dismissed="errorAlert = false" variant="danger">Issue creating recipe</b-alert>
   <b-alert :show="successAlert" dismissible @dismissed="successAlert = true"  variant="success">Recipe Created</b-alert>
  </div>
</template>

<script>

import {createRecipe} from '../clients/RecipeClient'
import Ingredient from '../models/Ingredient'
export default {
  props: ['ingredients', 'recipe', 'recipes', 'reloadCounter'],
  data() {
      return {
        unit: undefined,
        filter: undefined,
        recipesToShow: [],
        siblings: [],
        form: {
          id: undefined,
          name: '',
          hidden: false,
          ingredients: [],
          method: [{},{},{},{},{},{},{},{}],
          description: undefined,
          cuisine: {
            type: undefined,
            name: undefined
          }
        },
        ingredientsList: undefined,
        errorAlert: false,
        successAlert: false,
        siblingFields: ['title', {
          key: 'id', formatter: obj => obj.substr(0,5)
        }],
        cuisineTypeOptions: ['american', 'asian', 'european', 'middle eastern']
      }
    },
    methods: {
      async onSubmit(event) {
        this.successAlert = false
        if (event !== 'createNew' && event !== 'submitAndNext') event.preventDefault()
          this.form.siblingIds = this.siblings.map(obj => {
            if (!this.recipe.id || this.recipe.id !== obj.id) return obj.id
          }).filter(obj => obj)
          this.form.siblingIds = Array.from(new Set([...this.form.siblingIds]))
        
        
        if (event === 'createNew' || event === 'submitAndNext') {
          this.form.id = undefined
        }
        
        let response = await createRecipe(this.form)
        
        if (response.createRecipe && response.createRecipe.id) {
          this.successAlert = true
          this.errorAlert = false
          this.form.id = response.createRecipe.id
        }else {
          this.successAlert = false
          this.errorAlert = true
        }
        let idToSend = this.form.id
        this.$emit("recipeCreated", idToSend)
        this.recipesToShow = this.recipes.filter(obj => (!this.recipe || !this.recipe.id || (obj.id !== this.recipe.id && obj.id !== this.form.id)) && obj.title.toLowerCase().indexOf(this.filter) > -1 && this.recipe && this.recipe.siblingIds.indexOf(obj.id) === -1).slice(0,5)

        if (!this.filter) this.recipesToShow = []
      },
      async submitAndNext() {
        await this.onSubmit('submitAndNext')
        this.nextRecipe()
        this.$refs.cuisineType.focus();
      },
      nextRecipe() {
        this.$emit('nextRecipe')
      },
      ingredientSelected(ingredient, index) {
        const availableUnits = this.listAvailableUnits(ingredient)
        const matchingIngredient = this.ingredients.filter(i => i.name === ingredient.name)[0]
        if (matchingIngredient) {
          ingredient.store = matchingIngredient.store
          ingredient.id = matchingIngredient.id
          ingredient.amounts = matchingIngredient.amounts
          ingredient.calories = matchingIngredient.calories
          ingredient.aisle = matchingIngredient.aisle
          ingredient.freezable = matchingIngredient.freezable
        }
        this.saveRecipe()
      },
      addSibling(siblings) {
        if (siblings[0]) {
          this.siblings.push(siblings[0])
        }
        //Array.from(new Set([...arr]))
      },
      removeSibling(id) {
        this.siblings = this.siblings.filter(obj => obj.id !== id)
      },
      unitSelected(ingredient, index) {
        this.saveRecipe()
      },
      getIngredientOptions() {
        var options = this.ingredients.map(i => i.name)
        options.unshift('')
        return options
      },
      quantitySelected(ingredient, index) {
        const quantities = this.ingredients.filter(i => i.name === ingredient.name)[0].amounts.filter(obj => obj.unit === ingredient.unit)
        ingredient.partial = quantities.filter(obj => obj.quantity === ingredient.quantity)[0].partial

      },
      listAvailableUnits(ingredient) {
        if (ingredient.name) {
          return Array.from(new Set(this.ingredients.filter(i => i.name === ingredient.name)[0].amounts.filter(obj => obj.quantity).map(obj => obj.unit)))
        }
        return []
      },
      getCalories(ingredient) {
        if (ingredient.calories) {
          var caloriesForUnit = ingredient.calories.filter(obj => obj.unit === ingredient.unit)[0]
          if (caloriesForUnit) {
            const ingredientCalories = (ingredient.quantity / caloriesForUnit.quantity) * caloriesForUnit.calories
            return ingredientCalories.toFixed(0)
          }
        }
      },
      listAvailableQuantities(ingredient) {
        if (ingredient.name && ingredient.unit) {
          return Array.from(new Set(this.ingredients.filter(i => i.name === ingredient.name)[0].amounts.filter(obj => obj.unit === ingredient.unit && obj.quantity).map(obj => obj.quantity)))
        }
        return []
      },
      saveRecipe() {
        localStorage.setItem("recipe", JSON.stringify(this.form))
      },
      downloadRecipe() {
        var cachedRecipe = localStorage.getItem("recipe")
        if (cachedRecipe) {
          this.form = JSON.parse(cachedRecipe)
          while (this.form.ingredients.length < 20) this.form.ingredients.push(new Ingredient())
        }
      },
      getRunningCalories() {
        var totalCalories = 0
        for (var ingredient of this.form.ingredients) {
          if (ingredient.calories) {
            var caloriesForUnit = ingredient.calories.filter(obj => obj.unit === ingredient.unit)[0]
            if (caloriesForUnit) {
              const ingredientCalories = (ingredient.quantity / caloriesForUnit.quantity) * caloriesForUnit.calories
              totalCalories+=ingredientCalories
            }
          }
        }
        return 1* (totalCalories / 2).toFixed(0)
      },
      populateForm() {
        this.recipe.updateIngredients(this.ingredients)
        this.recipe.getCalories().toFixed(0)
        this.form.name = this.recipe.title
        this.form.time = this.recipe.time
        this.form.id = this.recipe.id
        this.form.method = this.recipe.method
        this.form.description = this.recipe.description
        if (this.recipe.cuisine) {
          this.form.cuisine = this.recipe.cuisine
        }else {
          this.form.cuisine = {
            type: undefined, name: undefined
          }
        }
        if (this.recipe.siblingIds) {
          this.siblings = this.recipes.filter(recipe => this.recipe.siblingIds.indexOf(recipe.id) > -1)
        }else {
          this.siblings = []
        }
        while (this.form.method.length < 10) this.form.method.push({})
        this.form.hidden = this.recipe.hidden || false
        if (this.recipe.ingredients[0] && this.recipe.ingredients[0].amounts) {
          this.form.ingredients = this.recipe.ingredients
        }else {
          this.form.ingredients = []
        }
        while (this.form.ingredients.length < 20) this.form.ingredients.push(new Ingredient())
      },
      copyUUID () {
        navigator.clipboard.writeText(this.recipe.id)
      }
    },
    mounted() {
      if (this.ingredients) this.ingredientsList = createIngredientsList(this.ingredients)
      if (this.recipe) {
        this.populateForm()
      }

      while (this.form.ingredients.length < 20) this.form.ingredients.push(new Ingredient())
    },
    watch : {
      recipe() {
        this.populateForm()
      },
      filter() {
        this.recipesToShow = this.recipes.filter(obj => (!this.recipe || !this.recipe.id || (obj.id !== this.recipe.id)) && obj.title.toLowerCase().indexOf(this.filter) > -1 && (!this.recipe || this.recipe.siblingIds.indexOf(obj.id) === -1)).slice(0,5)
        if (!this.filter) this.recipesToShow = []
      }
    }
}

function createIngredientsList(ingredients) {
  var newList = []
  for (var ingredient of ingredients) {
    for (var amount of ingredient.amounts) {
      newList.push( {
        id: ingredient.id,
        store: ingredient.store,
        name: ingredient.name,
        quantity: ingredient.quantity,
        unit: ingredient.unit,
        partial: ingredient.partial
      })
    }
  }
  return newList
}
</script>

<style>

</style>
