
<template>
  <div>
    <div class="footer p-1">
    <b-container >
      <b-row>
        <b-col cols="12" lg="12" class="mb-2">
          <a href="/privacy" class="" style="font-size:14px; color: #d1d1d1">Privacy Policy</a>
        </b-col>
      </b-row>
    </b-container>
    </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  }
}

</script>

<style>

.footer {
  background-color: var(--text-secondary);
  width: 100%;
  border-top: 1px solid #8f8f8f;
}
</style>
