import { request, GraphQLClient, gql } from 'graphql-request'
import ShoppingList from '../models/ShoppingList'
import UserState from '../models/UserState'
import Plan from '../models/Plan'
import Recipe from '../models/Recipe'
import axios from 'axios';
import router from '../router'
const authUrl = `https://recipefinder.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=5u1h41f28v6e2gpgitpfl4uld2&redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT_URL}`
import { Auth } from 'aws-amplify';
let suggestionCache = {}
let stateCache
let recipeCache
let weeklyRecipeCache
const useStateCache = true
const useRecipeCache = true
const useWeeklyRecipeCache = true
let stateCacheDate

export async function createIngredient(ingredient) {
  ingredient.name = ingredient.name.toLowerCase()
  ingredient.calories = ingredient.calories.filter(obj => obj.unit) //Filter any empty values
  ingredient.amounts = ingredient.amounts.filter(obj => obj.unit && obj.quantity) //Filter any empty values
  ingredient.amounts = ingredient.amounts.map(obj => {
    obj.partial = obj.partial || false
    return obj
  })

  const mutation = gql`
  mutation createIngredient($createIngredientInput: CreateIngredientInput!) {
    createIngredient(input: $createIngredientInput) {
      id
      name
    }
  }`

  const variables = {
    createIngredientInput: ingredient
  }
  var data = await makeGraphQLRequest(mutation, variables)
  return data
}

export async function listIngredients() {

const query = gql`
  query listIngredients {
    listIngredients(limit: 1000) {
      items {
        id
        name
        unit
        quantity
        freezable
        store
        partial
        aisle
        life
        allergens
        canBeStore
        amounts {
          unit
          quantity
          partial
        }
        calories {
          unit
          quantity
          calories
        }
      }
    }
  }`
  var data = await makeGraphQLRequest(query)
  return data.listIngredients.items.sort((a,b) => a.name < b.name? -1: 1)

}

export async function updateUser(user) {
const mutation = gql`
mutation updateUser($user: UpdateUserInput!) {
  updateUser(input: $user) {
    id
  }
}`
const variables = {
  user: user
}
var data = await makeGraphQLRequest(mutation, variables)
return data
}

export async function updateCustomShoppingListItems(items) {
const mutation = gql`
mutation updateCustomShoppingListItems($items: CustomShoppingListItemsInput!) {
  updateCustomShoppingListItems(input: $items) {
    name
  }
}`

//Yuk
const variables = {
  items: {items: items}
}

var data = await makeGraphQLRequest(mutation, variables)
return data
}

export async function getCustomShoppingListItems() {
  const query = gql`
  query getCustomShoppingListItems {
    getCustomShoppingListItems {
      name
      checked
    }
  }`

  const data = await makeGraphQLRequest(query)
  return data.getCustomShoppingListItems
}


export async function getUser() {
  const query = gql`
  query User {
    User {
      id
      cookiesEnabled
      guideSeen
    }
  }`

  const data = await makeGraphQLRequest(query)
  return data.User

}

export function clearUserStateCache() {
  stateCache = undefined
}

export async function getUserState() {
  if (stateCache && new Date() - stateCacheDate < (1000 * 2)) { //2 second caching
    return stateCache
  }
  const query = gql`
  query UserState {
    UserState {
      leftovers {
        ingredient {
          id
          life
          name
        }
        remainingLife
        quantity
        unit
        created
        bought
        planId
        action
        reservations {
          planId
          quantity
        }
      }
      storeItems {
        id
        name
      }
      date
    }
  }`

  const data = await makeGraphQLRequest(query)

  if (data){
    stateCache = new UserState(data.UserState)
    stateCacheDate = new Date()
    return stateCache
  }
}

export async function updateStateItem(input) {
  const mutation = gql`
  mutation updateStateItem($input: UpdateStateItemInput!) {
    updateStateItem(input: $input) {
        id
    }
  }`

  const variables = {
    input: input
  }

  var data = await makeGraphQLRequest(mutation, variables)
  return data
}

function getSuggestionCacheId(recipeIds, partials, type) {
  return partials.map(partial => `${partial.ingredientId}:${partial.unit}:${partial.leftoverQuantity}`).join("#") + ":" + recipeIds.join("#") + ":" + type
}
export function clearPartialSuggestionsCache() {
    suggestionCache = {}
}

function getSuggestionFromCache(cacheId) {
  return suggestionCache[cacheId]
}

export async function getPartialSuggestions(recipeIds, partials, type) {
  const cacheId = getSuggestionCacheId(recipeIds, partials, type)
  const entryInCache = getSuggestionFromCache(cacheId)
  if (entryInCache) {
    console.log("Using recipe cache")
    return entryInCache
  }

  const query = gql`
  query getPartialSuggestions($partialsInput: PartialsInput) {
  getSuggestionsForPartials(input: $partialsInput) {
    items {
      score
      type
      recipeToSwap
      partials {
        ingredientId
        leftoverQuantity
        life
        unit
        name
        planId
      }
      recipes {
        title
        id
        time
        imageUrl
        siblingIds
        ingredients {
          id
          name
          unit
          quantity
          partial
          aisle
          life
          store
          canBeStore
          amounts {
            unit
            quantity
            partial
          }
          calories {
            unit
            quantity
            calories
          }
        }
      }
    }
  }
  }`

  const variables = {
    partialsInput: {
      recipeIds: recipeIds,
      partials: partials,
      type : type
    }
  }
  const data = await makeGraphQLRequest(query, variables)
  if (data) {
    data.getSuggestionsForPartials.items.forEach(item => {
      item.recipes = item.recipes.map(obj => new Recipe(obj))
    })
    suggestionCache[cacheId] = data.getSuggestionsForPartials.items
    return data.getSuggestionsForPartials.items
  }
}

export async function getRecipe(id) {
  const query = gql`
  query getRecipe {
    getRecipe(id: "${id}") {
      id
      title
      imageUrl
      description
      cuisine {
        type
        name
      }
      ingredients {
        name
        unit
        quantity
        store
        allergens
        id
        aisle
        amounts {
          partial
          unit
          quantity
        }
        calories {
          unit
          quantity
          calories
        }
      }
      method {
        text
      }
    }
  }`
  const data = await makeGraphQLRequest(query)
  return new Recipe(data.getRecipe)
}

export async function getPartialStats() {
  const query = gql`
  query getPartialStats {
    listPartialsStats {
      stats
    }
  }`

  const data = await makeGraphQLRequest(query)
  return JSON.parse(data.listPartialsStats.stats)
}



export async function getUserStats() {
  const query = gql`
  query UserStats {
    UserStats {
      stats
    }
  }`

  const data = await makeGraphQLRequest(query)
  return JSON.parse(data.UserStats.stats)
}
export async function getFullRecipe(id) {
  const query = gql`
  query getRecipe {
    getRecipe(id: "${id}") {
      id
      title
      siblingIds
      created
      ingredients {
        name
        unit
        quantity
        store
        allergens
        id
        aisle
        canBeStore
        amounts {
          partial
          unit
          quantity
        }
        calories {
          unit
          quantity
          calories
        }
      }
      method{
        text
      }
      imageUrl
      time
    }
  }`

  const data = await makeGraphQLRequest(query)
  return new Recipe(data.getRecipe)
}

export async function clearRecipeCache() {
  recipeCache = undefined
}

export async function clearWeeklyRecipeCache() {
  weeklyRecipeCache = undefined
}

export async function listRecipes(recipeList, nextToken) {
  if (useRecipeCache && recipeCache && !recipeList) {
    console.log("Used recipe cache")
    return recipeCache
  }

  if (!recipeList) recipeList = []
  const query = gql`
    query listRecipes  {
      listRecipes (nextToken: "${nextToken || ''}"){
        nextToken
        items {
         id
         title
         created
         description
         siblingIds
         ingredients {
           name
           unit
           quantity
           store
           freezable
           id
           life
           allergens
           aisle
           canBeStore
           amounts {
             partial
             unit
             quantity
           }
           calories {
             unit
             quantity
             calories
           }
         }
         imageUrl
         time
       }
      }
    }`
    var data = await makeGraphQLRequest(query)
    recipeList = recipeList.concat(data.listRecipes.items.map(recipe => new Recipe(recipe)))
    if (data.listRecipes.nextToken) {
      recipeList = await listRecipes(recipeList, data.listRecipes.nextToken)
      return recipeList
    }else {
      recipeCache = recipeList.sort((a,b) => a.title < b.title? -1: 1)
      return recipeCache
    }
}


export async function listWeeklyRecipes() {
  if (useWeeklyRecipeCache && weeklyRecipeCache) {
    console.log("Used recipe cache")
    return weeklyRecipeCache
  }

  const query = gql`
    query listWeeklyRecipes  {
      listWeeklyRecipes {
        items {
         id
         title
         created
         description
         siblingIds
         cuisine {
          type
          name
         }
         ingredients {
           name
           unit
           quantity
           store
           allergens
           freezable
           id
           life
           aisle
           canBeStore
           amounts {
             partial
             unit
             quantity
           }
           calories {
             unit
             quantity
             calories
           }
         }
         imageUrl
         time
       }
      }
    }`
    
    const data = await makeGraphQLRequest(query)
    let recipeList = data.listWeeklyRecipes.items.map(recipe => new Recipe(recipe))
    weeklyRecipeCache = recipeList.sort((a,b) => a.title < b.title? -1: 1)
    return weeklyRecipeCache
}

  export async function listAllRecipes(recipeList, nextToken) {
    if (!recipeList) recipeList = []
    const query = gql`
    query listAllRecipes {
      listAllRecipes (nextToken: "${nextToken || ''}"){
        nextToken
        items {
         id
         title
         hidden
         description
         cuisine {
          type
          name
         }
         siblingIds
         ingredients {
           name
           unit
           quantity
           allergens
           store
           id
           aisle
           amounts {
             partial
             unit
             quantity
           }
           calories {
             unit
             quantity
             calories
           }
         }
         method{
           text
         }
         imageUrl
         time
       }
      }
    }`
  var data = await makeGraphQLRequest(query)
  recipeList = recipeList.concat(data.listAllRecipes.items)
  if (data.listAllRecipes.nextToken) {
    recipeList = await listAllRecipes(recipeList, data.listAllRecipes.nextToken)
    return recipeList
  }else {
    return recipeList.sort((a,b) => a.title < b.title? -1: 1).map(recipe => new Recipe(recipe))
  }
}

export async function createRecipe(recipe) {
  const mutation = gql`
  mutation createRecipe($createRecipeInput: CreateRecipeInput!) {
    createRecipe(input: $createRecipeInput) {
      id
      title
      ingredients {
        name
      }
      method {
        text
      }
    }
  }`

  let createRecipeVariables = {
   createRecipeInput: {
     title: recipe.name,
     time: recipe.time,
     hidden: recipe.hidden,
     siblingIds: recipe.siblingIds,
     id: recipe.id,
     description: recipe.description
   }
  }

  if (recipe.cuisine) {
    createRecipeVariables.createRecipeInput.cuisine = {
      type: recipe.cuisine.type.toLowerCase(),
      name: recipe.cuisine.name.toLowerCase(),
    }
  }

  if (recipe.ingredients) createRecipeVariables.createRecipeInput.ingredients = recipe.ingredients.filter(ingredient => ingredient.name).map(ingredient => {
    return {
      id: ingredient.id,
      quantity: ingredient.quantity,
      unit: ingredient.unit
    }
  })

  if (recipe.method) createRecipeVariables.createRecipeInput.method = recipe.method.filter(obj => obj.text)

  const data = await makeGraphQLRequest(mutation, createRecipeVariables)
  return data

}

export async function reserveStateIngredient(input) {

  const mutation = gql`
  mutation reserveStateItem($reserveStateItemInput: ReserveStateItemInput!) {
    reserveStateItem(input: $reserveStateItemInput) {
      success
    }
  }`

  const variables = {
   reserveStateItemInput: input
  }
  const data = await makeGraphQLRequest(mutation, variables)
  return data

}

export async function createUserLeftover(userLeftovers) {
  const mutation = gql`
  mutation createUserLeftovers($userLeftoversInput: UserLeftoversInput!) {
    createUserLeftovers(input: $userLeftoversInput) {
      ingredient {
        id
      }
    }
  }`

  const variables = {
    userLeftoversInput: {
      items: userLeftovers
    }
  }
  const data = await makeGraphQLRequest(mutation, variables)
  clearUserStateCache()
  return data
}

export async function getUserLeftovers(planId) {
  let filter = {}
  if (planId) filter = {planId : planId}

  const query = gql`
  query getUserLeftovers($userLeftoversFilter: TableUserLeftoverFilterInput) {
    getUserLeftovers (filter: $userLeftoversFilter){
      items {
       created
       ingredient {
           id
           name
         }
      bought
       action
     }
      nextToken
    }
  }`
  const variables = {
    userLeftoversFilter : filter
  }
  var data = await makeGraphQLRequest(query, variables)
  return data.getUserLeftovers.items
}

export async function savePlan(plan) {
  if (plan.recipes) {
      plan.recipes = plan.recipes.map(obj => {
      return {id : obj.id, servings: obj.servings}
    })
  }

  const mutation = gql`
  mutation createPlan($createPlanInput: CreatePlanInput!) {
    createPlan(input: $createPlanInput) {
      id
      created
      updated
    }
  }`
  const variables = {
    createPlanInput: plan
  }

  const data = await makeGraphQLRequest(mutation, variables)
  return data
}

//This is only used for archiving a plan, all other update operations should use savePlan
export async function updatePlan(plan) {

  const updatePlanInput = {
    id: plan.id,
    archived: plan.archived
  }

  const mutation = gql`
  mutation updatePlan($updatePlanInput: UpdatePlanInput!) {
    updatePlan(input: $updatePlanInput) {
      id
    }
  }`

  const variables = {
    updatePlanInput: updatePlanInput
  }


  const data = await makeGraphQLRequest(mutation, variables)
  return data
}

export async function loadPlans(planList, nextToken) {
  if (!planList) planList = []
  const filter = {archived: {ne : true}}
  
  const query = gql`
  query listPlans($tablePlanFilterInput: TablePlanFilterInput) {
    listPlans (filter: $tablePlanFilterInput, nextToken: "${nextToken || ''}", limit: 100){
      items {
        id
        userId
        created
        updated
        planStatuses
        recipes {
          id
          title
          time
          imageUrl
          day
          servings
          siblingIds
          ingredients {
            id
            name
            unit
            store
            canBeStore
            allergens
            quantity
            aisle
            calories{
              quantity
              unit
              calories
            }
            partial
            life
            checked
            amounts {
              quantity
              unit
              partial
            }
          }
        }
        shoppingList {
          ingredients {
            id
            name
            unit
            store
            canBeStore
            quantity
            aisle
            partial
            life
            checked
            amounts {
              quantity
              unit
              partial
            }
          }
          storeIngredientsInList
          customItems {
            name
            checked
          }
        }
      }
      nextToken
    }
  }`

  const variables = {
    tablePlanFilterInput : filter
  }

  let data = await makeGraphQLRequest(query, variables)
  planList = planList.concat(data.listPlans.items.map(plan => new Plan(plan)))
  if (data.listPlans.nextToken) {
    planList = await loadPlans(planList, data.listPlans.nextToken)
    return planList
  }else {
    return planList.sort((a,b) => new Date(a.created) - new Date(b.created))
  }
}

export async function getPlan(id) {
  const query = gql`
  query getPlan {
    getPlan(id: "${id}") {
      id
      archived
      shoppingList {
        ingredients {
          id
          name
          unit
          store
          canBeStore
          quantity
          aisle
          partial
          life
          checked
          amounts {
            quantity
            unit
            partial
          }
        }
        storeIngredientsInList
        customItems {
          name
          checked
        }
      }
      recipes {
        id
        title
        time
        imageUrl
        day
        siblingIds
        ingredients {
          id
          canBeStore
          name
          unit
          store
          aisle
          freezable
          allergens
          life
          quantity
          amounts {
            quantity
            unit
            partial
          }
          calories {
            unit
            quantity
            calories
          }
        }
      }
    }
  }`

  const data = await makeGraphQLRequest(query)

  if (data && data.getPlan) return new Plan(data.getPlan)
}

async function checkAuth() {
  try {
    var session = await Auth.currentSession()
    return {
      id_token: session.idToken.jwtToken
    }
  }catch (err) {
    console.log(err)
    router.push("/").catch((error) => {});
  }
}

async function makeGraphQLRequest(document, variables) {
  const endpoint = 'https://api.leanlarder.com/graphql'
  const token = await checkAuth()
  var headers = {}
  if (token) {
    headers.Authorization = token.id_token
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: headers
    })
    try {
      const data = await graphQLClient.request(document, variables)
      return data
    }catch (err) {
      console.error(err)
    }
  }

}
