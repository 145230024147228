<template>
  <div>
    <NavBarPublic :plan="plan" />
    <b-container class="nav-padding pb-5" >

      <b-row>
        <b-col cols="12">
          <b-card  class="pt-2 pb-5 px-4">
            <div v-if="content" v-html="content"> </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    </div>
</template>

<script>
import NavBarPublic from './NavBarPublic'
import axios from 'axios'
export default {
  components: {
    NavBarPublic
  },
  props: ['plan'],
  data() {
    return {
      content: undefined
    }
  },
  methods: {
    async loadPolicy() {
      var fileName = "privacy-policy"
      if (this.$route.name === "CookiePolicy") fileName = "cookie-policy"
      var res = await axios.get(`/${fileName}.html`)
      this.content = res.data
    }
  },
  async mounted() {
    this.loadPolicy()
  },
  watch: {
  }
}

</script>

<style>

</style>
