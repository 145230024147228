<template>
  <div>
    <b-modal id="leftover-modal"  hide-header scrollable >
      <div v-if="loadingLeftovers || (leftovers && leftovers.length === 0)" class="d-flex justify-content-center mb-3 mt-5">
      <b-spinner  class="mb-4" label="Spinning" variant="dark" style="text-align: center" ></b-spinner>
    </div>
      <div v-if="!loadingLeftovers && leftovers && leftovers.length > 0">
      <p class="p-2">What did you do with your leftovers? </p>
      <b-list-group v-if="leftovers">
        <b-list-group-item v-for="leftover of leftovers.filter(obj => !obj.ingredient.canBeStore)"  v-bind:key="leftover.ingredientId" >
          <b-row align-h="center">
            <b-col cols="auto" class="capitalize text-center mb-2" >
              <p class="bold mb-3" style="float:left; width: auto">{{leftover.name}}</p>
              <b-badge class="quantity-badge mr-2 ml-2" variant="primary" pill>{{`${leftover.quantity}${leftover.unit === 'units'? '' : ` ${leftover.unit}`}`}}</b-badge>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row no-gutters>
                <b-col cols="3" v-for="option of optionsForLeftover(leftover)" v-bind:key="option.name" >
                  <LeftoverModalItem  style="height: 100%" :leftover="leftover" :option="option" @optionSelected="optionSelected" :leftoverItemReloadCounter="leftoverItemReloadCounter"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="leftover.option ==='used_leftover'" class="mt-2">
              <b-col cols="12">
                <p>Was it any of these?</p>
                <b-list-group class="mt-2">
                  <b-list-group-item v-for="item of getStateOptions(leftover)" v-bind:key="item.id" class="px-4 clickable" :active="stateOptionActive(item, leftover)" @click="stateOptionSelected(item, leftover)">
                    <b-row>
                      <p class="capitalize">{{item.name}}</p>
                    </b-row>
                    <b-row v-if="item.created">
                      <p class="text-small">From about {{item.created}}</p>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <p class="p-2" v-if="reservations && reservations.length > 0">Did you use up these previous leftovers?</p>
      <b-list-group>
        <b-list-group-item v-for="reservation of reservations"  v-bind:key="reservation.ingredientId" >
          <b-row align-h="center">
            <b-col cols="auto" class="capitalize text-center mb-2" >
              <p class="bold mb-3" style="float:left; width: auto">{{reservation.name}}</p>
              <b-badge class="quantity-badge mr-2 ml-2" variant="primary" pill>{{`${reservation.quantity}${reservation.unit === 'units'? '' : ` ${reservation.unit}`}`}}</b-badge>
            </b-col>
          </b-row>
          <b-row align-h="center" style="min-height:50px" align-v="center" no-gutters>
              <b-col cols="12" class="text-center">
                <b-button-group style="height: 60px">
                  <b-button class="px-5" :variant="usedReservations.indexOf(`${reservation.ingredientId}_${reservation.originalPlanId}`) > -1? '2' : 'btn-secondary'" @click="markReservationUsed(true, reservation)" >Yes</b-button>
                  <b-button class="px-5" :variant="usedReservations.indexOf(`${reservation.ingredientId}_${reservation.originalPlanId}`) === -1? '2' : 'btn-secondary'" @click="markReservationUsed(false, reservation)" >No</b-button>
                </b-button-group>
              </b-col>
          </b-row>

        </b-list-group-item>
      </b-list-group>
      </div>
      <div slot="modal-footer">
        <div v-if="leftovers && leftovers.length > 0">
          <b-button-group v-if="!loadingLeftovers">
            <b-button class="full-button" @click="markPlanCooked(false)" squared>Skip</b-button>
            <b-button :class="`full-button ${leftovers && leftovers.filter(obj => !obj.option).length === 0? 'recipe-button-add':''}`" @click="markPlanCooked(true)" squared>
              <p v-if="!loading">Save</p>
              <b-spinner v-if="loading" small label="Spinning" variant="secondary" ></b-spinner>
            </b-button>
          </b-button-group>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import LeftoverModalItem from './LeftoverModalItem'
import {createUserLeftover, getUserLeftovers, getUserState, updateStateItem, reserveStateIngredient} from '../clients/RecipeClient'
import moment from 'moment'
export default {
  components: {
    LeftoverModalItem
  },
  props: ['plan', 'leftoverModalReloadCounter'],
  data() {
    return {
      leftovers: undefined,
      options: [
        //{ text: "Used Up A Leftover", name: 'used_leftover', icon: 'grade'},
        { text: "Used It Elsewhere", name: 'used', icon: 'check_circle', clicked: 'false'},
        { text: "Kept It", name: 'kept', icon: 'kitchen', clicked: 'false'},
        { text: "Binned It", name: 'binned', icon: 'delete'},
        { text: "Froze It", name: 'froze', icon: 'ac_unit'},
        { text: "Bought the right amount", name: 'right_amount', icon: 'shopping_cart'},
        { text: "Other", name: 'other', icon: 'circle'},

      ],
      leftoverItemReloadCounter: 0,
      loading: false,
      loadingLeftovers: false,
      previouslySavedLeftovers: undefined,
      userState: undefined,
      selectedStateOptions: [],
      reservations: undefined,
      usedReservations: []
    }
  },
  methods: {
    optionSelected(item) {
      this.leftovers.filter(obj => obj.id === item.leftover.id)[0].option = item.option.name
      this.leftoverItemReloadCounter++
    },
    markReservationUsed(used, reservation) {
      this.usedReservations = this.usedReservations.filter(obj => obj !== `${reservation.ingredientId}_${reservation.originalPlanId}`)
      if (used) this.usedReservations.push(`${reservation.ingredientId}_${reservation.originalPlanId}`)
    },
    getStateOptions(item) {
      var options = this.userState.leftovers.filter(obj => obj.ingredient.id === item.id).map(obj => {
        return {
          id: obj.ingredient.id,
          created: moment(obj.created).fromNow(),
          name: obj.ingredient.name,
          object: obj
        }
      })
      options.push({id: "none", name: "No"})
      return options
    },
    optionsForLeftover(leftover) {
      if (this.userState && this.userState.leftovers && this.userState.leftovers.filter(obj => obj.ingredient.id === leftover.id).length > 0) return this.options
      return this.options.filter(obj => obj.name !== 'used_leftover')
    },
    stateOptionSelected(stateOption, leftover) {
      this.selectedStateOptions = this.selectedStateOptions.filter(obj => obj.leftoverId !== leftover.id)
      this.selectedStateOptions.push({leftoverId: leftover.id, stateOption: stateOption})
    },
    stateOptionActive(stateOption,leftover) {
      return this.selectedStateOptions.filter(obj => obj.leftoverId === leftover.id && obj.stateOption.id ===  stateOption.id && obj.stateOption.created === stateOption.created).length > 0
    },
    async markPlanCooked(saveLeftovers) {
      this.loading = true
      if (saveLeftovers) {
        var leftoversToSave = this.leftovers.map(obj => {
            return {
              quantity: obj.quantity,
              unit: obj.unit,
              action: obj.option || (obj.ingredient.canBeStore? 'kept' : "binned"),
              life: obj.life,
              planId: this.plan.id,
              ingredient: obj.ingredient,
              created: obj.created,
              bought: obj.bought
            }
          })
        if (this.previouslySavedLeftovers) leftoversToSave.created = this.previouslySavedLeftovers.created
        await createUserLeftover(leftoversToSave)
      }

      //Remove reservations and marked leftovers as used
      for (var reservation of this.reservations) {
        if (this.usedReservations.indexOf(`${reservation.ingredientId}_${reservation.originalPlanId}`) > -1) {
          //Set the item as used TODO what happens when not usiing the full quantity?
          await updateStateItem({planId: reservation.originalPlanId, ingredient: {id: reservation.ingredientId}, unit: reservation.unit, action: 'used'})
        }else {
          
          await reserveStateIngredient({
            remove: true,
            ingredientId: reservation.ingredientId,
            planId: reservation.originalPlanId,
            reservedByPlanId: this.plan.id,
            quantity: reservation.quantity,
            unit: reservation.unit
          })
        }
      }
      await this.plan.setStatus("COOKED")
      this.loading = false
      this.$emit("planDone")
      this.$bvModal.hide('leftover-modal')
    },
    getPlanLink(leftover) {
      var matchingOption = this.selectedStateOptions.filter(obj => obj.leftoverId === leftover.id)[0]
      if (matchingOption) return matchingOption.stateOption.object.planId
    },
    async loadLeftovers() {
      this.loadingLeftovers = true
      this.userState = await getUserState("LeftoverModal")
      if (this.plan) {
        this.leftovers = this.plan.shoppingList.getLeftovers({plan: this.plan, userState: this.userState})
        var response =  await getUserLeftovers(this.plan.id)
        this.previouslySavedLeftovers = response
        if (this.previouslySavedLeftovers) {
          for (let leftover of this.leftovers) {
            var previousLeftover = this.previouslySavedLeftovers.filter(obj => obj.ingredient.id === leftover.id)[0]
            if (previousLeftover) {
              leftover.option = previousLeftover.action
              leftover.created = previousLeftover.created
              leftover.bought = previousLeftover.bought
            }
          }
        }else {
          for (let leftover of this.leftovers) {

            if (leftover.life <= 7) {
              leftover.option = 'binned'
            }else {
              leftover.option = 'kept'
            }


          }
        }
      }
      this.reservations = await this.userState.getReservationsForPlan(this.plan)
      this.usedReservations = this.reservations.map(obj => `${obj.ingredientId}_${obj.originalPlanId}`)
      this.selectedStateOptions = []
      if (this.leftovers.length === 0) {
        this.markPlanCooked(false)
      }
      this.loadingLeftovers = false
    }
  },
  mounted() {
  },
  watch: {
    leftoverModalReloadCounter() {
      this.loadLeftovers()
    }
  }
}

</script>

<style>
.blank-footer {
  background-color: #00000000;
  padding: 0px;
  margin-bottom: 5px;
  border-top: 0px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.list-group-item.active {
  background-color: var(--secondo);
  color: var(--text-primary);
  border: 0px
}
</style>
