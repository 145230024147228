<template>
  <div>
    <div id="small-recipe-cards" class="scrolling-wrapper text-left" :style="`height: ${hideTime? '85px': '110px'}`" >
      <b-card v-for="recipe of recipes" v-bind:key="plan.id + recipe.id" style="height: 100%;width: 80px;" class="mr-1 align-middle scrolling-card text-center clickable" footer-class="blank-footer"
      @click="recipeSelected(recipe)">
      <b-row align-v="center" align-h="center" class="px-1" style="height:80px;  overflow: hidden">
        <b-col align-h="center" class="" >
          <p v-if="!recipe.imageUrl" :id="`recipe-title-${recipe.id}`"  class="text-secondary normal-overflow text-small bold " style="font-size:11px; height:100%; width: 100%">{{getRecipeTitle(recipe)}}</p>
          <div v-if="recipe.imageUrl" :id="`recipe-image-${recipe.id}`" class="small-card-image" @mouseover="(hover = recipe.id)" @mouseleave="(hover = undefined)">
            <b-img  :src="recipe.imageUrl" alt="Image" :class="`rounded-0 pt-1`" style="height:100%; width: 100%"></b-img>
            <div v-if="(hover === recipe.id)" class="normal-overflow text-small bold small-card-image-text"  >
              <p :id="`recipe-hover-title-${recipe.id}`" >{{recipe.title && recipe.title.length > 40? `${recipe.title.substr(0,45)}...`: recipe.title}}</p>
            </div>
          </div>
        </b-col>
        </b-row>
        
        <template #footer >
          <span v-if="!hideTime" class="text-secondary text-small text-bold mb-2 normal-overflow"><i class="material-icons nav-icon align-bottom">schedule</i>{{recipe.time}}</span>
       </template>
      </b-card>
      <!--<b-button v-if="webShareApiSupported && plan.recipes.length > 0" @click="share" class="btn-transparent"><i class="material-icons icon-middle text-primary-mine">share</i></b-button>-->
      <div class="scrolling-wrapper text-left" :style="`height: ${hideTime? '80px': '110px'}`" >
      <div v-for="index in 4" v-bind:key="index" style="height: 100%;width: 80px; border: 1px dashed var(--primo)" class="mr-1 align-middle scrolling-card text-center">
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: ["plan", "hideTime","reloadCounter"],
  data() {
    return {
      hover: undefined,
      recipes: [],

    }
  },
  methods: {
    recipeSelected(recipe) {
      this.$emit('recipeSelected', {recipe: recipe})
    },
    getRecipeTitle(recipe)  {
      let title = recipe.title && recipe.title.length > 40? `${recipe.title.substr(0,40)}...`: recipe.title
      if (recipe.servings && recipe.servings !== 2) title += ` (for ${recipe.servings})`
      return title
    },
    share() {
      navigator.share({
          title: "Share your Lean Larder Plan",
          description: "Here's my Lean Larder Plan",
          url: `https://leanlarder.com/share?content=${btoa(JSON.stringify(this.plan.recipes.map(recipe => recipe.id)))}`
      })
    }
  },
  mounted() {
    this.recipes = this.plan.recipes.sort((a,b) => a.id < b.id? 1: -1)
  },
  computed: {
  webShareApiSupported() {
    return navigator.share
  }
},
  watch: {
    reloadCounter() {
      this.recipes = this.plan.recipes.sort((a,b) => a.id < b.id? 1: -1)
    }
  }
}

</script>

<style>
.blank-footer {
  background-color: #00000000 !important;
  padding: 0px !important;
  margin-bottom: 5px !important;
  border-top: 0px !important;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.small-card-image {
  position: relative;
  text-align: center;
}

.small-card-image-text {
  position: absolute;
  background-color: #00000077;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrolling-card {
  display: inline-block !important;
}

.normal-overflow {
  white-space: normal;
  overflow-y: hidden;
}

</style>
