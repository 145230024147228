<template>
  <div>
    <b-modal id="guide-modal" size="xl" hide-header scrollable style="height:90vh" >
      <b-container fluid style="height:90vh" class="text-grey"> 
        <b-row>
          <b-col>
            <div v-for="page of pages" v-bind:key="page.title">
              <div v-if="activePage === page.index"  class="p-4">
                <h3 class="mb-3">{{page.title}}</h3>
                <b-img center class="mb-4" rounded="true" style="max-height:400px" fluid :src="require(`../assets/images/${page.image}`)"/>
                <p v-html="page.text"></p>
              </div>

            </div>
          </b-col>
        </b-row>

    </b-container>
    <div slot="modal-footer" class="pb-2">
      <b-row class="justify-content-center" no-gutters>
        <b-col>
          <b-pagination
          class="text-center"
          v-model="activePage"
          :total-rows="pages.length"
          :per-page="1"
          limit="1"
          pills
          prev-text="Prev"
          next-text="Next"
          align="center"
          hide-goto-end-buttons
        >
        <template #page="{ page, active }">
        <b v-if="active">{{ page }} of {{pages.length}}</b>

      </template>
      </b-pagination>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mb-5">
        <b-col class="text-center">
        <b-button variant="secondary" @click="closeModal">Close</b-button>
        </b-col>
      </b-row>
    </div>
    </b-modal>
  </div>
</template>

<script>
import User from '../models/User'
export default {
  components: {

  },
  props: [],
  data() {
    return {
      user: undefined,
      activePage: 1,
      pages :[
        {
          title: "",
          text: "Welcome to Lean Larder! We'll give you the tools to Shop Confidently, Eat Well and Waste Less. Here's how it works...",
          image: "LeanLarder.png",
          index: 1
        },
        {
          title: "Find recipes",
          text: "Scroll through our list of recipes for the week and add them to your plan. You can add as many or as few as you like. <br><br> Add recipes based on when you're going shopping. If you're shopping for 1 meal, add 1 meal to the plan, if it's for the whole week, add more.",
          image: "guide1.png",
          index: 2
        },
        {
          title: "Create Plans",
          text: "We'll add your recipes to a plan, but you can create more plans if you like to plan ahead. Switch between them on the recipe page, or see them all on the plan page",
          image: "guide2.png",
          index: 3
        },
        {
          title: "Plan smart",
          text: "Look out for our recommendations on recipes you can add to reduce how many leftovers you have. Most of our recipes use sensible amounts of supermarket sizes so we can match things easily for you.",
          image: "guide3.png",
          index: 4
        },
        {
          title: "Shop",
          text: "We'll create your shopping list for you, when you buy your ingredients you can tick them off. You can even add your own items, so you don't need to manage multiple lists",
          image: "guide4.png",
          index: 5
        },
        {
          title: "Cook",
          text: "When you're ready to cook, go to your plan page, select your recipe and we'll walk you through the steps",
          image: "guide5.png",
          index: 6
        },
        {
          title: "Track your leftovers",
          text: "If you do have leftovers, we'll ask you what you did with them so we can help you use up anything you have left",
          image: "guide6.png",
          index: 7
        }
      ]
    }
  },
  methods: {
    async closeModal() {
      this.$bvModal.hide('guide-modal')
    }
  },
  mounted() {
  },
  watch: {
  }
}

</script>

<style>

</style>
