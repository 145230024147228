<template>
  <div>

    <b-container class="p-0 m-0" style="height: 100vh; " fluid>
      <div v-if="recipe" >
      <b-row class="mb-2 pt-2 pb-2" align-h="center"  style="background:white" >
        <b-col cols="9" class="text-left pl-5">
          <b-navbar-brand href="#">
            <b-img :src="require('../assets/images/LeanLarder3030.png')" class="d-inline-block align-top"></b-img>
          </b-navbar-brand>
        </b-col>
        <b-col cols="3" >
          <b-button class="top" variant="transparent" @click="$router.push('/plan')" style="width: 100%; " squared><i class="material-icons" style="font-size:30px;">close</i></b-button>
        </b-col>
      </b-row>
      <div class="p-3">
      <b-row class="mb-2 mt-2">
        <b-col cols="12">
          <p style="font-size: 30px">Step {{step + 1}} of {{recipe.method.length}}</p>
        </b-col>
      </b-row>
      <b-row >
        <b-col cols="12">
          <p id="step-text" style="font-size: 25px" v-html="formattedText(recipe.method[step].text)"></p>
        </b-col>
      </b-row>
    </div>
      <b-button-group style="width: 100%; height: 10vh" class="bottom">
        <b-button class="full-button" @click="lastPage" squared><i class="material-icons" style="font-size:40px">chevron_left</i></b-button>
        <b-button class="full-button" @click="showIngredientsModal" squared><i class="material-icons" style="font-size:40px">view_list</i></b-button>
        <b-button  class="full-button"  @click="nextPage" squared><i class="material-icons" style="font-size:40px">chevron_right</i></b-button>
      </b-button-group>
    </div>
    </b-container>
    <b-modal id="ingredients-modal" v-if="recipe" title="BootstrapVue" hide-header scrollable >
        <div class="mx-3 mb-4">
          <h3 class="mt-4 mb-4 text-grey">Ingredients</h3>
          <ShoppingListItem v-for="ingredient of recipe.ingredients" v-bind:key="ingredient.id" :item="ingredient"/>
        </div>
        <div slot="modal-footer">
          <div >
            <b-button class="full-button ingredients-button-close align-text-center" @click="closeIngredientsModal()" squared><i class="material-icons" style="font-size:30px;">close</i></b-button>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import ShoppingListItem from './ShoppingListItem'
import {getRecipe} from '../clients/RecipeClient'
export default {
  name: 'MethodPage',
  components: {
    ShoppingListItem
  },
  data() {
    return {
      recipe: undefined,
      step: 0,
      stepText: undefined
    }
  },
  methods: {
    async loadRecipe() {
      var response = await getRecipe(this.$route.params.recipeId)
      this.recipe = response
    },
    nextPage() {
      if (this.step + 1 < this.recipe.method.length) {
        this.step++
        this.$router.replace({query: {step: this.step}})
      }
      var newText = this.recipe.method[this.step].text.replace(/\s\s+/g, '<br>')
    },
    lastPage() {
      if (this.step - 1 >= 0) {
        this.step = this.step -1
        this.$router.replace({query: {step: this.step}})
      }
    },
    formattedText(text) {
      return text.replace(/\s\s+/g, '<br><br>')
    },
    showIngredientsModal() {
      this.$router.push({hash: '#ingredients'})
      this.$bvModal.show('ingredients-modal')
    },
    closeIngredientsModal() {
      this.$router.replace({hash: undefined})
      this.$bvModal.hide('ingredients-modal')
    }

  },
  activated() {
    this.recipe = undefined
    this.stepText = undefined
    this.loadRecipe()
    this.step = 1* (this.$route.query.step || 0)

  }
}

</script>

<style>

.bottom {
    position: fixed;
    bottom: 0;
    left:0;
}

.top {
    position: absolute;
    top: 0;
    left:0;
}

.ingredients-button-close {
  width: 100%;

}
</style>
