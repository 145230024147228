<template>
    <div id="add-recipe-swap" class="px-4 pt-4 pb-0" v-if="swap">
      <b-card class="p-2">
      <b-row @click="showDetail = !showDetail" align-h="between" :class="swap.undone? '':'clickable'">
        <b-col cols="10">
          <p v-if="!swap.undone">We've tweaked your <span class="bold">{{ swap.out.title }} </span>to help reduce waste  
            <span id="undo-swap-button" class="text-small underlined" @click.stop="undoSwap">undo</span>
          </p>
          <p v-if="swap.undone">We have undone the tweak to <span class="bold">{{ swap.out.title }} </span>  
            <span id="undo-swap-button" class="text-small underlined" @click.stop="redoSwap">redo</span>
          </p>
        </b-col>
        <b-col cols="2" v-if="!swap.undone">
          <i class="material-icons icon-middle text-grey clickable" v-if="showDetail===false" >expand_more</i>
          <i class="material-icons icon-middle text-grey clickable" v-if="showDetail===true" >expand_less</i>
        </b-col>
      </b-row>
      <b-collapse id="leftovers-detail" v-model="showDetail" class="mt-2" v-if="!swap.undone">
        <div id="swap-detail" >
          <div id="new-items"  v-if="difference">
            <h4>New Items</h4>
            <p class="text-small pb-2">These items were added to the recipe</p>
            <div v-for="newItem in difference.new" v-bind:key="newItem.id" :id="`new-item-${newItem.id}`">
              <ShoppingListItem :item="newItem"/>
            </div>
          </div>
          <div id="removed-items"  v-if="difference" class="pt-4">
            <h4 class="">Removed Items</h4>
            <p class="text-small pb-2">These items were removed to reduce waste</p>
            <div v-for="missingItem in difference.missing" v-bind:key="missingItem.id" :id="`removed-item-${missingItem.id}`">
              <ShoppingListItem :item="missingItem"/>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-card>
    </div>
  </template>
  
  <script>
import ShoppingListItem from '../components/ShoppingListItem';

  
  export default {
    components: {
      ShoppingListItem
    },
    props: [
      'swap'
    ],
    data() {
      return {
        difference: undefined,
        showDetail: false
      }
    },
    methods: {
      undoSwap() {
        this.$emit('undoSwap')
      },
      redoSwap() {
        this.$emit('redoSwap')
      }
    },
    mounted() {
      if (this.swap) {
        this.difference = this.swap.in.differenceTo(this.swap.out).ingredients
      }
    },
    watch: {
      swap() {
        this.difference = this.swap.in.differenceTo(this.swap.out).ingredients
      }
    }
  }
  
  </script>
  
  <style>
  
  </style>
  