export default {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_V9VgV50Xa',
        //endpoint: 'https://auth.leanlarder.com',
        //endpoint: 'https://ney5yxydh6.execute-api.us-east-1.amazonaws.com',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '4kialc1po0b9npvm14eodna2fd',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
        oauth: {
            domain: 'auth.leanlarder.com',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:8080/auth',
            redirectSignOut: 'http://localhost:8080',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }

    }
}
