<template>
  <div>

  <p v-if="plans.length === 0">You have no plans</p>
  <p v-if="plan && plan.recipes.length === 0">You have nothing on your plan!</p>
  <div v-for="(plan, index) of plansToShow" v-bind:key="'activePlans' + index" class="mb-3" >
      <PlanView
      :plan="plan"
      :index="index"
      :hideDeleteOption="(plan.isInShopping() && plans.filter(plan => plan.getStatus() === 'SHOPPING').length === 1)"
      :userState="userState"
      :cookingButtonOnly="true"
      @removeFromPlan="removeFromPlan"
      :reloadCounter="reloadCounter"
      :reloadCounter2="reloadCounter2"
      @archivePlan="archivePlan"
      @recipeSelected="recipeSelected"
      @planStatusUpdated="planStatusUpdated"
      @addRecipeToPlan="addRecipeToPlan"
      @setActivePlan="setActivePlan"
      @showLeftoverModal="showLeftoverModal"
      />
      <hr class="mt-1">
  </div>
  <b-button @click="createPlan" variant="primary" class="bold">Create New Plan</b-button>
  <b-row>

    <h3 v-b-toggle.cooked-plans-collapse v-if="cookedPlans && cookedPlans.length > 0" class="capitalize mb-3 ml-3 mt-5">Cooked Plans
      <i v-if="cookedPlansVisible" class="material-icons icon-middle text-primary-mine ml-3">expand_less</i>
      <i v-if="!cookedPlansVisible" class="material-icons icon-middle text-primary-mine ml-3">expand_more</i>
    </h3>
  </b-row>
  <b-collapse id="cooked-plans-collapse" class="mt-2" v-model="cookedPlansVisible">
    <div v-for="(plan, index) of cookedPlans" v-bind:key="'cookedPlans' + index" class="mb-3" >
        <PlanView
        :plan="plan"
        :userState="userState"
        :active="index === 0"
        :index="index"
        @removeFromPlan="removeFromPlan"
        :reloadCounter="reloadCounter"
        :hideSuggestions="true"
        @archivePlan="archivePlan"
        @recipeSelected="recipeSelected"
        @planStatusUpdated="planStatusUpdated"
        @addRecipeToPlan="addRecipeToPlan"
        @setActivePlan="setActivePlan"
        @showLeftoverModal="showLeftoverModal"
        />
        <hr class="mt-1">
    </div>
  </b-collapse>
    <LeftoverModal v-if="plan" :plan="plan" :leftoverModalReloadCounter="leftoverModalReloadCounter" @planDone="planStatusUpdated"/>
  </div>
</template>

<script>
import moment from 'moment'
import PlanView from './PlanView'
import Plan from '../models/Plan'
import LeftoverModal from './LeftoverModal'
export default {
  components: {
    PlanView, LeftoverModal
  },
  props: ['plans', 'reloadCounter', 'userState'],
  data() {
    return {
      recipes: [],
      plan: undefined,
      showSuggestions: false,
      suggestions: undefined,
      plansToShow: undefined,
      reloadCounter2: 0,
      cookedPlansVisible: false,
      cookedPlans: undefined,
      recipeMap: {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "unplanned": []
      },
      done: true,
      leftoverModalReloadCounter: 0
    }
  },
  methods: {
    planHasDayPlannedRecipes() {
      return this.recipes.filter(obj => obj.day).length > 0
    },
    planShouldShowAsUndoable(plan) {
      return plan.planStatuses && plan.planStatuses.indexOf("COOKED") > -1 && new Date() - new Date(plan.updated) <= 1000 * 10
    },
    planShouldShowAsCooking(plan) {
      return plan.planStatuses && plan.planStatuses.indexOf("COOKING") > -1
    },
    getCreatedDate(plan) {
      return moment(this.plan.created).fromNow()
    },
    async removeFromPlan(obj) {
      this.$emit('removeRecipeFromPlan', obj)
    },
    async addToPlan(obj) {
      this.$emit('addToPlan', obj)
    },
    async createPlan() {
      this.$emit('createPlan')
    },
    recipeSelected(options) {
      this.$emit('recipeSelected', options) 
    },
    async addRecipeToPlan(addRecipeEvent) {
      this.$emit('addRecipeToPlan', addRecipeEvent)
    },
    async archivePlan(plan) {
      this.$emit('archivePlan', plan)
    },
    async planStatusUpdated() {
      await this.plansUpdated()
      this.$emit("planStatusUpdated")
    },
    async plansUpdated() {
      this.plansToShow = this.plans.filter(plan => plan.planStatuses && plan.planStatuses.indexOf("COOKED") === -1 ).sort((a,b) => b.planStatuses.indexOf("COOKING") - a.planStatuses.indexOf("COOKING"))
      this.cookedPlans = this.plans.filter(plan => plan.planStatuses && plan.planStatuses.indexOf("COOKED") > -1 ).sort((a,b) => new Date(b.updated) - new Date(a.updated))
      this.reloadCounter2++
    },
  
    setActivePlan(plan){
      this.$emit('setActivePlan', plan)
    },
    showLeftoverModal(plan) {
      this.plan = plan
      this.leftoverModalReloadCounter++
      this.$bvModal.show('leftover-modal')
    }
  },
  mounted() {
    if (this.plans) this.plan = this.plans[0]
    this.plansUpdated()
  },
  watch: {
    reloadCounter() {
      this.plansUpdated()
    }
  }
}

</script>

<style>
.blank-footer {
  background-color: #00000000;
  padding: 0px;
  margin-bottom: 5px;
  border-top: 0px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.undo-button {
}
</style>
