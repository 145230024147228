<template>
  <div>
    <NavBar />
    <b-container class="" style="margin-top:110px">
      <b-button class="mb-2" @click="showStats = !showStats">Show / Hide Stats</b-button>
      <b-row>
        <b-col v-if="showStats" lg="6" sm="12">

          <PartialStats />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" sm="12">
          <AddRecipe class="mb-3" :ingredients="ingredients" v-if="showAddRecipe && recipes" :recipe="recipe" :recipes="recipes" @recipeCreated="listRecipes" @nextRecipe="nextRecipe" :reloadCounter="reloadCounter"/>
          <RecipeList :showHeading="true" :recipes="recipes" v-if="recipes" v-on:recipeSelected="showRecipe"/>
        </b-col>
        <b-col lg="6" sm="12">
          <AddIngredient v-if="showAddIngredient" v-on:ingredientCreated="listIngredients" :inputIngredient="activeIngredient" v-on:nextIngredient="nextIngredient" :reloadCounter="reloadCounter"/>
          <IngredientsList class="mt-3" :ingredients="ingredients" v-if="ingredients" v-on:ingredientSelected="setActiveIngredient"/>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import AddRecipe from './AddRecipe'
import AddIngredient from './AddIngredient'
import IngredientsList from './IngredientsList'
import RecipeList from './RecipeList'
import NavBar from './NavBar'
import PartialStats from './PartialStats'
import {listIngredients, listAllRecipes} from '../clients/RecipeClient'
export default {
  name: 'Admin',
  components: {
    AddRecipe,AddIngredient, IngredientsList, RecipeList, NavBar, PartialStats
  },
  data() {
    return {
      showAddRecipe: true,
      showAddIngredient: true,
      reloadCounter: 0,
      ingredients: undefined,
      recipes: undefined,
      recipe: undefined,
      activeIngredient: undefined,
      showStats: false
    }
  },
  methods: {
    async listIngredients() {
      this.ingredients = await listIngredients()
    },
    async listRecipes(id) {
      this.recipes = await listAllRecipes()
      if (id) this.recipe = this.recipes.filter(obj => obj.id === id)[0]
    },
    showRecipe(recipeSelectedEvent) {
      this.recipe = recipeSelectedEvent.recipe
      try {this.$router.push({query: {recipeId: this.recipe.id}})}catch(err) {}
    },
    nextRecipe() {
      if (!this.recipe) {
        this.recipe = this.recipes[0]
      }else {
        let currentIndex = this.recipes.map(obj => obj.id).indexOf(this.recipe.id)
        currentIndex++
        if (currentIndex === this.recipes.length) {
          this.recipe = this.recipes[0]
        }else {
          this.recipe = this.recipes[currentIndex]
        }
      }
    },
    nextIngredient(){
      if (!this.activeIngredient) {
        this.activeIngredient = this.ingredients[0]
      }else {
        var currentIndex = this.ingredients.map(obj => obj.id).indexOf(this.activeIngredient.id)
        currentIndex++
        if (currentIndex === this.ingredients.length) {
          this.activeIngredient = this.ingredients[0]
        }else {
          this.activeIngredient = this.ingredients[currentIndex]
        }
      }
    },
    setActiveIngredient(ingredient) {
      this.activeIngredient = ingredient
      this.reloadCounter++
    }
  },
  mounted() {
    this.listIngredients()
    this.listRecipes()
  }
}

</script>

<style>

</style>
