<template>
  <div>
    <h2 v-if="showHeading">Recipes</h2>
    <div v-if="plan && plan.recipes.length > 0 && plan.shoppingList.getLeftovers().length === 0" class="py-3 text-grey fixed-bottom" style="background: white; font-weight: bold" block>
      <i class="material-icons align-bottom" style="color: var(--secondo)" >star</i>
      This plan adds no leftovers!
      <i class="material-icons align-bottom" style="color: var(--secondo)" >star</i>
    </div>

    <b-row  align-h="between" align-v="end" class="">
      <b-col cols="8" xl="auto">
        <h3 v-if="(!planView && !showSuggestions)" class="text-left" @click="showAllRecipes" id="recipe-list-title">This week's selected recipes</h3>
      </b-col>
      <b-col cols="4" xl="auto" style="text-align:end">
        
      </b-col>
    </b-row>
    <b-row  align-h="between" align-v="center" class="mt-2 mb-2">
      <b-col cols="auto" md="2" class="mb-2">
    <b-dropdown id="dropdown-form" text="Filter" ref="dropdown" class="ml-0 pl-0" variant="transparent">
      <template #button-content >
              <div>
                <p><i class="material-icons icon-middle text-primary-mine" > filter_list</i> Filter</p>
              </div>
            </template>
          <b-dropdown-form>
            <b-form-checkbox-group
              v-model="filters"
              :options="['Gluten Free', 'Vegetarian', 'Vegan']"
            ></b-form-checkbox-group>
          </b-dropdown-form>
        </b-dropdown>
        </b-col>
        <b-col cols="8" md="4">
        <b-badge  v-for="filter in filters" v-bind:key="filter" variant="light" class="mr-1">{{ filter }}</b-badge>
      </b-col> 
      <b-col cols="12" md="6">
        <b-input-group class="">
          <b-form-input size="sm"  v-model="search" placeholder="search by title or ingredient"></b-form-input>
        </b-input-group>
      </b-col>
      </b-row>
    <div id="recipe-list" class="mb-5">
    
      <SuggestionsListView v-if="plan && showSuggestions" @recipeSelected="recipeSelected"
      :userState="userState" :suggestions="suggestions" :plan="plan" :reloadCounter="reloadCounter" v-on:addRecipeToPlan="addRecipeToPlan"
      />
      <div v-if="!showSuggestions">

        

      <RecipeListCard
      :reloadCounter="reloadCounter"
      class="clickable mb-2"
      v-for="recipe in recipes.filter(recipe => filteredIds.indexOf(recipe.id) === -1)"
      v-bind:key="recipe.id"
      :recipe="recipe"
      :plan="plan"
      :planView="planView"
      :plans="plans"
      v-on:recipeSelected="recipeSelected"
      v-on:addRecipeToPlan="addRecipeToPlan"
      v-on:removeRecipeFromPlan="removeRecipeFromPlan"
       />
    </div>
  </div>
  <SuggestionBanner @recipeSelected="recipeSelected" v-if="suggestions" :suggestions="suggestions" @addRecipeToPlan="addRecipeToPlan" :plan="plan" :userState="userState"/>
  </div>
</template>

<script>
import RecipeListCard from './RecipeListCard'
import SuggestionsListView from './SuggestionsListView'
import SuggestionBanner from './SuggestionBanner'

export default {
  components: {
    RecipeListCard, SuggestionsListView, SuggestionBanner
  },
  props: ['recipes', 'showHeading', 'plan', 'reloadCounter','planView','plans', 'recipeView', 'userState'],
  data() {
    return {
      fields: ['title'],
      showSuggestions: false,
      suggestions: undefined,
      filters: [],
      filterMap : {
        'Gluten Free' : 'gluten',
        'Vegetarian' : 'meat',
        'Vegan' : 'animal_product'
      },
      search: undefined,
      filteredIds :[]
    }
  },
  methods: {
    showAllRecipes() {
      this.$emit('showAllRecipes')
    },
    recipeSelected(recipe) {
      this.$emit('recipeSelected', {recipe: recipe})
    },
    async addRecipeToPlan(recipeEvent) {
      this.$emit('addRecipeToPlan', recipeEvent)
    },
    async removeRecipeFromPlan(recipeEvent) {
      this.$emit('removeRecipeFromPlan', recipeEvent)
    },
    showHideSuggestions() {
      this.showSuggestions = !this.showSuggestions
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    async loadSuggestions() {
      this.suggestions = undefined
      if (this.plan) {
        this.suggestions = await this.plan.getSuggestions(this.userState)

      }else {
        this.suggestions = []
      }
      if (!this.suggestions || this.suggestions.length === 0) this.showSuggestions = false
    },
  },
  mounted() {
    this.loadSuggestions()
  },
  watch: {
    reloadCounter() {
      this.loadSuggestions()
    },
    showSuggestions() {
      if (document.getElementById('recipe-list')) document.getElementById('recipe-list').scrollTop = 0
    },
    filters() {
      this.filteredIds = []
      this.filteredIds = this.filteredIds.concat(this.recipes.filter(recipe => !recipe.search(this.search)).map(recipe => recipe.id))
      const allergen = this.filters.map(filter => this.filterMap[filter])
      this.filteredIds = this.filteredIds.concat(this.recipes.filter(recipe => recipe.containsAllergen(allergen)).map(recipe => recipe.id))
    },
    search () {
      this.filteredIds = []
      this.filteredIds = this.filteredIds.concat(this.recipes.filter(recipe => !recipe.search(this.search)).map(recipe => recipe.id))
      const allergen = this.filters.map(filter => this.filterMap[filter])
      this.filteredIds = this.filteredIds.concat(this.recipes.filter(recipe => recipe.containsAllergen(allergen)).map(recipe => recipe.id))
    }
  }
}

</script>

<style>


</style>
