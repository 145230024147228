export default class Ingredient {
  constructor(ingredient) {
    if (ingredient) {
      this.name = ingredient.name
      this.unit = ingredient.unit
      this.store = ingredient.store
      this.quantity = ingredient.quantity
      this.amounts = ingredient.amounts
      this.id = ingredient.id
      this.aisle = ingredient.aisle
      this.freezable = ingredient.freezable
      this.life = ingredient.life
      this.canBeStore = ingredient.canBeStore
      this.checked = ingredient.checked
      this.calories = ingredient.calories
      this.allergens = ingredient.allergens
      if (this.amounts) this.partial = this.isPartial()
    }
  }

  isPartial() {
    if (this.store) return false
    let fullAmountsOfUnit = this.amounts.filter(amount => amount.unit === this.unit && !amount.partial)
    return fullAmountsOfUnit.filter(amount => this.quantity % amount.quantity === 0).length === 0
  }

  quantityWithServings(servings) {
    return this.quantity / 2 * servings
  }

  getLeftover() {
    if (this.store || this.canBeStore) return
    var fullAmountsOfUnit = this.amounts.filter(obj => obj.unit === this.unit && !obj.partial)
    var leftoverAmounts = fullAmountsOfUnit.map(obj => {
      const leftoverAmount = this.quantity % obj.quantity
      if (leftoverAmount === 0) return 0
      return obj.quantity - leftoverAmount
    })
    var minimumLeftoverOfIngredient = Math.min(...leftoverAmounts)
    if (minimumLeftoverOfIngredient <= 0) return
    var leftover = new LeftoverIngredient(this)
    leftover.quantity = minimumLeftoverOfIngredient
    return leftover
  }

  hasLeftovers() {
    return this.getLeftover() !== undefined
  }

  getCalories() {
    if (!this.calories) return
    var caloriesForUnit = this.calories.filter(caloryInformation => caloryInformation.unit === this.unit)[0]
    if (caloriesForUnit) return (this.quantity / caloriesForUnit.quantity) * caloriesForUnit.calories
  }
}

class LeftoverIngredient {
  constructor(ingredient) {
    this.name = ingredient.name
    this.unit = ingredient.unit
    this.id = ingredient.id
    this.freezable = ingredient.freezable
    this.life = ingredient.life
    this.ingredient = ingredient
  }
}
