<template>
  <div>
    <b-modal id="plan-chooser-modal"  scrollable size="md" ok-only >
      <div class="p-3 " v-if="plansToShow" >
        <div v-for="plan of plansToShow" v-bind:key="plan.id" class="mb-2">
          <b-card class="p-2" style="background: var(--secondo)">
            <SmallRecipeCards :plan="plan" :hideTime="true"/>
            <div class="w-100 text-center">
              <b-button v-if="recipe && plan && plan.recipes.filter(obj => obj.id === recipe.id).length === 0" @click="addRecipeToPlan(plan)" class="bold mt-2 " variant="primary">Add to this plan</b-button>
              <b-button v-if="recipe && plan && plan.recipes.filter(obj => obj.id === recipe.id).length > 0" disabled class="bold mt-2" >Already In Plan</b-button>
              <b-button v-if="!recipe && plan && plan.id !== activePlan.id" @click="changeActivePlan(plan)" class="bold mt-2 " variant="primary">Select this plan</b-button>
              <b-button v-if="!recipe && plan && plan.id === activePlan.id" disabled class="bold mt-2" >Current Plan</b-button>
            </div>
          </b-card>
        </div>

      </div>
      <div slot="modal-footer" class="text-center p-2">
        <b-button-group>
          <b-button @click="$router.push('/plan'); $bvModal.hide('plan-chooser-modal')" variant="primary" class="bold mt-2">Manage Plans</b-button>
          <b-button @click="$bvModal.hide('plan-chooser-modal')" variant="negative" class="bold mt-2">Close</b-button>
        </b-button-group>
      </div>
      <div slot="modal-header" class="text-center p-2">
        <b-row align-v="center" no-gutters>
          <b-col cols="6">
            <div ><h3 class="mt-1 text-grey h-100">Select Your Plan</h3></div>
          </b-col>
          <b-col cols="1">
            <p class="text-left text-grey" style="padding-left:0px"> or</p>
          </b-col>
          <b-col cols="5">
            <b-button @click="createPlan" variant="primary" class="bold mt-2 ml-2">Create New Plan {{recipe? 'and Add':''}} </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SmallRecipeCards from './SmallRecipeCards'
export default {
  components: {
    SmallRecipeCards
  },
  props: ['plans', 'reloadCounter', 'recipe', "activePlan"],
  data() {
    return {
      plansToShow: undefined
    }
  },
  methods: {
    createPlansList() {
      if (this.plans) this.plansToShow = this.plans.filter(plan => plan.isInShopping()).sort((a,b) => this.activePlan && this.activePlan.id === a.id? -1: 1)
    },
    createPlan() {
      var options = {}
      if (this.recipe) {
        options.recipe = this.recipe
      }else {
        options.makeActive = true
      }
      this.$emit("createPlan", options)
      this.$bvModal.hide("plan-chooser-modal")
    },
    addRecipeToPlan(plan) {
      this.$emit("addRecipeToPlan", {id: plan.id, recipe: this.recipe})
    },
    changeActivePlan(plan) {
      this.$emit("changeActivePlan", plan)
      this.$bvModal.hide("plan-chooser-modal")
    }
  },
  mounted() {
    this.createPlansList()
  },
  watch: {
    reloadCounter() {
      this.createPlansList()
    }
  }
}

</script>

<style>
.blank-footer {
  background-color: #00000000;
  padding: 0px;
  margin-bottom: 5px;
  border-top: 0px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
