import {createRecipe, getFullRecipe} from '../clients/RecipeClient'
import Ingredient from './Ingredient'
const newAge = (1000 * 60 * 60 * 24 * 7) 
export default class Recipe {
  constructor(recipe) {
    if (recipe) createFromInput(this, recipe)
  }
  getCalories() {
    if (this.calories) return this.calories
    if (this.ingredients.length === 0) return
    const servings = 2
    const totalCalories = this.ingredients.map(ingredient => ingredient.getCalories()).reduce((previousValue, currentValue) => currentValue? previousValue + currentValue : previousValue)
    return  totalCalories / servings

  }
  async load() {
    var loadedRecipe = await getFullRecipe(this.id)
    createFromInput(this, loadedRecipe)
  }
  updateIngredients(masterIngredientsList) {
    for (let ingredient of this.ingredients) {
      let ingredientInList = masterIngredientsList.find(obj => isMatchingIngredient(ingredient, obj));
  
      if (ingredientInList) {
        ingredient.amounts = ingredientInList.amounts
        ingredient.calories = ingredientInList.calories
        ingredient.partial = ingredientInList.amounts.find(obj => obj.quantity === ingredient.quantity && obj.unit === ingredient.unit).partial
        ingredient.aisle = ingredientInList.aisle
        ingredient.freezable = ingredientInList.freezable
      }
    }
  }
  isNew() {
    return new Date() - new Date(this.created) < newAge
  }
  hasPartials() {
    return this.ingredients.find(ingredient => ingredient.isPartial()) !== undefined
  }
  containsAllergen(allergens) {
    return this.ingredients.find(ingredient => ingredient.allergens && ingredient.allergens.some(a => allergens.indexOf(a) > -1)) !== undefined
  }
  search(searchTerm) {
    searchTerm = searchTerm.toLowerCase()
    let ingredientNames = ""
    if (this.ingredients) ingredientNames = this.ingredients.map(ingredient => ingredient.name).join("#")
    return this.title.toLowerCase().indexOf(searchTerm) > -1  || ingredientNames.indexOf(searchTerm) > -1
  }
  differenceTo(recipe) {
    let newItems = this.ingredients.filter(ingredient => !recipe.ingredients.find(i => i.id === ingredient.id))
    let missingItems = recipe.ingredients.filter(ingredient => !this.ingredients.find(i => i.id === ingredient.id))
    return {ingredients: {new: newItems, missing: missingItems}}
  }
  async save() {
    const data = await createRecipe(this)
    this.id = data.createRecipe.id
  }
}

function createFromInput(recipe, input) {
  Object.keys(input).forEach(key => {
    if (key === 'ingredients') {
        recipe.ingredients = input.ingredients.map(ingredient => new Ingredient(ingredient))
    }else {
      recipe[key] = input[key]
    }
  })
}

function isMatchingIngredient(ingredient, obj) {
  return obj.id === ingredient.id;
}

function isMatchingAmount(ingredient, obj) {
  return obj.quantity === ingredient.quantity && obj.unit === ingredient.unit;
}