<template>
  <div>
    <b-container class="login-container px-5" style="height:100vh; " >
      <b-row class="pb-3 pt-5">
      <b-col class="d-flex justify-content-center">
        <div class="logo-main p-3">
          <div>LEAN<br>LARDER</div>
        </div>
      </b-col>
      </b-row>
      <div v-if="!confirmationCodeEntry && !forgotPasswordFlow">
        <b-row class="mt-2">
          <b-col>
            <b-spinner v-if="federatedSignInLoading" variant="light" />
            <b-img v-if="!federatedSignInLoading" style="height:50px" class="clickable mb-2 text-center sign-in-with-google-button" :src="require('../assets/images/btn_google_signin_dark_normal_web@2x.png')" @click="federatedSignIn()" >Sign in with Google</b-img>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2" v-if="registerFlow">
          <b-col>
            <p class="text-grey bold">or</p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2" v-if="registerFlow">
          <b-col>
            <p class="text-grey">Register with your email and password</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-input id="input-email" class="pill-input" placeholder="Email address" v-model="email" v-on:keyup.enter="signIn"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2" >
          <b-col>
            <b-form-input type="password" id="input-password" class="pill-input" placeholder="Password" v-model="password" v-on:keyup.enter="signIn"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2" v-if="error">
          <b-col>
            <p class="text-grey">{{error}}</p>
          </b-col>
        </b-row>
        <div v-if="signInFlow">
          <b-row class="mt-2">
            <b-col>
              <b-button variant="second" pill @click="signIn" style="min-width: 100px"><p v-if="!signInLoading">Sign In</p><b-spinner v-if="signInLoading" variant="light" small /></b-button>

            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p v-if="!forgotPasswordLoading" class="clickable underlined text-small text-grey" @click="forgotPassword">Forgot password?</p>
              <b-spinner v-if="forgotPasswordLoading" variant="dark" small />
            </b-col>
          </b-row>
          <p class="line-with-text mb-4 mt-4"><span>OR</span></p>

          <b-row class="mt-2">
            <b-col>
              <b-button pill variant="second" @click="signUp" style="min-width: 100px"><p v-if="!registerLoading">Register</p><b-spinner v-if="registerLoading" variant="light" small /></b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="registerFlow">

          <b-row class="mt-2">
            <b-col>
              <b-button pill variant="second" @click="signUp" style="min-width: 100px"><p v-if="!registerLoading">Register</p><b-spinner v-if="registerLoading" variant="light" small /></b-button>
            </b-col>
          </b-row>


          <p class="line-with-text mb-4 mt-4"><span>OR</span></p>
          <b-row class="mt-2">
            <b-col>
              <b-button variant="third" pill @click="signIn" style="min-width: 100px"><p v-if="!signInLoading">Sign In</p><b-spinner v-if="signInLoading" variant="light" small /></b-button>

            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p v-if="!forgotPasswordLoading" class="clickable underlined text-small text-grey" @click="forgotPassword">Forgot password?</p>
              <b-spinner v-if="forgotPasswordLoading" variant="dark" small />
            </b-col>
          </b-row>
        </div>
      </div>
      <div  v-if="confirmationCodeEntry">
        <b-row class="mt-2">
          <b-col>
            <p class="text-grey">We've sent a code to your email address, please enter it here</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-input  class="pill-input" id="input-confirmation-code" placeholder="Confirmation Code" v-model="confirmationCode"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="error">
          <b-col>
            <p class="text-grey">{{error}}</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button variant="second" pill @click="confirmSignUp" style="min-width: 100px"><p v-if="!confirmSignUpLoading">Submit</p><b-spinner v-if="confirmSignUpLoading" variant="light" small /></b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="text-grey">
            <p v-if="!codeResent && !codeResentLoading" class="clickable underlined" @click="resendConfirmationCode">Resend Code</p>
            <b-spinner v-if="codeResentLoading" variant="dark" small />
            <p v-if="codeResent  && !codeResentLoading" @click="resendConfirmationCode">Code Sent</p>
          </b-col>
        </b-row>

      </div>
      <div  v-if="forgotPasswordFlow">
        <b-row class="mt-2">
          <b-col>
            <p class="text-grey">We've sent a code to your email address, please enter it here</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-input  class="pill-input"  id="input-confirmation-code" placeholder="Confirmation Code" v-model="confirmationCode"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-input  class="pill-input"  type="password" id="input-password" placeholder="New password" v-model="password" v-on:keyup.enter="signIn"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="error">
          <b-col>
            <p class="text-grey">{{error}}</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button variant="second" pill @click="newPassword" style="min-width: 100px"><p v-if="!newPasswordLoading">Submit</p><b-spinner v-if="newPasswordLoading" variant="light" small /></b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      confirmationCode: undefined,
      confirmationCodeEntry: false,
      signInLoading: false,
      registerLoading: false,
      confirmSignUpLoading:false,
      error: false,
      codeResent: false,
      codeResentLoading:false,
      forgotPasswordLoading: false,
      forgotPasswordFlow: false,
      forgotPasswordFlowStart: false,
      newPasswordLoading: false,
      federatedSignInLoading: false,
      registerFlow: false,
      signInFlow: true,
      invalidPasswordError: "Please make sure your password has a mixture of uppercase and lowercase and at least one symbol"
    }
  },
  methods: {
    async signUp() {
      this.error= false
      this.forgotPasswordFlow = false
      if (!this.email || !this.password) {
        this.registerFlow = true
        this.signInFlow = false
      }else {
        this.registerLoading = true
        try {
          const { user } = await Auth.signUp({
              username: this.email,
              password : this.password,

              autoSignIn: { // optional - enables auto sign in after user is confirmed
                  enabled: true,
              }
          });
          if (user) {
            this.confirmationCodeEntry = true
          }
        } catch (error) {
          if (error.code === 'UsernameExistsException') {
            this.error = "An account already exists with this email, please try signing in or reset your password"
          }else {
            if (error.name === "AuthError"){
              this.error = "Please enter your username and password and hit 'Register' to sign up!"
            }else {
              if (error.name === "InvalidPasswordException"){
                this.error = "Your password must contain uppercase and lowercase letters, a number and a special character and be at least 7 characters."
              }else {
                console.log('error signing up:', error);
              }
            }
          }

        }
        this.registerLoading = false
      }
    },
    async signIn() {
      this.error= false
      if (!this.email || !this.password) {
        this.registerFlow = false
        this.signInFlow = true
      }else {
        this.signInLoading = true

        try {
            const user = await Auth.signIn(this.email, this.password);
            this.$router.push('/landing')
        } catch (error) {
          if (error.code === "UserNotConfirmedException") {
            this.confirmationCodeEntry = true
          }else {
            if (error.code === "NotAuthorizedException") {
              this.error = "Incorrect Username or Password"
            }else {
              console.log('error signing in', error);
            }
          }

        }
        this.confirmSignUpLoading = false
        this.signInLoading = false
      }
    },
    async  confirmSignUp() {
      this.error= false
      this.forgotPasswordFlow = false
      this.confirmSignUpLoading = true
      try {
        var res = await Auth.confirmSignUp(this.email, this.confirmationCode);
        this.signIn()
      } catch (error) {
        if (error.code === "CodeMismatchException"){
          this.error = "Incorrect code, please try again"
        }else {
          console.log('error confirming sign up', error);

        }
        this.confirmSignUpLoading = false
      }

    },
    async resendConfirmationCode() {
      this.codeResentLoading = true
      try {
          await Auth.resendSignUp(this.email);
          this.codeResent = true
      } catch (err) {
        this.error = "Issue resending code"
        console.log('error resending code: ', err);
      }
      this.codeResentLoading = false
    },
    async forgotPassword() {
      this.error = undefined
      if (!this.email) {
        this.error = "Please enter your email above and click 'forgot password' again"
      }else {
        this.forgotPasswordFlow = true
        // Send confirmation code to user's email
        try {
            await Auth.forgotPassword(this.email)
        } catch (error) {
          if (error.code === "LimitExceededException") {
            this.error = "Too many attempts to change password on this account"
            this.forgotPasswordFlow = false
          }
        }
      }

    },
    async newPassword() {
      this.newPasswordLoading = true
      try {
          await Auth.forgotPasswordSubmit(this.email, this.confirmationCode, this.password)
      } catch (error) {
        if (error.code === "CodeMismatchException"){
          this.error = "Incorrect code, please try again"
        }else {
          if (error.code === "InvalidPasswordException"){
            this.error = this.invalidPasswordError
          }else {
            console.log('error confirming sign up', error);
          }
        }
      }
      this.newPasswordLoading = false
    },
    async federatedSignIn() {
      this.federatedSignInLoading = true
      try {
          var creds = await Auth.federatedSignIn({ provider: 'Google' });
      } catch (error) {
        console.log(error)
      }
      this.federatedSignInLoading = false
    },

  },
  mounted() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
            const user = payload.data;
            // assign user
        } else if (event === 'autoSignIn_failure') {
            // redirect to sign in page
        }
    })
  }
  
}

</script>

<style>
.login-container {
  max-width: 400px !important
}

.input-label-left {
  text-align: start
}

.pill-input {
   border-radius: 16px !important;
}

</style>
