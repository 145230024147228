<template>
  <div>
    <b-list-group-item :id="`shopping-list-item-${item.id}`" style="padding-right:5px" :class="`square d-flex justify-content-between align-items-center ${!hideStrikethrough && item.checked?'grey':''}`">
      <b-container  class="p-0 m-0">
      <b-row no-gutters>
        <b-col cols="auto">
          <p :id="`item-${item.id}-name`" :class="`align-start  capitalize ${!hideStrikethrough && item.checked?'grey':'text-grey'}`">{{item.name}}</p>
        </b-col>
        <b-col cols="auto" class="ml-2" v-if="item.fromState">
          <i class="material-icons icon-middle mr-2 clickable" style="font-size: 18px" @click="$router.push('/leftovers')">info</i>
        </b-col>
        <b-col cols="auto" class="ml-1" v-if="item.frozen">
          <i v-b-tooltip.hover title="This item is in the freezer" style="font-size: 14px; padding: 2px" class="material-icons icon-middle mr-2 freezable-icon" >ac_unit</i>
        </b-col>
      </b-row>
      <b-row  no-gutters v-if="item.fromState">
        <b-col>
        <p class="align-start text-small text-grey" >leftover from a previous plan</p>
        </b-col>
      </b-row>
    </b-container>
      <div>
        <b-row no-gutters align-v="center" style="flex-wrap: nowrap" class="justify-content-end">
          <b-col v-if="item.used" cols="auto" class="ml-2">
            <i class="material-icons icon-small" >done</i>
            <span class="mx-1">used</span>
          </b-col>
          <b-col cols="auto" v-if="item.freezable && showFreezable">
            <i v-b-tooltip.hover title="This item can be stored in the freezer" class="material-icons icon-middle mr-2 freezable-icon" >ac_unit</i>
          </b-col>
          <b-col cols="auto" v-if="item.life > 7 && showLifeTooltip">
            <i v-b-tooltip.hover :title="`This item should last for around ${item.life} days, we'll show you recipes to help use it up over the coming weeks`" class="material-icons icon-middle mr-2">more_time</i>
          </b-col>
          <b-col cols="auto" v-if="item.quantity">
            <h3 :id="`item-${item.id}-quantity`"><b-badge :class="`quantity-badge mr-2 ${!hideStrikethrough && item.checked?'grey':''}`" variant="primary" pill>{{`${quantity}${item.unit === 'units'? '' : ` ${item.unit}`}`}}</b-badge></h3>
          </b-col>
          <b-col cols="auto" v-if="item.usedPartial">
            <p class="text-grey text-small">(of {{`${item.originalQuantity}${item.unit === 'units'? '' : ` ${item.unit}`}`}})</p>
          </b-col>
          <b-col cols="auto" v-if="showCheckbox">
            <b-form-checkbox v-model="item.checked" v-on:input="checkboxChanged" size="lg" ></b-form-checkbox>
          </b-col>
          <b-col cols="auto" v-if="showUnreserve">
            <i v-if="!reservationLoading" @click="unreserveItem" v-b-tooltip.hover :title="`Add this to your shopping list`" class="clickable material-icons icon-middle mr-2">close</i>
            <b-spinner v-if="reservationLoading" small label="Spinning" variant="dark" class="mr-2" ></b-spinner>
          </b-col>
      </b-row>
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: ['item', 'showCheckbox','showFreezable', 'showLifeTooltip', 'showUnreserve', 'hideStrikethrough', 'servings', 'reloadCounter'],
  data() {
    return {
      reservationLoading: false,
      quantity: undefined
    }
  },
  methods: {
    checkboxChanged(event) {
      this.$emit('checkboxChanged')
    },
    unreserveItem() {
      this.reservationLoading = true
      this.$emit('unreserveItem', this.item)
    }
  },
  mounted() {
    this.quantity = this.item.quantity
    if (this.servings) this.quantity = this.item.quantityWithServings(this.servings)
  },
  
  watch: {
    servings () {
      if (this.servings) this.quantity = this.item.quantityWithServings(this.servings)
    },
    item() {
      this.quantity = this.item.quantity
      if (this.servings) this.quantity = this.item.quantityWithServings(this.servings)
    }
  }
}

</script>

<style>

.striken {
  color: #8c8c8c;
  text-decoration: line-through;
}

.icon-small {
  font-size: 14px;
}

.background-used {
  background-color: #CDCBD6
}



</style>
