<template>
    <div>
        <div class="p-4 text-grey" >
            <p class="p-2">Do you need to add any of these store items to your shopping list?</p>
            <b-list-group>
            <b-list-group-item v-for="storeItem of storeItems"  v-bind:key="storeItem.id" :id="`store-item-${storeItem.id}`" >
                <b-row>
                    <b-col cols="8">
                <b-form-checkbox class="capitalize" v-model="storeItem.moveToMainList" name="check-button" @change="checkboxChanged(storeItem)"  size="md" switch>
                    {{storeItem.name}}
                </b-form-checkbox>
                </b-col>
                <b-col cols="4" v-if="storeItem.quantity">
                    <h3><b-badge :class="`quantity-badge mr-2 `" variant="primary" pill>{{`${storeItem.quantity}${storeItem.unit === 'units'? '' : ` ${storeItem.unit}`}`}}</b-badge></h3>
                </b-col>
            </b-row>
            </b-list-group-item>
            </b-list-group>
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    components: {
    },
    props: ['storeItems', 'plan'],
    data() {
      return {
      }
    },
    methods: {
      async checkboxChanged(item) {
        if (item.moveToMainList) this.plan.shoppingList.moveStoreIngredientToList(item)
        if (!item.moveToMainList) this.plan.shoppingList.removeStoreIngredientFromList(item)
        await this.plan.save()
      },
      
    },
    mounted() {
    },
    watch: {
    }
  }
  
  </script>
  
  <style>
  
  </style>
  