<template>
  <div>
      <b-spinner v-if="loading" label="Spinning" class="mt-2"></b-spinner>
      <div  v-if="!loading && suggestions">

    <p class="mb-2">Here are some recipes you can add to reduce leftovers...</p>
      <SuggestionView class="mb-3" v-for="(suggestion, i) of suggestions.filter(obj => obj.type === 'add')" id="suggestionPages" v-bind:key="i"
      :suggestion="suggestion" :plan="plan" :userState="userState"
        v-on:addRecipeToPlan="addRecipeToPlan" :index="i" @recipeSelected="recipeSelected" />
      </div>
  </div>
</template>

<script>
import SuggestionView from './SuggestionView'
export default {
  components: {
    SuggestionView
  },
  props: ['plan', 'reloadCounter', 'suggestions', 'userState'],
  data() {
    return {
      loading: false,
      currentPage: 1
    }
  },
  methods: {
    recipeSelected(recipe) {
      this.$emit('recipeSelected', recipe)
    },
    async addRecipeToPlan(recipeEvent) {
      this.$emit('addRecipeToPlan', recipeEvent)
    }
  },
  mounted() {
  },
  watch: {
    reloadCounter() {
    }
  }
}

</script>

<style>


.strikethrough {
  text-decoration: line-through;
}

.align-start {
  text-align: start
}

.suggestions-modal {
  background-color: black
}

.my-class {
  background-color: red !important;
}


</style>
