<template>
    <div>
        <div class="p-4" v-if="userState">
        <p class="p-2">Do you want to use up any of these previous leftovers?</p>
        <div v-for="item of userStateItemsToUse" v-bind:key="`${item.ingredient.id}-${item.created}`" class="px-2 pb-3">
          <b-row class="" no-gutters>
            <b-col cols="auto" class="mr-2">
              <p class="capitalize bold">{{item.ingredient.name}}</p>
            </b-col>
            <b-col cols="auto" v-if="item.frozen">
              <i  class="material-icons icon-middle mr-2 freezable-icon text-small" >ac_unit</i>
            </b-col>
            <b-col cols="auto">
              <b-badge :class="`quantity-badge mr-2`" variant="primary" pill>{{`${item.quantity}${item.unit === 'units'? '' : ` ${item.unit}`}`}}</b-badge>
            </b-col>
          </b-row>
          <b-row class="pb-2">
            <b-col cols="auto">
              <p class="text-small">From plan about {{item.createdText}}</p>
            </b-col>
          </b-row>
          <b-list-group class="clickable">
            <b-list-group-item @click="optionSelected('yes', item)" :active="optionIsActive('yes', item)" :disabled="enoughReservedForRecipe(item)">Yes</b-list-group-item>
            <b-list-group-item @click="optionSelected('no', item)" :active="optionIsActive('no', item)" :disabled="enoughReservedForRecipe(item)">No</b-list-group-item>
            <b-list-group-item @click="optionSelected('gone', item)" :active="optionIsActive('gone', item)" :disabled="enoughReservedForRecipe(item)">I no longer have this</b-list-group-item>
          </b-list-group>
          <b-collapse :id="`no-longer-have-collapse-${item.id || item.ingredient.id}-${item.planId}`">
          <b-list-group class="p-3">
            <b-list-group-item
              v-for="option of noLongerHaveItOptions"
              v-bind:key="option.name"
              @click="noLongerHaveItOptionSelected(option, item)"
              :active="noLongerHaveItOptionActive(option, item)"
            >
              <b-row no-gutters class="clickable" >
              <b-col cols="auto">
                <i  class="material-icons icon-middle mr-2">{{option.icon}}</i>
              </b-col>
              <b-col cols="auto">
                <p>{{option.text}}</p>
              </b-col>
            </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  import {getUserState} from '../clients/RecipeClient'
  export default {
    components: {
    },
    props: ['recipe', 'plan', 'userStateItemsToUse'],
    data() {
      return {
        storeItems: undefined,
        optionsSelected: [],
        userState: undefined,
        noLongerHaveItOptions: [
          { text: "Used It Elsewhere", name: 'used', icon: 'check_circle', clicked: 'false'},
          { text: "Binned It", name: 'binned', icon: 'delete'},
          { text: "Other", name: 'other', icon: 'circle'}
        ],
        noLongerHaveItOptionsSelected: [],
        collapsesOpen: []
      }
    },
    methods: {
      optionIsActive(option, item) {
        return this.optionsSelected.filter(obj => obj.option === option && item.ingredient.id === obj.ingredient.id && obj.quantity === item.quantity && item.planId === obj.planId).length > 0
      },
      optionSelected(option, item) {
        this.optionsSelected = this.optionsSelected.filter(obj => obj.ingredient.id !== item.ingredient.id || obj.quantity !== item.quantity || obj.planId !== item.planId)
        this.optionsSelected.push({
          ingredient: item.ingredient,
          quantity: item.quantity,
          planId: item.planId,
          option: option,
          unit: item.unit
        })
        var collapseId = `no-longer-have-collapse-${item.id || item.ingredient.id}-${item.planId}`
        if (option === "gone") {
          if (this.collapsesOpen.indexOf(collapseId) === -1) {
            this.$root.$emit('bv::toggle::collapse', collapseId)
            this.collapsesOpen.push(collapseId)
          }
        }else {
          if (this.collapsesOpen.indexOf(collapseId) > -1) {
            this.$root.$emit('bv::toggle::collapse', collapseId)
            this.collapsesOpen = this.collapsesOpen.filter(obj => obj !== collapseId)
          }
        }
        this.$emit('optionsChanged', this.optionsSelected)  
        
      },
      enoughReservedForRecipe(item) {
        if (this.optionsSelected && this.optionsSelected.filter(obj => obj.ingredient.id === item.ingredient.id && obj.quantity === item.quantity && obj.planId === item.planId).length > 0) {
          return false
        }
        var required = getAmountRequiredForRecipe(item, this.recipe).quantity
        var reserved = getAmountReserved(item, this.optionsSelected)
        return reserved >= required
      },
  
      async loadUserState() {
        this.userState = await getUserState()
      },
      noLongerHaveItOptionActive(option, item) {
        return this.noLongerHaveItOptionsSelected.filter(obj => obj.id === item.ingredient.id && obj.planId === item.planId && obj.option === option.name).length > 0
      },
      noLongerHaveItOptionSelected(option, item) {
        this.noLongerHaveItOptionsSelected = this.noLongerHaveItOptionsSelected.filter(obj => obj.id !== item.ingredient.id && obj.planId !== item.planId)
        this.noLongerHaveItOptionsSelected.push({
          id: item.ingredient.id,
          planId: item.planId,
          option: option.name
        })
        let thisOption = this.optionsSelected.find(obj => obj.ingredient.id === item.ingredient.id || obj.quantity === item.quantity || obj.planId === item.planId)
        thisOption.reason = option.name
        this.$emit('optionsChanged', this.optionsSelected)  
      },
      async done() {
        
        this.$emit('savingReservationsComplete')
      }
    },
    mounted() {
        this.optionsSelected = []
        this.loadUserState()
    },
    watch : {
      recipe() {
        this.optionsSelected = []
        this.loadUserState()
      }
    }
  }
  
  function getAmountRequiredForRecipe(leftover, recipe) {
    var allIngredients = recipe.ingredients.filter(obj => obj.id === leftover.ingredient.id && obj.unit === leftover.unit)
    return {
      quantity: allIngredients.map(obj => obj.quantity).reduce((prev, cur) => prev + cur),
      recipeIds: allIngredients.map(obj => obj.id)
    }
  }
  
  
  function getAmountReserved (ingredient, reservations) {
    return reservations.filter(obj => obj.ingredient.id === ingredient.id && obj.unit === ingredient.unit && obj.option === 'yes').map(obj => obj.quantity).reduce((prev, cur) => prev + cur, 0)
  }
  
  
  </script>
  
  <style>
  
  </style>
  