<template>
  <div>
    <div class="d-block">
      <b-navbar toggleable="lg"  fixed="top" type="dark" style="background-color: var(--secondo)"  >
        <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>
        <b-navbar-brand to="/"  class="d-none d-lg-block">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <div class="logo-navbar">
                <div>LEAN<br>LARDER</div>
              </div>
            </b-col>
          </b-row>
        </b-navbar-brand>
        <b-navbar-brand to="/" class="d-lg-none nav-center-logo">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <div class="logo-navbar">
                <div>LEAN<br>LARDER</div>
              </div>
            </b-col>
          </b-row>
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav class="pt-3 pt-lg-0" >
          <b-navbar-nav class="">
            <b-nav-item to="/about/how-it-works" :active="$route.path === '/about/how-it-works'">How It Works</b-nav-item>
            <b-nav-item to="/about/recipes" :active="$route.path === '/about/recipes'">Our Recipes</b-nav-item>
            <b-nav-item to="/about/why-is-food-waste-important" :active="$route.path === '/about/why-is-food-waste-important'">About Food Waste</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-nav class='nav-login-right bold'>
          <b-nav-item to="/login" class="navbar-login" >
            <p >Login / Join</p>
          </b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  props: [],
  components: {
  },
  data() {
    return {

    }
  },
  methods: {


  },
  mounted() {
  },
  watch: {
  }
}
</script>

<style>

.nav-center-logo {
  position: absolute;
  left: 50%;
  margin-left: -40px !important;
  display: block;
  top: 3px
}

.nav-login-right {
  position: absolute;
  right:15px;
  display: block;

  border: 1px solid var(--secondo);
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.nav-link.active {
  font-weight: bold !important
}

.navbar-light .nav-link {
  color: #484848 !important
}

.navbar-dark .nav-link {
  color: #eeeeee !important
}

.navbar-dark .navbar-toggler {
  border: 0px
}

@media screen and (max-width:991px) {
  .nav-login-right {
    top: 7px;
  }
}

@media screen and (min-width:991px) {
  .nav-login-right {
    top: 10px;
  }
}

.logo-navbar {
  height: fit-content;
  width: fit-content;
  border: 0px solid white;
  background-color: transparent;
  color: white;
  font-weight: 900;
  font-size: 14px ;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.2em;
}


</style>
