<template>
  <div>
    <b-modal id="add-recipe-modal" hide-header scrollable :static="isStatic">
      <div class="text-center">
        <b-spinner class="m-4 align-text-center" v-if="openLoading" label="Spinning" variant="secondary"></b-spinner>
      </div>
      <AddRecipeSwap v-if="!loading && !openLoading && swap" :swap="swap" @undoSwap="undoSwap" @redoSwap="redoSwap"/>
      <AddRecipeStoreItems  v-if="storeItems.length > 0 && !loading && view ==='storeItems'" :plan="plan" :storeItems="storeItems"/>
      <AddRecipeUseStateItems v-if="view ==='leftoverItems' && !loading && !openLoading" :userStateItemsToUse ="userStateItemsToUse" :plan="plan" :recipe="recipe" @optionsChanged="reservationOptionsChanged"/>
      <div slot="modal-footer">
        <b-button-group  v-if="recipe && !openLoading && view === 'storeItems'">
          <b-button class="full-button recipe-button-add"  squared>
            <p v-if="!loading" @click="nextModal">Done</p>
            <b-spinner v-if="loading" small label="Spinning" variant="light" ></b-spinner>
          </b-button>
        </b-button-group>
        <b-button-group v-if="view==='leftoverItems'">
            <b-button class="full-button recipe-button-add"  squared>
              <p @click="saveReservations" v-if="!loading">Done</p>
              <b-spinner v-if="loading" small label="Spinning" variant="light" ></b-spinner>
            </b-button>
          </b-button-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AddRecipeStoreItems from './AddRecipeStoreItems'
import AddRecipeSwap from './AddRecipeSwap'
import AddRecipeUseStateItems from './AddRecipeUseStateItems'
import {reserveStateIngredient, updateStateItem, getUserState} from '../clients/RecipeClient'
export default {
  components: {
    AddRecipeStoreItems, AddRecipeUseStateItems, AddRecipeSwap
  },
  props: ['recipe', 'plan', 'reloadCounter', 'userState', 'isStatic', 'openLoading', 'swap'],
  data() {
    return {
      storeItems: [],
      view: 'storeItems',
      reservationOptions: [],
      updatedUserState: undefined,
      userStateItemsToUse: undefined,
      loading: false
    }
  },
  methods: {
    async nextModal() {
      this.loading = true
      this.updatedUserState = await getUserState()
      this.$emit('stateUpdated')
      this.userStateItemsToUse = undefined
      this.userStateItemsToUse = this.updatedUserState.getPotentialStateItemsToUseForRecipes([this.recipe], this.plan)
      if (this.userStateItemsToUse && this.userStateItemsToUse.length > 0) {
        this.view = 'leftoverItems'
        this.loading = false
      }else {
        this.view = 'storeItems'
        this.loading = false
        this.$bvModal.hide('add-recipe-modal')
      }
    },
    reservationOptionsChanged(options) {
      this.reservationOptions = options
    },
    undoSwap() {
      this.$emit('undoSwap', this.plan)
    },
    redoSwap() {
      this.$emit('redoSwap', this.plan)
    },
    async saveReservations() {
      this.loading = true
      for (var option of this.reservationOptions) {
          if (option.option === 'yes') {
            var recipeSummary = getRecipesAndAmountRequired(option, this.recipe)
            await reserveStateIngredient({
              planId: option.planId,
              ingredientId: option.ingredient.id,
              quantity: recipeSummary.quantity,
              unit: option.unit,
              reservedByPlanId: this.plan.id
            })
          }else {
            if (option.option === "gone") {
              //Update the UserLeftoverItem
              var input = {
                ingredient: {id: option.ingredient.id},
                planId: option.planId,
                unit: option.unit,
                action: option.reason || 'binned'
              }
  
              await updateStateItem(input)
            }
          }
        }
        this.loading = false
        this.reservationOptions = []
        this.view = 'storeItems'
        this.$bvModal.hide('add-recipe-modal')
    },
    createStoreItemList() {
      
      if (!this.recipe) return
      this.storeItems = []
      this.storeItems = Array.from(this.recipe.ingredients.filter(ingredient => shouldAskAboutStoreIngredient(ingredient, this.userState, this.plan))).map(ingredient => {
        return {
          id: ingredient.id,
          name: ingredient.name,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
          moveToMainList: false
        }
      })
    
      if (!this.storeItems || this.storeItems.length === 0) {
       this.nextModal()
      }
    }
  },
  mounted() {
    this.createStoreItemList()
  },
  watch: {
    reloadCounter() {
      this.createStoreItemList()
    },
    openLoading () {
      if (this.openLoading) this.storeItems = []
    }
  }
}

function shouldAskAboutStoreIngredient(ingredient, userState, plan) {
  return userHasInStore(ingredient, userState)  && plan.shoppingList.storeIngredientsInList.indexOf(ingredient.name) === -1
}
function userHasInStore(ingredient, state) {
  return state && state.storeItems && state.storeItems.map(obj => obj.id).indexOf(ingredient.id) > -1
}

function getRecipesAndAmountRequired(ingredient, recipe) {
    var quantity = 0
    var usingRecipes = []
    const matchingIngredient = recipe.ingredients.filter(obj => obj.unit === ingredient.unit && obj.id === ingredient.ingredient.id)[0]
    if (matchingIngredient){
      quantity += matchingIngredient.quantity
      usingRecipes.push(recipe)
    }
    return {quantity: quantity, recipes: usingRecipes}
  }
</script>

<style>
.blank-footer {
  background-color: #00000000;
  padding: 0px;
  margin-bottom: 5px;
  border-top: 0px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
