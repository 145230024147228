<template>
  <div id="app" >
    <keep-alive >
      <router-view style="min-height: 100vh"/>
    </keep-alive>
    <CookieBanner v-if="showCookieBanner" @updateCookies="updateCookies" />
    <Footer v-if="!showCookieBanner" @updateCookies="updateCookies" />
  </div>
</template>

<script>
import User from './models/User'
import CookieBanner from '@/components/CookieBanner'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  components: {
    CookieBanner, Footer
  },
  data() {
    return {
      authState: undefined,
      user: undefined,
      showCookieBanner: false
    }
  },
  methods: {
    async updateCookies(enabled) {
      this.user.cookiesEnabled = enabled
      await this.user.updateCookies()
      if (this.user.cookiesEnabled === true) {
        this.$gtag.optIn()
      }
      if (this.user.cookiesEnabled === false) {
        this.$gtag.optOut()
      }
      this.showCookieBanner = false
    }
  },
  async mounted () {
    this.user = new User()
    await this.user.loadUser()
    if (this.user.signInUserSession) {
      await this.user.loadAdditionalUserData()
      if (this.user.cookiesEnabled === null) {
        this.$gtag.optOut()
        this.showCookieBanner = true
      }

      if (this.user.cookiesEnabled === true) {
        this.$gtag.optIn()
      }
      if (this.user.cookiesEnabled === false) {
        this.$gtag.optOut()
      }
    }else {
      this.$gtag.optOut()
    }
  },
  watch: {
    async $route(to, from) {
      if (to.name !== 'Home' && to.name !== 'HowItWorks' && to.name !== 'WhyIsFoodWasteImportant' && to.name !== 'Login' && to.name !== 'PublicRecipes') {
        if (this.user.cookiesEnabled === undefined || this.user.cookiesEnabled === null) {
          await this.user.loadAdditionalUserData()
          if (this.user.cookiesEnabled === undefined|| this.user.cookiesEnabled === null) this.showCookieBanner = true
        }else {
          this.showCookieBanner = false
        }
      }
    }
  }
}

</script>

<style>
#app {
  font-family: Urbanist, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-primary);

  background-color: var(--colour-primary);
  opacity: 1;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

:root {
  --colour-primary: #D96846 ;
  --colour-button-primary: var(--tertiary);
  --text-primary: white;
  --text-secondary: #414141;
  --colour-button-negative: #CDCBD6;
  --primo: #CDCBD6;
  --secondo: #D96846;
  --tertiary: #596235;
  --fourth: #2F3020;
  --light-grey: #f8f8f8;
  --amplify-border-radius: 2px;
  --amplify-padding: 2px;
  --amplify-primary-color: white;
  --amplify-background-color:#D96846;
  --amplify-primary-contrast: #414141;
  --amplify-grey: #f8f8f8;
  --amplify-secondary-color: #white;
  --amplify-secondary-contrast: #D96846;
  --amplify-primary-shade: #414141;
  --amplify-primary-tint: #414141;
  --amplify-font-family: Urbanist, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --amplify-text-sm: 1em
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  height: 60vh;
  --box-shadow: none;
  --padding: 0px;
  --width: 380px;
  --margin-bottom: 0px;
  --container-justify: start;
  --container-align: start;
}


.btn-secondary, .btn-secondary:hover, .btn-secondary.dropdown-toggle {
  background-color: white !important;
  color: #2c3e50 !important;
  font-weight: bold !important;
  border: none !important;
}

.recipe-button-more {
  background-color: var(text-primary) !important;
  max-width: 25%;
}

.recipe-button-add, .recipe-button-add:hover {
  background-color: var(--secondo) !important;
  color: var(--text-primary) !important;
}

.recipe-button-remove {
  background-color: var(--secondo) !important;
  color: var(--text-primary) !important;
}

.recipe-button-close {
  background-color: var(--primo) !important;
  max-width: 25%;
}





.input {
  color: blue !important;
}

h1 {
  font-size: 30px;
  text-align: left;
}

h2 {
  font-size: 18pt !important;
}

h3 {
  color: var(-- text-primary);
  font-size: 14pt !important;
  text-align: left;
  font-weight: 700 !important;
  margin-bottom: 0px !important;
}

h4 {
  color: var(-- text-primary);
  font-size: 12pt !important;
  text-align: left !important;
  font-weight: 700 !important;
  margin-bottom: 0px !important;
}

h5 {
  color: var(--text-secondary);
  font-size: 12pt !important;
  text-align: left;
  font-weight: 700 !important;
  margin-bottom: 0px !important;
}

.clickable {
  cursor: pointer !important;
}

.nav-padding {
  margin-left: 0px !important;
  margin-right: 0px !important;
  min-width: 100%;
}

.nav-padding-top {
  padding-top: 80px !important;
}

@media screen and (max-width:768px) {
  .nav-padding {
    margin-top: 80px !important;
  }
}


@media screen and (min-width:768px) {
  .nav-padding {
  margin-top: 120px !important;
  padding-left: 20vw !important;
  padding-right: 20vw !important;
  }
}

.row {

}

.text-right {
  text-align: right;
  margin-right: 10px;
}

.text-small {
  font-size: 12px;
}

.grey-text {
  color: #878787 !important;
  font-size: 14px;
}

.h1-grey {
  color: var(--text-secondary) !important;
}

p {
  margin-bottom: 0px !important;
}

@font-face {
  font-family: 'Urbanist', sans-serif;
}



.badge-primary {
  background-color: var(--colour-button-primary) !important
}

.badge-negative {
  background-color: var(--colour-button-negative);
  color: var(--text-secondary)
}

.btn-primary, .btn-primary:hover {
  background-color: var(--tertiary) !important;
  border: 0px !important;
}

.btn-negative, .btn-negative:hover {
  background-color: var(--colour-button-negative) !important;
  color: var(--text-secondary);
  border: 0px !important;
}

.fill-background {
  background-color: #69FFC5
}

.text-grey {
  color: var(--text-secondary) !important;
}

.quantity-badge {
   border: 1px solid transparent;
   text-align: center;
   vertical-align: middle;
   color: var(--text-primary);
}

.square {
  border-radius: 0px !important;
}

.material-icons {
  color: var(--text-secondary);
}

.material-icons-light {
  color: white !important;
}


.icon-middle {
  vertical-align: middle;
}

.modal-content {
  background-color: white !important;
  border: none !important;
  font-family: Urbanist, sans-serif !important;
}

.btn-list {
  background-color: var(--colour-primary) !important;
  color: var(--text-primary) !important;
  border-top: 1px solid var(--light-grey) !important;
  font-family: Urbanist, sans-serif;
  font-size: 15px !important;
  padding: 10px !important;
}

.text-primary-mine {
  color: var(--text-primary) !important
}

.btn-1 {
  background-color: var(--text-secondary) !important;
  color: white !important;
  font-weight: bold !important;
}

.btn-2 {
  background-color: var(--colour-primary) !important;
  color: white !important;
  font-weight: bold !important;
}


@media screen and (min-width:768px) {
  .body-text {
    font-size: 1em;
  }
}

@media screen and (max-width:768px) {
  .body-text {
    font-size: 0.9em;
  }
}


@media screen and (max-width:405px) {
  .body-text {
    font-size: 0.9em;
  }
}

@media screen and (min-width:768px) {
  .responsive-h5{
    font-size: 1.2em !important;
  }
}

@media screen and (max-width:768px) {
  .responsive-h5 {
    font-size: 1em !important;
  }
}


@media screen and (max-width:405px) {
  .responsive-h5{
    font-size: 1em !important;
  }
}

.page-item .page-link {
  border: 0px;
  color: var(--text-secondary)
}

.page-item.active .page-link {
  color: var(--text-primary) !important;
  background-color: var(--tertiary) !important;
}

.error-text {
  font-weight: bold
}

.capitalize {
  text-transform: capitalize;
}


.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--colour-primary) !important;
  border-color: var(--colour-primary) !important;
  color: #2c3e50 !important;
}

.grey {
  color: #eaeaea;
  text-decoration: line-through;
  background-color: #bfbfbf !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0rem !important;
}

.btn-transparent, .btn-transparent:hover {
  color: white !important;
  background-color: #00000000 !important;
}

.bottom-right-circle-button, .bottom-right-circle-button:hover {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 800!important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent!important;
  box-shadow: 0 4px 12px rgb(0 0 0 / 30%);
  background-color: var(--warning) !important;
  width: 100%;
  min-height: 60px;
}

.bottom-right-button, .bottom-right-button:hover {
  position: fixed;
  right: 1.2em;
  top: 7em;
  z-index: 500!important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent!important;
  box-shadow: 0 4px 12px rgb(0 0 0 / 30%);
  background-color: var(--warning) !important;
  min-height: 90px;
  min-width: 150px;
  max-width: 200px;
}

.freezable-icon {
  background-color: #7EDAFF;
  padding:5px;
  border-radius: 50%;
  color: white;
}

.freezable-icon-small {
  background-color: #7EDAFF;
  border-radius: 50%;
  color: white;
}

.background-secondary {
  background-color: var(--secondo);
  color: white;
}

.no-border {
  border: 0px !important
}

.no-hover{
    pointer-events: none;
}

.bold {
  font-weight: bold;
}
.inline-text > p, .inline-text > h3{
    vertical-align:top;
    display:inline-block;
}

.underlined {
  text-decoration: underline;
}

.full-page {
  min-height: 100vh !important;
}

.btn-second {
  background-color: var(--secondo);
  color: white;
  font-weight: bold;
}

.btn-third {
  background-color: white;
  color: var(--secondo);
  font-weight: bold;
  border: 2px solid var(--secondo)
}
</style>
