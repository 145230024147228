<template>
  <div>
    <NavBar :plan="plan" />
    <b-container class="nav-padding" >
      <b-row>
        <b-col>
          <b-card  class="pt-3 pb-5 px-4 mb-2">
            <h2 class="text-grey pb-4">Your stats</h2>
            <div id="stats-section" v-if="stats" >
              <div class="text-grey" >You have cooked <span id="stats-recipes-cooked" class="bold" >{{stats.recipes.total}}</span> recipes
              </div>
            </div>
            <b-row>
              <b-col cols="12" lg="6">
            <div v-if="stats"  class="text-grey mt-3" >Your Top Cuisines</div>
            <div  v-if="stats" class="d-flex justify-content-center mt-3">
              <apexchart v-if="options && series" width="300" height="250" type="bar" :options="options" :series="series"></apexchart>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div v-if="stats"  class="text-grey mt-3" >Your Top Ingredients</div>
            <div  v-if="stats" class="d-flex justify-content-center mt-3">
              <apexchart v-if="ingredientOptions && ingredientSeries" width="300" height="250" type="bar" :options="ingredientOptions" :series="ingredientSeries"></apexchart>
            </div>
          </b-col>
          </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card  class="pt-2 pb-5 px-4">
            <h2 class="text-grey pb-4">Your Account</h2>
            <div id="user-account-section" v-if="user">
              <h3 class="text-grey mb-2">Cookies</h3>
              <p  class="text-grey text-small mb-2" >We use analytics cookies to learn about how people use our product to make it better for everyone. We do understand, though, that some people are uncomfortable with the use of cookies so if you would like to disable them, you can do so here. We have made every effort to make sure LeanLarder works just as well without them. You can see our full cookie policy <a href="/cookies">here</a>.</p>
              <b-form-checkbox id="cookie-checkbox" v-model="user.cookiesEnabled" switch @change="cookiesChanged(user.cookiesEnabled)">
              </b-form-checkbox>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    </div>
</template>

<script>
import NavBar from './NavBar'
import User from '../models/User'
import {getUserStats} from '../clients/RecipeClient'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    NavBar
  },
  props: ['plan'],
  data() {
    return {
      user: undefined,
      stats: undefined,
      options: undefined,
      series: undefined,
      ingredientOptions: undefined,
      ingredientSeries: undefined
    }
  },
  methods: {
    async loadUser() {
      var tempUser = new User()
      await tempUser.loadAdditionalUserData()
      this.user = tempUser
    },
    async cookiesChanged() {
      await this.user.updateCookies()
      if (this.user.cookiesEnabled) this.$gtag.optIn()
      if (!this.user.cookiesEnabled) this.$gtag.optOut()
    },
    async loadStats() {
      this.stats = await getUserStats()
      let cuisineNameArray = Object.entries(this.stats.recipes.cuisine.name).map(obj => {
        return {
          name: obj[0],
          count: obj[1]
        }
      }).sort((a,b) => b.count - a.count).slice(0, 10);
      let ingredientsArray = Object.entries(this.stats.ingredients.name).map(obj => {
        return {
          name: obj[0],
          count: obj[1]
        }
      }).sort((a,b) => b.count - a.count).slice(0, 10);
      this.options = {
        chart: {
          id: 'cuisine-chart',
          toolbar: {show: false}
        },
        xaxis: {
          categories: cuisineNameArray.map(obj => obj.name)
        },
        tooltip: {
          theme: 'dark'
        },
        colors: ['#D96846']
      },
      this.series = [{
        name: 'recipes',
        data: cuisineNameArray.map(obj => obj.count)
      }]

      this.ingredientOptions = {
        chart: {
          id: 'ingredients-chart',
          toolbar: {show: false}
        },
        xaxis: {
          categories: ingredientsArray.map(obj => obj.name)
        },
        tooltip: {
          theme: 'dark'
        },
        colors: ['#D96846']
      },
      this.ingredientSeries = [{
        name: 'recipes',
        data: ingredientsArray.map(obj => obj.count)
      }]
    }
  },
  mounted() {
    this.loadUser()
    this.loadStats()
  },
  watch: {
  }
}

</script>

<style>

</style>
