<template>
  <div>
    <b-container  fluid="md" class="mt-4 mb-5" >
      <b-row align-h="center">
        <b-col cols="12" md="10" >
          <h1 class="text-grey">Why food waste matters and how we can all help fix it </h1>
          <h3 class="text-grey mt-5">People don't want to waste food</h3>
          <p class="text-grey text-left mt-4">People don't want to waste food. It's a waste of effort throughout a supply chain, a waste of money for the person throwing it away and, aside from anything else, it feels awful. Food waste happens throughout the supply chain, well publicised examples include the downsides of fish quotas <a target="_blank" href="https://www.theguardian.com/environment/2022/mar/25/clientearth-legal-action-eu-unsustainable-fishing-quotas">[1]</a> (where fishermen have to throw dead catch into the sea rather than land it and risk a fine for exceeding their quotas) or the food that gets thrown away because it doesn't meet the standards of a supermarket <a href="https://www.theguardian.com/environment/2016/jul/14/from-field-to-fork-the-six-stages-of-wasting-food" target="_blank">[2]</a>. But in Europe around 13% of food is wasted because of consumers buying food and throwing it away, compared to 24% in the rest of the supply chain put together <a target="_blank" href="https://www.fao.org/3/mb060e/mb060e00.pdf">[3]</a>.</p>
          <h3 class="text-grey mt-5">So why does waste happen?</h3>
          <p class="text-grey text-left mt-4">Market pressures cause a considerable amount of waste at other parts of the process. Farmers need to plan months, perhaps years, before they can sell their crop and so if there is no demand, there will be waste. They are also subject to the commercial pressures of their customers - the large supermarkets - who set standards on vegetables that are not compatible with the nature of farming. A percentage of carrots will be lumpy, but supermarkets won't buy them.</p>
          <h3 class="text-grey mt-5">Consumers need help</h3>
          <p class="text-grey text-left mt-4">But what about the 13% of food that is thrown away by consumers? Consumers throw food away because they buy too much of it. It really is as simple as that Most of us are lucky in the modern, developed world not to have to worry about preserving food so that we can keep eating through the winter. We have incredible availability of produce all year round, available at a reasonable cost and convenience. Presented with this plentiful supply we naturally buy more than we need, or spend less time concerned about whether we throw any of it away. We've lost the ability and perhaps the time to think creatively about the things we're buying.</p>
          <h3 class="text-grey mt-5">How's your pension?</h3>
          <p class="text-grey text-left mt-4">This is a category of problem where consumers don't like what they're doing but the consequences to the individual of continuing to do this aren't significant enough to put the effort in to solve it. Some other examples include exercising (no one wants to be unfit and live less, but exercise is hard work), good pension management and cleaning your oven. Consumers take the path of least resistance. There is undoubtedly pain in throwing away food, but the effort required to make lean shopping lists and buy only what you need is not to be underestimated. It's hard work.</p>
          <h3 class="text-grey mt-5">The solution</h3>
          <p class="text-grey text-left mt-4">What we're seeing is a positive trend in solving some of these sorts of problems with technology. Online tools make managing your pension much easier (no, I still don't do it either) and interactive home exercise apps are reducing barriers to getting fit (Zwift, Pelaton, Joe Wicks on YouTube etc). We at Lean Larder believe the food waste problem is perfect for a technology solution because it provides benefit to people (reducing waste, organising shopping) while tackling the obstacle of brain effort (technology is great at doing this). </p>
          <p class="text-grey text-left mt-4">So give it a go. Let us know what you think and hopefully we can save the world together.</p>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
    
  },
  data() {
    return {
    }
  },
  methods: {


  },
  mounted() {

  }

}

</script>

<style>

@media screen and (max-width:991px) {
  .public-recipe-card {

  }
}

@media screen and (min-width:991px) {
  .public-recipe-card {
    height: 400px
  }
}

</style>
