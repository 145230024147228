<template>
  <div>
    <b-container  fluid="lg"  >

      <b-row no-gutters>

        <b-col cols="12" lg="4" v-for="recipe of recipes" v-bind:key="recipe.id">
            <b-card class="p-2 m-2 public-recipe-card" v-if="!recipe.type"
             no-body style="border: 0px; "
             >
             <b-container fluid style="height:100%">


              <b-row no-gutters style="height:45%" align-v="center">
              <b-col cols="12" v-if="recipe.imageUrl">
                <b-img-lazy id="recipe-image" :src="recipe.imageUrl"  alt="Image" :class="`rounded-0 recipe-card-image `"></b-img-lazy>
              </b-col>
            </b-row>
            <b-row style="height:50%" align-v="top" no-gutters>
              <b-col >
                    <h5 class="text-center mb-2 mt-2" style="word-wrap: normal; font-size:1.2em !important">{{recipe.title}}</h5>
                  <div >
                    <b-row align-h="center" no-gutters>
                      <b-col class="p-0 mb-2 mt-2 mt-lg-0"  cols="auto" align-self="start">
                        <p class="text-grey body-text d-none d-lg-block" style="font-size:14px !important">{{recipe.description}}</p>
                        <p class="text-grey body-text d-block d-lg-none" style="font-size:14px !important">{{recipe.description}}</p>
                      </b-col>
                    </b-row>

                    <b-row align-h="center">
                      <b-col class="p-0" cols="auto" align-self="start">
                        <p class="grey-text body-text"><i class="material-icons grey-text icon-middle" style="font-size:22px !important"> schedule</i>{{recipe.time}} mins</p>
                      </b-col>
                    </b-row>
                  </div>
              </b-col>
            </b-row>
          </b-container>

            </b-card>
            <b-card class="p-4 m-2 public-recipe-card" v-if="recipe.type === 'content'"
             no-body style="border: 0px; ">
             <b-container fluid style="height:100%">
              <b-row align-v="center" style="height:100%" no-gutters>
                <b-col >
                  <h3 class="text-center mb-3 text-grey" style="font-size:30px !important">{{recipe.title}}</h3>
                  <p class="mt-1 text-center text-grey" v-html="recipe.body"></p>
                </b-col>
              </b-row>

            </b-container>
            </b-card>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Recipe from '../models/Recipe'
export default {
  name: '',
  components: {
  },
  data() {
    return {
      recipes : undefined,
      content: [
        {
          title: "Be Inspired",
          body: "Every week we publish a list of 50 recipes with a variety of cuisines. No need to scroll through hundreds of recipes - we'll help you find your dinner inspiration.",
          type: 'content'
        },
        {
          title: "Sensible Recipes",
          body: "Our recipes are all suitable for midweek cooking and use ingredients that are available in all major supermarkets. <br><br>",
          type: 'content'
        },
        {
          title: "Waste Conscious",
          body: "Our recipes are designed to complement each other so that any leftovers can be used up by other recipes.<br><br>You'll also make use of your ever growing store cupboard",
          type: 'content'
        }
      ]
    }
  },
  methods: {


  },
  mounted() {
    var data = require('../assets/content/sample-recipes.json')
    this.recipes = data.map(obj => new Recipe(obj))
    this.recipes.splice(0,0,this.content[0])
    this.recipes.splice(4,0,this.content[1])
    this.recipes.splice(8,0,this.content[2])

  }

}

</script>

<style>

@media screen and (max-width:991px) {
  .public-recipe-card {

  }
}

@media screen and (min-width:991px) {
  .public-recipe-card {
    height: 400px
  }
}

</style>
