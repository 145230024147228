<template>
    <b-navbar class="no-bottom-top-padding white-background nav-bar py-md-4 py-2" fixed="top" >
        <b-navbar-brand href="#">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <div class="logo-navbar-dark">
                <div>LEAN<br>LARDER</div>
              </div>
            </b-col>
          </b-row>
        </b-navbar-brand>
        <b-navbar-nav class="nav-max-width"> <!--class=""nav-fill-->
          <b-nav-item @click="navSelected('recipes')" class="mx-md-4">
            <i class="d-block d-md-none material-icons nav-icon" >menu_book</i>
            <span class="d-none d-md-block nav-link-bold">Recipes</span>
          </b-nav-item>
          <b-nav-item to="/list" class="mx-md-4">
            <div class="">
              <span class="d-none d-md-inline nav-link-bold">List</span>
              <i class="material-icons nav-icon d-inline d-md-none ">shopping_cart</i>
              <span class="nav-title nav-icon-text ml-2">{{plan && plan.shoppingList? `${plan.shoppingList.ingredients.length}` : ""}}</span>
            </div>

          </b-nav-item>
          <b-nav-item to="/plan" class="mx-md-4">
            <i class="material-icons nav-icon d-inline d-md-none">calendar_view_month</i>

            <span class="d-none d-md-inline nav-link-bold">Plan</span>
            <span class="nav-title nav-icon-text ml-2">{{plan && plan.recipes? `${plan.recipes.length}` : ""}}</span>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto"> <!--class=""nav-fill-->
          <b-nav-item-dropdown right style="z-index:1000">
            <template #button-content>
            <b-avatar variant="dark" size="sm"></b-avatar>
            <!-- <div v-if="user" class="nav-title nav-icon-text">{{username.length > 8? username.substring(0,7) + "..." : username}}</div> -->
          </template>
          <b-dropdown-item to="/account">Account</b-dropdown-item>
          <b-dropdown-item to="/leftovers">Manage Your Leftovers</b-dropdown-item>
          <b-dropdown-item @click="user.signOut()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

    </b-navbar>
</template>

<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import User from '../models/User'
Vue.use(BootstrapVue)

export default {
  name: 'App',
  props: ['plan'],
  components: {
  },
  data() {
    return {
      activePage: 'home',
      username: "",
      logoutURI: process.env.VUE_APP_AUTH_LOGOUT_URI,
      user: undefined
    }
  },
  methods: {
    navSelected(type) {
      if (type === 'recipes') {
        if (this.$route.path !== '/recipes') this.$router.push('/recipes')

      }
      this.$emit('navSelected', type)
    },
    async getUser() {
      this.user = new User()
      await this.user.loadUser()
      this.username = this.user.username
    }

  },
  mounted() {
    this.getUser()
  },
  watch: {
  },
}

function parseJwt (token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}
</script>

<style>

.nav-fill {
  width:100%;
  margin-left: 20px;
  margin-right: 20px
}

.no-bottom-top-padding {
  padding-bottom: 0px;
  padding-top: 0px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-link-bold {
  font-weight: bold;
  color: #191919
}

.nav-bar {
   border-bottom: 1px solid #e8e8e8;
}

.logo-navbar-dark {
  height: fit-content;
  width: fit-content;
  border: 0px solid white;
  background-color: transparent;
  color: #343333;
  font-weight: 900;
  font-size: 13px ;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.2em;
}

</style>
