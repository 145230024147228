import moment from 'moment'
import {reserveStateIngredient} from '../clients/RecipeClient'
import StateLeftover from './StateLeftover'
import ShoppingList from './ShoppingList'
export default class UserState {
  constructor(userState) {
    if (userState) {
      Object.keys(userState).forEach(key => {
        this[key] = userState[key]
      })
    }

    if (!this.leftovers) this.leftovers = []
    this.leftovers = this.leftovers.map(leftover => new StateLeftover(leftover))
  }
  getPotentialStateItemsToUseForRecipes(recipes, plan) {
    var usableLeftovers = itemsFromStateThatCanBeUsed(this, recipes, plan)
    return usableLeftovers.map(leftover => {
      leftover.createdText = moment(leftover.created).fromNow()
      return leftover
    })
  }

  getReservationsForPlan(plan) {
    const leftoversWithReservationsForThisPlan = this.leftovers.filter(leftover => leftover.isReservedByPlan(plan.id))
    let reservationsForPlanWithLeftoverDetails = []
    leftoversWithReservationsForThisPlan.forEach(leftover => {
      reservationsForPlanWithLeftoverDetails = reservationsForPlanWithLeftoverDetails.concat(leftover.getFullReservationsForPlan(plan.id))
    })
    return reservationsForPlanWithLeftoverDetails
  }
  matchingLeftoverForPlan(ingredient, planId) {
    return this.leftovers.filter(leftover => leftover.ingredient.id === ingredient.id && leftover.planId === planId && leftover.unit === ingredient.unit)[0]
  }
  leftoversForPlan(planId) {
    return this.leftovers.filter(leftover => leftover.planId === planId)
  }
  leftoversFromOtherPlans(planId) {
    var leftovers = []
    return this.leftovers.filter(leftover => {
      return !leftoverWasFromPlan(leftover,planId) && !leftoverIsAlreadyReservedByPlan(leftover, planId) && !leftover.isFullyReserved()
    })
  }
  hasStoreItem(ingredientId) {
    return this.storeItems.find(item => item.id == ingredientId) !== undefined
  }
  async removeNotNeededReservationsForPlan(plan) {
    let reservations = this.getReservationsForPlan(plan)
    for (let reservation of reservations) {
      let itemInList = plan.shoppingList.ingredients.filter(obj => obj.id === reservation.ingredientId && obj.unit === reservation.unit)[0]
      if (itemInList && itemInList.quantity === reservation.quantity) return
      let quantityToRemove = reservation.quantity

      if (itemInList) quantityToRemove = reservation.quantity - itemInList.quantity

      await reserveStateIngredient({
        remove: true,
        ingredientId: reservation.ingredientId,
        planId: reservation.originalPlanId,
        reservedByPlanId: plan.id,
        quantity: quantityToRemove,
        unit: reservation.unit
      })
    }
  }
}

function itemsFromStateThatCanBeUsed(state, recipes, plan) {
  const recipeLeftovers = leftoversForRecipes(recipes)
  return state.leftovers.filter(stateLeftover => {
    const matchingLeftoverInRecipes = recipeLeftovers.find(leftover => leftover.ingredient.id === stateLeftover.ingredient.id)
    if (!matchingLeftoverInRecipes) return
    return stateLeftover.planId !== plan.id && stateLeftover.hasEnoughForIngredient(matchingLeftoverInRecipes.ingredient)
  })
}

function leftoversForRecipes(recipes) {
  let tempShoppingList = new ShoppingList()
  tempShoppingList.createList(recipes)
  const leftoversForRecipes = tempShoppingList.getLeftovers()
  return leftoversForRecipes
}

function leftoverIsAlreadyReservedByPlan(leftover, planId) {
  return leftover.reservations && leftover.reservations.filter(obj => obj.planId === planId).length > 0
}

function leftoverWasFromPlan(leftover, planId) {
  return leftover.planId === planId
}