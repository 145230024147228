<template>
  <div class="mb-5">
    <div>
    <b-row align-h="between" align-v="center" class="mb-2" >
      <b-col cols="auto">
        <div class="inline-text text-left" >
        <h3 class="">Shopping List</h3>
        <b-dropdown variant="transparent" id="shopping-list-dropdown" class="p-0">
          <template #button-content class="p-0">
            <i class="material-icons no-hover text-top text-primary-mine p-0" >more_horiz</i>
          </template>
          <b-dropdown-item @click.stop="toggleHideBought">{{hideBought? 'Show Checked': 'Hide Checked'}}</b-dropdown-item>
        </b-dropdown>
      </div>
      </b-col>
          <b-col cols="auto" class="text-right">
          <b-button @click="addingItems = true" variant="primary" v-if="!addingItems" class="bold"><div><i class="material-icons icon-middle text-primary-mine " >add</i>Add Item</div></b-button>
        </b-col>
    </b-row>
    <p v-if="ingredients && ingredients.length === 0 && this.plan.shoppingList.getMainShoppingList().length === 0 && (!customShoppingListItems || customShoppingListItems.length > 0)" class="mb-4">You have nothing in this shopping list!</p>
    <div class="mb-2">
  <b-form v-if="addingItems" style="background-color:#00000055" class="p-3" @submit.prevent="addCustomItems">
    <label class="sr-only" for="inline-form-input-name">Name</label>
    <b-form-input
      placeholder="Enter item"
      v-model="customShoppingListItems"
    ></b-form-input>
    <b-button-group class="mt-2">
      <b-button style="width: 100px" variant="primary" @click="addCustomItems">Add</b-button>
      <b-button variant="negative" @click="addingItems = false"><i class="material-icons icon-middle " >close</i></b-button>
    </b-button-group>
  </b-form>
</div>
<b-row align-h="center">
  <b-col xl="8">
  <div v-if="customItems && customItems.length > 0" class="mb-4">
    <h4 class="capitalize mb-2" v-b-toggle.custom-items-collapse>Your Items <i class="material-icons icon-middle text-primary-mine when-closed" >expand_more</i></h4>
    <b-collapse id="custom-items-collapse" class="mt-2" :visible="customItems.filter(obj => !obj.checked).length > 0 || !ingredients || ingredients.length === 0">
      <b-list-group class="mb-2" >
        <ShoppingListItem
        v-for="(item, customItemIndex) of customItems"
        v-bind:key="customItemIndex"
        :item="item"
        noQuantites="true"
        showCheckbox="true"
        @checkboxChanged="customItemChecked(customItems)"
        />
      </b-list-group>
    </b-collapse>
  </div>
      <div v-for="(aisle, index) in ingredientsByAisle" v-bind:key="`aisle${index}`" id="main-shopping-list">
        <h4 class="capitalize mb-2">{{aisle.name}}</h4>
          <b-list-group class="mb-3">
            <b-list-group-item  v-for="(ingredient, index) of aisle.ingredients" v-bind:key="index"  :class="`no-side-borders px-0 pb-0 square ${ingredient.checked?'grey':''}`" >
              <b-row :id="`main-shopping-list-item-${ingredient.id}`" style="width: 100%" no-gutters class="pl-3 pr-1 pb-2 justify-content-between" >
                <b-col cols="6" sm="8">
                  <p :class="`align-start  capitalize ${ingredient.checked?'grey':'text-grey'}`">{{ingredient.name}}</p>
                </b-col>
                <b-col cols="6" sm="4">
                <div>
                  <b-row no-gutters align-v="center" style="flex-wrap: nowrap" align-h="end">
                    <b-col cols="auto" class="ml-1" v-if="ingredient.canBeStore">
                      <i v-b-tooltip.hover title="This item can be added to your store cupboard"  class="material-icons icon-middle mr-2" >add_home</i>
                    </b-col>
                    <b-col cols="auto">
                      <h3><b-badge v-b-tooltip.hover title="With standard supermarket packs you may have some leftover" v-if="ingredient.partial && !ingredient.canBeStore" :class="` mr-2 ${ingredient.checked?'strikethrough':''}`" variant="warning" pill>P</b-badge></h3>
                    </b-col>
                    <b-col cols="auto">
                      <h3><b-badge :class="`quantity-badge mr-2 ${ingredient.checked?'grey':''}`" variant="primary" pill>{{`${ingredient.quantity}${ingredient.unit === 'units'? '' : ` ${ingredient.unit}`}`}}</b-badge></h3>
                    </b-col>
                    <b-col cols="auto">
                      <b-form-checkbox :id="`main-shopping-list-checkbox-${ingredient.id}`" v-model="ingredient.checked" v-on:input="checkboxChanged(ingredient)" size="lg"></b-form-checkbox>
                    </b-col>
                    <b-dropdown class="mr-2" >
                      <template #button-content>
                        <i class="material-icons nav-icon align-bottom" >more_horiz</i>
                      </template>
                      <b-dropdown-item :disabled="ingredient.addToStore || ingredient.canBeStore || ingredient.store" @click.stop="addToStore(ingredient)" v-b-tooltip.hover title="If you don't normally need to buy this item we can add it to your store, we'll ask every time before adding to the shopping list. ">Add to your store<i  class="material-icons icon-middle icon-small ml-2" >info</i></b-dropdown-item>
                    </b-dropdown>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            </b-list-group-item>
          </b-list-group>
    </div>
      <div v-if="itemsReservedFromState && itemsReservedFromState.length > 0">
        <hr/>
        <b-row no-gutters align-v="center" align-h="center" class="mb-2">
          <b-col cols="12">
            <p class="bold">Leftovers You're Using Up<i v-b-tooltip.hover title="These are ingredients you've got leftover from previous plans you've said you'll use up - if that's changed just remove them from this list" class="material-icons icon-middle text-primary-mine ml-2">info</i></p>
          </b-col>
        </b-row>
          <b-list-group class="mb-3">
            <b-list-group class="mb-2">
              <ShoppingListItem
              v-for="(reservedItem, index) of itemsReservedFromState"
              v-bind:key="`${reservedItem.id}${index}`"
              :item="reservedItem"
              :showUnreserve="true"
              @unreserveItem="unreserveItem"
              />
            </b-list-group>

          </b-list-group>
            </div>

        <div v-if="storeIngredients && storeIngredients.length > 0">
          <hr/>
        <b-row  no-gutters align-v="center" align-h="center" v-b-toggle="'collapse-store'">
          <b-col cols="12">
            <p class="bold">Store Cupboard Items ({{storeIngredients.length}})<i v-b-tooltip.hover title="These are ingredients we think you may have in your store cupboard. If not - pick them up with the rest of your shopping!" class="material-icons icon-middle text-primary-mine ml-2">info</i></p>
            <b-button class="btn-transparent no-hover">
              <i class="material-icons text-primary-mine" v-if="!storeItemsCollapseVisible">add</i>
              <i class="material-icons  text-primary-mine" v-if="storeItemsCollapseVisible" >remove</i>
            </b-button>
          </b-col>
        </b-row>
        <b-collapse :id="`collapse-store`" v-model="storeItemsCollapseVisible">
          <b-list-group >
            <b-list-group-item v-for="(storeIngredient, index2) of storeIngredients" v-bind:key="index2" class="square d-flex justify-content-between align-items-center text-grey">
              <p class="capitalize">{{storeIngredient.name}} </p>
              <div>
                <b-row no-gutters align-v="center">
                <b-col cols="auto">
                  <h3><b-badge class="quantity-badge mr-2" variant="primary" pill>{{`${storeIngredient.quantity}${storeIngredient.unit === 'units'? '' : ` ${storeIngredient.unit}`}`}}</b-badge></h3>
                </b-col>
                <b-col cols="auto">
                  <i @click.stop="moveToList(storeIngredient)" class="material-icons nav-icon mr-2 clickable">arrow_upward</i>
                </b-col>
              </b-row>
              </div>

            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </div>
      <div v-if="leftovers && leftovers.length > 0">
        <hr/>
        <p class="mb-3">This is what you may have leftover at the end of your meals if you buy supermarket standard packs:</p>
        <b-list-group >
          <ShoppingListItem v-for="(leftover, index3) of leftovers" v-bind:key="index3" :item="leftover" :showFreezable="true"  :showLifeTooltip="true"/>
        </b-list-group>
      </div>
      <hr/>
      <b-row no-gutters style="" align-v="center" align-h="center" v-if="newStoreCupboardItems && newStoreCupboardItems.length > 0" v-b-toggle="'collapse-longlife'">
        <b-col cols="10">
          <p class="bold">Items you'll add to your store cupboard ({{newStoreCupboardItems.length}})</p>
          <b-button class="btn-transparent no-hover">
            <i class="material-icons text-primary-mine" v-if="!newStoreCupboardItemsCollapseVisible">add</i>
            <i class="material-icons  text-primary-mine" v-if="newStoreCupboardItemsCollapseVisible" >remove</i>
          </b-button>
        </b-col>
      </b-row>
      <b-collapse :id="`collapse-longlife`" v-model="newStoreCupboardItemsCollapseVisible">
        <div >
          <b-list-group >
            <ShoppingListItem v-for="(newStoreCupboardItem, index4) of newStoreCupboardItems" v-bind:key="index4" :item="newStoreCupboardItem" :hideStrikethrough="true"/>
          </b-list-group>
        </div>
      </b-collapse>
    </b-col>
    </b-row>
      <b-modal centered id="modal-list-done-popup" size="sm" hide-header hide-footer :static="true">
        <div class="p-2">
          <div class="px-2 pt-4 pb-2 text-grey">
            Congratulations, you have everything for your current plan!
            <br><br>
            We'll mark this plan as ready to cook and give you a new plan to start thinking about.
          </div>
          <b-button variant="primary" style="font-weight:bold" block @click="movePlanToCooking">
            <div v-if="!planChangeLoading">Yes Please</div>
            <b-spinner v-if="planChangeLoading" small label="Spinning" variant="light" ></b-spinner>
          </b-button>
          <b-button class="mb-2" block @click="closeModal">No thanks, I'll keep adding to my current plan</b-button>
        </div>
      </b-modal>
    </div>
    <SuggestionBanner v-if="suggestions" :suggestions="suggestions" @addRecipeToPlan="addRecipeToPlan" :plan="plan" :userState="userState" @recipeSelected="recipeSelected" />
    </div>

</template>

<script>
import ShoppingListItem from './ShoppingListItem'
import SuggestionBanner from './SuggestionBanner'
import {reserveStateIngredient, updateCustomShoppingListItems,getCustomShoppingListItems} from '../clients/RecipeClient'
export default {
  components: {
    ShoppingListItem,SuggestionBanner
  },
  props: ['plan', 'reloadCounter', 'morePlans', 'previousPlan', 'userState'],
  data() {
    return {
      ingredients: undefined,
      storeIngredients: undefined,
      leftovers: undefined,
      newStoreCupboardItems: undefined,
      ingredientsByAisle: undefined,
      customShoppingListItems: undefined,
      ingredientsToShow: undefined,
      hideBought : true,
      addingItems: false,
      planChangeLoading : false,
      newStoreCupboardItemsCollapseVisible: false,
      itemsReservedFromState: undefined,
      storeItemsCollapseVisible: false,
      customItems: [],
      customItemsVisible: this.plan.shoppingList.customItems.filter(obj => !obj.checked).length > 0,
      suggestions:undefined
    }
  },
  methods: {
    async moveToList(storeIngredient) {
      this.plan.shoppingList.moveStoreIngredientToList(storeIngredient)
      await this.plan.save()
      this.createLists()
    },
    ingredientIsInState(ingredient) {
      return this.userState.items.filter(obj => obj.id === ingredient.id).length > 0
    },
    async addRecipeToPlan(addRecipeEvent) {
      this.$emit('addRecipeToPlan', addRecipeEvent)
    },
    async loadSuggestions() {
      this.suggestions = undefined
      if (this.plan) {
        this.suggestions = await this.plan.getSuggestions(this.userState)

      }else {
        this.suggestions = []
      }
      if (!this.suggestions || this.suggestions.length === 0) this.showSuggestions = false
    },
    getStateText(ingredient) {
      var itemsInState =  this.userState.items.filter(obj => obj.id === ingredient.id)
      var totalQuantity = itemsInState.map(obj => obj.quantity).reduce((previous, current) => previous + current)
      return `You may have ${totalQuantity}${itemsInState[0].unit === 'units'?'' : ' ' + itemsInState[0].unit} left over from previous plans`
    },
    async createLists() {
      this.ingredients = this.plan.shoppingList.getMainShoppingList({hideBought: this.hideBought, userState: this.userState, plan: this.plan})
      this.storeIngredients = this.plan.shoppingList.getStoreList({ userState: this.userState, plan: this.plan})
      this.leftovers = this.plan.shoppingList.getLeftovers({ userState: this.userState, plan: this.plan})
      this.newStoreCupboardItems = this.plan.shoppingList.getNewStoreCupboardItems({ userState: this.userState, plan: this.plan})
      this.ingredientsByAisle = this.plan.shoppingList.getIngredientsByAisle({hideBought: this.hideBought, userState: this.userState, plan: this.plan})
      this.itemsReservedFromState = this.userState.getReservationsForPlan(this.plan)
      this.customItems = await getCustomShoppingListItems()
      this.loadSuggestions()
    },
    async customItemChecked(event) {
      await updateCustomShoppingListItems(this.customItems)
    },
    async checkboxChanged(item) {
      this.plan.shoppingList.checkOrUncheckItem(item, this.plan)
      if (!this.plan.shoppingList.hasUncheckedItems({plan: this.plan, userState: this.userState}) && this.plan.planStatuses.indexOf("COOKING") === -1) {
        this.$bvModal.show('modal-list-done-popup')
      }
      this.plan.save()
    },
    async addToStore(ingredient) {
      ingredient.addToStore = true
      ingredient.checked = true
    },
    async addCustomItems() {
      var itemsToAdd = this.customShoppingListItems.split(",").filter(item => item.length > 0)
      itemsToAdd = itemsToAdd.map(obj => {
        return {
          name: obj
        }
      })
      this.customItems = this.customItems.concat(itemsToAdd)
      await updateCustomShoppingListItems(this.customItems.filter(obj => !obj.checked))
      this.customShoppingListItems = undefined
    },
    async movePlanToCooking() {
      this.planChangeLoading = true
      await this.plan.setStatus("COOKING")
      this.$bvModal.hide('modal-list-done-popup')
      this.$emit("planChanged")
    },
    closeModal() {
      this.$bvModal.hide('modal-list-done-popup')
    },
    toggleHideBought() {
      this.hideBought = !this.hideBought
      this.createLists()
    },
    nextPlan(increment) {
      if ((increment === -1 && this.previousPlan) || (increment === 1 && this.morePlans)) {
        this.$emit("nextPlan", increment)
      }
    },
    recipeSelected(recipe) {
      this.$router.push({query: {activeRecipe: recipe.id}}).catch((error) => {});
      this.$emit('recipeSelected', recipe)
    },
    async unreserveItem(item) {
      await reserveStateIngredient({
        remove: true,
        planId: item.originalPlanId,
        reservedByPlanId: this.plan.id,
        ingredientId: item.ingredientId,
        quantity: item.quantity,
        unit: item.unit
      })
      this.$emit("planChanged")
    }
  },
  async mounted() {
    
    this.createLists()
  },
  watch: {
    reloadCounter() {
      this.createLists()
    }
  }
}

</script>

<style>

.strikethrough {
  text-decoration: line-through;
}

.align-start {
  text-align: start
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

#shopping-list-dropdown > .dropdown-toggle {
  padding: 0px;
  margin-left: 10px;
}

.no-side-borders {
  border-left: 0px !important;
  border-right: 0px !important;
}

</style>
