<template>
  <div>
    <NavBar  />
    <b-container class="nav-padding" >
      <b-row>
        <b-col class="text-center">
          <b-spinner variant="light"/>
        </b-col>
      </b-row>
    </b-container>
    </div>
</template>

<script>
import {} from '../clients/RecipeClient'
import NavBar from './NavBar'
import User from '../models/User'
import Plan from '../models/Plan'
import Recipe from '../models/Recipe'
export default {
  components: {
    NavBar
  },
  props: [],
  data() {
    return {
    }
  },
  methods: {

  },
  async mounted() {
    var user = new User()
    await user.loadUser()
    if (Object.keys(user).length > 0) {
      var sharedPlan = JSON.parse(atob(this.$route.query.content))
      var plan = new Plan()
      
      for (var recipe of sharedPlan.recipes) {
        recipe = new Recipe(recipe)
        await recipe.load()
        await plan.addRecipe(recipe)
      }
      this.$router.push({name: "PlanPage", query: {reload: true}})
    }else {
      localStorage.setItem('share_redirect', this.$route.query.content)
      this.$router.push({name: "Login"})
    }
  },
  watch: {
  }
}

</script>

<style>

</style>
