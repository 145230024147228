import {savePlan, getPartialSuggestions, updatePlan, getPlan} from '../clients/RecipeClient'
import ShoppingList from './ShoppingList'
import Recipe from './Recipe'
export default class Plan {
  constructor(plan) {
    createFromObject(this, plan)
    setDefaults(this)
  }
  async addRecipe(recipe, userState, options) {
    if (!options) options = {}
    if (recipeIsInList(recipe, this.recipes)) return
    this.recipes.push(recipe)
    this.shoppingList.createList(this.recipes, userState)
    if (options.doSwap) {
      const recipesToSwap = await this.getRecipeSwap(userState)
      if (recipesToSwap) await this.performSwap(recipesToSwap, userState)
      return
    }
    if (userState) await this.shoppingList.ensureStateLeftoversAreCorrect(this, userState)
    await this.save()
  }
  async performSwap(recipesToSwap, userState) {
    if (!recipesToSwap) return
    this.recipes = this.recipes.filter(obj => obj.id !== recipesToSwap.out.id)
    this.recipes.push(recipesToSwap.in)
    this.shoppingList.createList(this.recipes, userState)
    if (userState) await this.shoppingList.ensureStateLeftoversAreCorrect(this, userState)
    await this.save()
  }
  async updateServingsForRecipe(recipeToUpdate, userState) {
    this.recipes = this.recipes.filter(recipe => recipe.id !== recipeToUpdate.id)
    await this.addRecipe(recipeToUpdate, userState)
  }

  async getRecipeSwap(userState) {
    var suggestions = await this.getSuggestions(userState, 'swap')
    var swapSuggestion = suggestions.find(obj => obj.type === 'swap')
    if (!swapSuggestion) return

    const recipeToAdd = recipePresentInSwapAndNotInPlan(swapSuggestion, this.recipes)
    return {in: recipeToAdd, out: this.recipes.find(recipe => recipe.id === swapSuggestion.recipeToSwap)}
  }
  async addRecipes(recipes, userState) {
    for (var recipe of recipes) {
      if (!this.recipes.find(obj => obj.id === recipe.id)) this.recipes.push(recipe)
    }
    this.shoppingList.createList(this.recipes)
    if (userState) await this.shoppingList.ensureStateLeftoversAreCorrect(this, userState)
    await this.save()
  }
  async removeRecipe(recipe, userState) {
    this.recipes = this.recipes.filter(obj => obj.id !== recipe.id)
    this.shoppingList.createList(this.recipes)
    if (userState) await this.shoppingList.ensureStateLeftoversAreCorrect(this, userState)
    if (userState) await userState.removeNotNeededReservationsForPlan(this)
    await this.save()
  }

  async getSuggestions(userState, type) {
    const recipeIds = this.recipes.map(obj => obj.id)
    let partials = getPartialsForPlanIncludingUserLeftovers(userState, this)
    if (partials.length === 0) return []
    let partialSuggestions = await getPartialSuggestions(recipeIds, partials, type)
    partialSuggestions.sort((a,b) => a.score - b.score)
    return partialSuggestions
  }
  async save() {
    var objToSave = {
      shoppingList: this.shoppingList,
      recipes: this.recipes,
      planStatuses: this.planStatuses
    }
    if (this.id) objToSave.id = this.id
    if (this.created) objToSave.created = this.created

    const data = await savePlan(objToSave)
    this.created = data.createPlan.created
    this.updated = data.createPlan.updated
    this.id = data.createPlan.id
  }
  async archive() {
    await updatePlan({id: this.id, archived: true})
  }
  containsRecipe(recipe) {
    return this.recipes.find(obj => obj.id === recipe.id)
  }
  getStatus() {
    if (!this.planStatuses) return
    return this.planStatuses[0]
  }
  async setStatus(status) {
    this.planStatuses = [status]
    await this.save()
  }
  getLeftoverDifferenceWithSuggestion(suggestion, userState) {
    const leftoversForPlan = this.shoppingList.getLeftovers({userState: userState, plan: this})
    let usedUpLeftovers = leftoversForPlanReducedOrRemovedBySuggestion(leftoversForPlan, suggestion, this.id)
    let stateUsedUpLeftovers = leftoversFromStateReducedOrRemovedBySuggestion (userState, suggestion, this.id)
    usedUpLeftovers = usedUpLeftovers.concat(stateUsedUpLeftovers).filter(item => item)
    return {usedUpLeftovers: usedUpLeftovers, leftoversForPlan: leftoversForPlan}
  }
  isInShopping(userState) {
    return this.getStatus() === 'SHOPPING' || (this.getStatus() === 'COOKING' && this.shoppingList.hasUncheckedItems({userState: userState, plan: this}))
  }
  async load() {
    const planResponse = await getPlan(this.id)
    this.fullyLoaded = true
    createFromObject(this, planResponse)
  }
}

function recipeIsInList(recipe, list) {
  return list.find(obj => obj.id === recipe.id)
}

function getPartialsForPlanIncludingUserLeftovers(userState, plan) {
  let partials = getPartialsFromShoppingList(plan, userState)
  let userStatePartials = []
  if (userState) userStatePartials = getLeftoversFromUserState(userState, plan)
  return partials.concat(userStatePartials)
}


function getPartialsFromShoppingList(plan, userState) {
  return plan.shoppingList.getLeftovers({ userState: userState, plan: plan}).map(leftover => {
    var partial = new PartialIngredient()
    partial.fromShoppingListLeftover(leftover, plan)
    return partial

  })
}

function getLeftoversFromUserState(userState, plan) {
  return userState.leftoversFromOtherPlans(plan.id).map(leftover => {
    var partial = new PartialIngredient()
    partial.fromUserStateLeftover(leftover)
    return partial
  })
}

function recipePresentInSwapAndNotInPlan(swapSuggestion, currentRecipeList) {
  return swapSuggestion.recipes.find(recipe => {
    return currentRecipeList.map(obj => obj.id).indexOf(recipe.id) === -1
  })
}


function leftoversForPlanReducedOrRemovedBySuggestion(leftoversForPlan, suggestion, planId) {
  return leftoversForPlan.map(leftover => {
    var newLeftoversForIngredient = suggestion.partials.filter(partial => partial.ingredientId === leftover.id && partial.planId === planId)
    if (newLeftoversForIngredient.length === 0) return {
      quantity: leftover.quantity,
      id: leftover.id,
      originalQuantity: leftover.quantity,
      planId: planId,
      name: leftover.name,
      unit: leftover.unit
    }
    if (newLeftoversForIngredient[0].leftoverQuantity < leftover.quantity) return {
      id: leftover.id,
      originalQuantity: leftover.quantity,
      quantity: leftover.quantity - newLeftoversForIngredient[0].leftoverQuantity,
      planId: planId,
      usedPartial: true,
      name: leftover.name,
      unit: leftover.unit
    }
  }).filter(leftover => leftover)
}

function leftoversFromStateReducedOrRemovedBySuggestion(userState, suggestion, planId) {
  const leftoversFromState = userState.leftovers.filter(leftover => leftover.planId !== planId && !leftover.isReservedByPlan(planId) && !leftover.isFullyReserved())
  return leftoversFromState.map(leftover => {
    const newLeftoversForIngredient = suggestion.partials.find(partial => partial.ingredientId === leftover.ingredient.id && partial.planId === leftover.planId)
    if (!newLeftoversForIngredient) {
      leftover.id = leftover.ingredient.id

      return {
        id: leftover.ingredient.id,
        planId: leftover.planId,
        quantity: leftover.quantity,
        fromState: true,
        name: leftover.ingredient.name,
        unit: leftover.unit
      }
    }

    if (newLeftoversForIngredient.leftoverQuantity < leftover.quantity) {
      return {
        id: leftover.ingredient.id,
        planId: leftover.planId,
        fromState: true,
        originalQuantity: leftover.quantity,
        quantity: leftover.quantity - newLeftoversForIngredient.leftoverQuantity,
        usedPartial: true,
        name: leftover.ingredient.name,
        unit: leftover.unit
      }
    } 
  }).filter(leftover => leftover)
}

class PartialIngredient {
  constructor() {}
  fromShoppingListLeftover(leftover, plan) {
    this.ingredientId = leftover.id
    this.name = leftover.name
    this.leftoverQuantity = leftover.quantity
    this.unit = leftover.unit
    this.life = leftover.life
    this.planId = plan.id
  }
  fromUserStateLeftover(leftover) {
    this.ingredientId = leftover.ingredient.id
    this.name = leftover.ingredient.name
    this.leftoverQuantity = leftover.quantity
    this.unit = leftover.unit
    this.life = (leftover.action === 'froze'? 91 : leftover.ingredient.life) - Math.ceil((new Date() - new Date(leftover.created)) / 1000 / 60 / 60 / 24)
    this.planId = leftover.planId
  }
}

function createFromObject(plan, planInput) {
  if (planInput) {
    Object.keys(planInput).forEach(key => {
      if (key === 'shoppingList') {
        plan.shoppingList = new ShoppingList(planInput.shoppingList)
      }else {
        if (key === 'recipes') {
          plan.recipes = planInput.recipes.map(obj => new Recipe(obj))
        }else {
          plan[key] = planInput[key]
        }
      }
    })
  }
}

function setDefaults(plan) {
  if (!plan.recipes) plan.recipes = []
  if (!plan.shoppingList) plan.shoppingList = new ShoppingList()
  if (!plan.planStatuses) plan.planStatuses = ["SHOPPING"]
}
