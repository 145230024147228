<template>
  <div>
    <h2>Ingredients</h2>
    <b-form-input v-model="filter" placeholder="filter"></b-form-input>
    <b-table hover :items="ingredientsToShow" selectable select-mode="single" v-on:row-selected="rowSelected"></b-table>
  </div>
</template>

<script>

import {listIngredients} from '../clients/RecipeClient'
export default {
  props: ['ingredients'],
  data() {
    return {
      filter: undefined,
      ingredientsToShow: undefined
    }
  },
  methods: {
    rowSelected(row) {
      if (row[0]) this.$emit("ingredientSelected", this.ingredients.filter(obj => obj.id === row[0].id)[0])
    }
  },
  mounted() {
    this.ingredientsToShow = this.ingredients
  },
  watch: {
    filter() {
      this.ingredientsToShow = this.ingredients.filter(obj => {
        return obj.name.indexOf(this.filter) > -1
      })
    }
  }
}

</script>

<style>

</style>
