import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Admin from '@/components/Admin.vue'
import Auth from '@/components/Auth.vue'
import Login from '@/components/Login.vue'
import RecipePage from '@/components/RecipePage.vue'
import PlanView from '@/components/PlanView.vue'
import MethodPage from '@/components/MethodPage.vue'
import AccountPage from '@/components/AccountPage.vue'
import PolicyPage from '@/components/PolicyPage.vue'
import StatePage from '@/components/StatePage.vue'
import Share from '@/components/Share.vue'
import User from '../models/User'
Vue.use(VueRouter)

const router = new VueRouter({
mode: 'history',
routes: [
  { path: '/', name: 'Home', component: Home, meta: {
      title: 'Lean Larder - Home',
      metaTags: [
        {
          name: 'description',
          content: 'Lean Larder home page, the alternative to recipe boxes that helps you eat well, shop confidently and waste less'
        }
      ]
    }  },
  { path: '*', name: 'Default', component: Home, meta: {
      title: 'Lean Larder - Home',
      metaTags: [
        {
          name: 'description',
          content: 'Lean Larder home page, the alternative to recipe boxes that helps you eat well, shop confidently and waste less'
        }
      ]
    }   },
  { path: '/admin', name: 'Admin', component: Admin },
  { path: '/recipes', name: 'RecipePage', component: RecipePage },
  { path: '/landing', name: 'Landing', component: RecipePage },
  { path: '/list', name: 'ListPage', component: RecipePage },
  { path: '/plan', name: 'PlanPage', component: RecipePage },
  { path: '/auth', name: 'Auth', component: Auth },
  { path: '/login', name: 'Login', component: Home },
  { path: '/cook/:recipeId', name: 'MethodPage', component: MethodPage},
  { path: '/account', name: 'Account', component: AccountPage },
  { path: '/cookies', name: 'CookiePolicy', component: PolicyPage },
  { path: '/privacy', name: 'PrivacyPolicy', component: PolicyPage },
  { path: '/leftovers', name: 'StatePage', component: RecipePage },
  { path: '/share', name: 'Share', component: Share },
  { path: '/about/how-it-works', name: 'HowItWorks', component: Home, meta: {
      title: 'Lean Larder - How It Works',
      metaTags: [
        {
          name: 'description',
          content: 'How Lean Larder works to make shopping easy and reduce waste'
        }
      ]
    } },
  { path: '/about/why-is-food-waste-important', name: 'WhyIsFoodWasteImportant', component: Home, meta: {
      title: "Lean Larder - Why Food Waste Matters",
      metaTags: [
        {
          name: 'description',
          content: 'Lean Larder Hhow we can help combat the embarrassing amount of food waste'
        }
      ]
    }    },
  { path: '/about/recipes', name: 'PublicRecipes', component: Home, meta: {
      title: 'Lean Larder - Recipes',
      metaTags: [
        {
          name: 'description',
          content: 'Lean Larder sample recipes to inspire your weekly shop and give you recipe box style plans where you can choose the quality of the ingredients you buy'
        }
      ]
    }   }
] })


router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  if (to.name !== from.name) {window.scrollTo(0,0);}

  if (to.name === "Admin") {
    var user = new User()
    var isAdmin = await user.isAdmin()
    if (isAdmin) {
      next()
    }else {
      next('/landing')
    }
  }
  next()
})

export default router
