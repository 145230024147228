<template>
  <div>
    <b-container  >
      <h3 class="mt-3 mb-3">Your Leftovers</h3>
      <b-spinner v-if="pageLoading" label="Spinning" variant="light" ></b-spinner>
      <p v-if="!pageLoading && (!leftovers || leftovers.length === 0)">No Leftovers!</p>
      <b-list-group-item v-for="item of leftovers" v-bind:key="`${item.ingredient.id}${item.planId}`" style="padding-right:5px" :class="`square d-flex justify-content-between align-items-center ${item.checked?'grey':''}`">
        <b-container  class="p-0">
          <b-row align-v="center" no-gutters class="justify-content-between">
            <b-col cols="auto" sm="8">
              <b-row no-gutters>
                <b-col cols="auto">
                  <p :class="`align-start text-grey capitalize bold`">{{item.ingredient.name}}</p>
                </b-col>
                <b-col cols="auto" class="ml-2" v-if="item.action === 'bought'">
                  <i v-b-tooltip.hover title="In a current plan"  class="material-icons icon-middle mr-2" >pending</i>
                </b-col>
                <b-col cols="auto" class="d-block d-sm-none ml-2">
                  <b-row no-gutters align-v="center" align-h="end" >
                    <b-col v-if="item.quantity">
                      <h3 class="text-right"><b-badge :class="`quantity-badge`" variant="dark" pill>{{`${item.quantity}${item.unit === 'units'? '' : ` ${item.unit}`}`}}</b-badge></h3>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="auto" class="ml-2" v-if="item.action === 'froze'">
                  <i v-b-tooltip.hover title="This item is in the freezer" style="font-size: 14px; padding: 2px" class="material-icons icon-middle mr-2 freezable-icon" >ac_unit</i>
                </b-col>

              </b-row>
              <b-row no-gutters>
                <b-col cols="auto">
                  <p :class="`align-start text-grey  text-small capitalize`">from about {{dateText(item)}}</p>
                </b-col>
              </b-row>
              <b-row no-gutters class="mt-1" >
                <b-col cols="auto" >
                  <h4  class="text-right"><b-badge :variant="getLifeVariant(item)" pill>Remaining Life: approx {{remainingLifeText(item)}}</b-badge></h4>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="auto" sm="4">
              <div class="pr-1">
                <b-row align-h="end" no-gutters>
                  <b-col cols="auto" class="d-none d-sm-block">
                    <b-row no-gutters align-v="center" align-h="end" >
                      <b-col v-if="item.quantity">
                        <h3 class="text-right"><b-badge :class="`quantity-badge`" variant="dark" pill>{{`${item.quantity}${item.unit === 'units'? '' : ` ${item.unit}`}`}}</b-badge></h3>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="auto" class="mr-2">
                    <i class="material-icons clickable" @click="showDeleteModal(item)">delete</i>
                  </b-col>
              </b-row>
              </div>
            </b-col>
    </b-row>
  </b-container>
      </b-list-group-item>
      <div v-if="storeItems && storeItems.length > 0" class="mb-5">
        <h3 class="mt-5">Store Items</h3>
        <p class=" align-start mb-3" style="font-size: 14px">Don't worry too much about these, we'll ask you whether you need them every time they're in a recipe</p>
        <b-list-group-item v-for="storeItem of storeItems" v-bind:key="storeItem.id" style="padding-right:5px" :class="`square d-flex justify-content-between align-items-center}`">
          <b-container  class="p-0">
          <b-row no-gutters>
            <b-col cols="auto">
              <p :class="`align-start text-grey capitalize bold`">{{storeItem.name}}</p>
            </b-col>
          </b-row>
        </b-container>

        </b-list-group-item>
      </div>

    </b-container>
    <b-modal centered id="modal-update-leftover" size="md" hide-header >
      <div class="p-2">
        <div class="px-3 pt-4 pb-2">
          What did you do with this leftover?
        </div>
        <b-dropdown class="m-md-2 w-100 px-3 text-center" left text="dropdown" >
          <template #button-content>
            <b-container>
              <b-row align-h="between">
                <b-col cols="auto">
                  <p>{{selectedOption.text}}</p>
                </b-col>
                <b-col cols="auto">
                  <i class="material-icons no-hover icon-middle" >expand_more</i>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <b-dropdown-item v-for="option of options" v-bind:key="option.name" @click="selectOption(option)">{{option.text}}</b-dropdown-item>
        </b-dropdown>

      </div>
      <div slot="modal-footer">
        <b-button-group>
            <b-button class="mb-2 full-button" squared @click="closeModal">Cancel</b-button>
            <b-button class="full-button" squared variant="primary" style="font-weight:bold" @click="updateItem">
              <p v-if="!loading" >Update</p>
              <b-spinner v-if="loading" small label="Spinning" variant="light" ></b-spinner>
            </b-button>
        </b-button-group>
      </div>
    </b-modal>
    </div>
</template>

<script>
import {getUserState,updateStateItem } from '../clients/RecipeClient'
import NavBar from './NavBar'
import moment from 'moment'
export default {
  components: {
  },
  props: [],
  data() {
    return {
      leftovers: undefined,
      storeItems: undefined,
      stateDate: undefined,
      activeItem: undefined,
      pageLoading: true,
      loading: false,
      options: [
        { text: "Binned It", name: 'binned', icon: 'delete'},
        { text: "Used It Elsewhere", name: 'used', icon: 'check_circle', clicked: 'false'},
        { text: "Bought the right amount", name: 'right_amount', icon: 'shopping_cart'},
        { text: "Froze It", name: 'froze', icon: 'ac_unit'},
        { text: "Other", name: 'other', icon: 'circle'}
      ],
      selectedOption: { text: "Binned It", name: 'binned', icon: 'delete'}
    }
  },
  methods: {
    async loadUserState(hideLoading) {
      if (!hideLoading) this.pageLoading = true
      var response = await getUserState("StatePage")
      if (response.leftovers) {
        this.leftovers = response.leftovers.sort((a,b) => this.calculateRemainingLife(a) - this.calculateRemainingLife(b))
      }

      if (response.storeItems) {
        this.storeItems = response.storeItems.sort((a,b) => a.name > b.name? 1:-1)
      }
      this.stateDate = response.date
      this.pageLoading = false
    },
    calculateRemainingLife(item) {
      if (item.action === 'froze') item.ingredient.life = 91
      var life = item.ingredient.life - Math.ceil((new Date() - new Date(item.bought || item.created)) / 1000 / 60 / 60 / 24)
      return life
    },
    remainingLifeText(item) {
      var life = this.calculateRemainingLife(item)
      if (life < 1) return 'Possibly out of date'
      return `${life} days`
    },
    getLifeVariant(item) {
      var life = this.calculateRemainingLife(item)
      if (life <2) return 'danger'
      if (life <= 7) return 'warning'
      return 'primary'
    },
    dateText(item) {
      return moment(item.bought || item.created).fromNow()
    },
    closeModal() {
      this.$bvModal.hide('modal-update-leftover')
    },
    showDeleteModal(item) {
      this.activeItem = item
      this.$bvModal.show('modal-update-leftover')
    },
    selectOption(option) {
      this.selectedOption = option
    },
    async updateItem() {
      this.loading = true
      this.leftovers = this.leftovers.filter(obj => obj.ingredient.id !== this.activeItem.ingredient.id && obj.planId !== this.activeItem.planId)
      await updateStateItem({planId: this.activeItem.planId, ingredient: {id: this.activeItem.ingredient.id}, unit: this.activeItem.unit, action: this.selectedOption.name})
      this.loading = false
      this.$bvModal.hide('modal-update-leftover')
      setTimeout(() => this.loadUserState(true), 1000)
    }
  },
  mounted() {
    this.loadUserState()
  },
  activated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  watch: {
  }
}

</script>

<style>

</style>
