
<template>
  <div>
    <div class="cookie-banner p-3" id="cookie-banner">
    <b-container class="cookie-banner-content" >
      <b-row>
        <b-col cols="12" lg="9" class="mb-2">
          <p class="text-grey text-left">We use analytics cookies to learn about how people use our product to make it better for everyone. We do not use them to identify individuals. We do understand, though, that some people are uncomfortable with the use of cookies so if you would like to disable them, you can do so here. We have made every effort to make sure LeanLarder works just as well without them. You can see our full cookie policy <a href="/cookies">here</a>.</p>
        </b-col>
        <b-col cols="12" lg="3">
          <b-button-group class="align-bottom">
            <b-button id="cookie-reject-button" class="btn-1" @click="updateCookies(false)" squared>Disable Cookies</b-button>
            <b-button id="cookie-accept-button" class="recipe-button-add" @click="updateCookies(true)" squared>Accept Cookies</b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
    </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    updateCookies(enabled) {
      this.$emit('updateCookies', enabled)
    }
  },
  mounted() {
  },
  watch: {
  }
}

</script>

<style>

.cookie-banner {
  background-color: #000000AA;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1500;
}

.cookie-banner-content {
  background-color: white;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1500;
  padding: 30px;
}
</style>
