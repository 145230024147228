<template>
  <div>
    <b-spinner v-if="!items" label="Spinning" variant="light" class="mt-2"></b-spinner>
    <b-table v-if="items" :items="items"  :fields="['name', 'unhidden', 'hidden', 'count']" responsive> </b-table>
  </div>
</template>

<script>

import {getPartialStats} from '../clients/RecipeClient'
export default {
  props: [''],
  data() {
      return {
        items: undefined
      }
    },
    methods: {
      async load() {
        var stats = await getPartialStats()
        this.items = stats.map(obj => {
          obj.unhidden = obj.count - obj.hidden
          return obj
        })
      }
    },
    mounted() {
      this.load()
    },
    watch: {
    }
}

</script>

<style>

</style>
