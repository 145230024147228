
<template>
  <div>
    <div class="suggestion-banner pt-2 clickable" @click="cancelled=false" v-if="cancelled && suggestions.length > 0" >
      <p>Show Suggestions<i class="material-icons icon-middle text-primary-mine clickable"  >expand_less</i></p>
    </div>
    <div class="suggestion-banner pt-2" v-if="!cancelled && suggestions.length > 0" style="height:auto; font-size:14px; ">
    <b-container v-if="recipe"  class="p-3" style="max-width: 500px">
      <b-row >
        <b-col cols="10" class="pl-4" align-h="start">
          <h5 class=" text-primary-mine " style="word-wrap: normal">Here's a suggestion...</h5>
          <b-row no-gutters>
            <b-col cols="auto">
              <p class=" text-primary-mine text-left" style="word-wrap: normal">Uses up {{this.leftovers.length === 0? 'all' : this.usedLeftovers.length}} of your leftovers</p>
            </b-col>
            <b-col cols="auto" class="ml-2">
            <p class="text-small clickable" v-if="showDetail===false" @click="showDetail =true"><u>more</u></p>
            <p class="text-small clickable" v-if="showDetail===true" @click="showDetail =false"><u>less</u></p>
            </b-col>
          </b-row >
        </b-col>
        <b-col cols="2" align-h="end">
          <i class="material-icons icon-middle text-primary-mine clickable" @click="cancelled=true" >close</i>
        </b-col>
      </b-row>
      <b-row no-gutters align-h="center" align-v="stretch" @click="recipeSelected">
        <b-col :cols="recipe.imageUrl?4 : 1"  >
          <b-img v-if="recipe.imageUrl" id="recipe-image" :src="recipe.imageUrl" alt="Image" class="rounded-0 my-2" style="height: 80px; width:80px"></b-img>
        </b-col>
        <b-col :cols="recipe.imageUrl? 8: 11" align-self="stretch" >
          <b-row no-gutters >
            <b-col cols="12">
              <h5 class="responsive-h5 mr-4 pt-4 text-primary-mine "  style="word-wrap: normal; height:50px">{{recipe.title}}</h5>
            </b-col>
          </b-row>
          <b-row style="max-width:500px" align-v="end" align-h="end">
            <b-col cols="auto" align-self="end">
            <b-button-group class="mt-3 mr-2" style="font-size: 14px !important">
              <b-button class="btn-1" size="sm"  :disabled='suggestionIndex === 0' squared @click.stop="previousRecipe" >Previous</b-button>
              <b-button class="btn-1" size="sm"  squared @click.stop="nextRecipe" :disabled='suggestionIndex === suggestions.length-1'>Next</b-button>
              <b-button class="recipe-button-add" size="sm" @click.stop="addRecipeToPlan" squared>
                <p v-if="!loading" >Add to plan</p>
                <b-spinner v-if="loading" small label="Spinning" variant="light" class="mt-2"></b-spinner>
              </b-button>
            </b-button-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-collapse id="leftovers-detail" v-model="showDetail" >
        <hr/>
      <b-row >
        <b-col cols="12" class='p-2'>
          <p class="mb-1 text-left">You'll have used up these leftovers:</p>
          <div class="scrollable-list">
            <ShoppingListItem v-for="(leftover, index) of usedLeftovers" :item="leftover" v-bind:key="index" />
          </div>
          <div class="mb-2" >
             <p v-if="leftovers && leftovers.length > 0" class="mt-3 mb-2 text-left">Remaining leftovers (<strong>{{leftovers.length}}</strong>)</p>
             <div class="scrollable-list">
               <ShoppingListItem v-for="leftover of leftovers" :item="leftover" v-bind:key="`${leftover.ingredientId}_${leftover.planId}`" :showLifeTooltip="true"/>
             </div>
          </div>
        </b-col>
      </b-row>
      <b-row @click="showDetail = false">
        <b-col cols="12">
          <i class="material-icons icon-middle text-primary-mine clickable"  >expand_more</i>
        </b-col>
      </b-row>
    </b-collapse>
    </b-container>

    </div>
    </div>
</template>

<script>
import ShoppingListItem from './ShoppingListItem'
export default {
  components: {
    ShoppingListItem
  },
  props: ["suggestions", "reloadCounter", "plan", "userState"],
  data() {
    return {
      recipe: undefined,
      cancelled: true,
      showDetail: false,
      loading: false,
      newRecipe: undefined,
      suggestionIndex: 0,
      usedLeftovers: undefined,
      originalLeftovers: undefined,
      leftovers: undefined
    }
  },
  methods: {
    recipeSelected(){
      this.$router.push({query: {activeRecipe: this.recipe.id}}).catch((error) => {});
      this.$emit('recipeSelected', this.recipe)
    },
    nextRecipe() {
      this.recipe = undefined
      this.suggestionIndex++
      this.recipe = this.suggestions[this.suggestionIndex].recipes[0]
      this.loadLeftovers()
    },
    previousRecipe() {
      this.recipe = undefined
      this.suggestionIndex--
      this.recipe = this.suggestions[this.suggestionIndex].recipes[0]
      this.loadLeftovers()
    },
    loadLeftovers() {
      let suggestion = this.suggestions[this.suggestionIndex]
      let leftoverDifference = this.plan.getLeftoverDifferenceWithSuggestion(suggestion, this.userState)
      this.usedLeftovers = leftoverDifference.usedUpLeftovers
      this.originalLeftovers = leftoverDifference.leftoversForPlan
      this.leftovers = suggestion.partials.map(obj => {
        obj.quantity = obj.leftoverQuantity
        if (this.userState && this.userState.leftovers) {
          var matchingStateItem = this.userState.leftovers.filter(stateItem => stateItem.ingredient.id === obj.ingredientId && stateItem.remainingLife === obj.life)[0]
          if (matchingStateItem && matchingStateItem.action === 'froze') obj.frozen = true
        }
        obj.fromState = obj.planId && obj.planId !== this.plan.id
        return obj
      }).filter(obj => !obj.life || obj.life < 91).filter(obj => stateItemIsNotForThisPlan(obj, this.plan, this.userState)).sort((a,b) => a.fromState? 1: -1)
    },
    load() {
      var suggestion = this.suggestions[0]
      if (suggestion) {
        this.recipe = suggestion.recipes[0]
        this.loadLeftovers()

      }
    },
    async addRecipeToPlan() {
      this.loading = true
      this.$emit('addRecipeToPlan', {recipe: this.recipe})
    }
  },
  mounted() {
    this.load()

  },
  watch: {
  }
}

function stateItemIsNotForThisPlan(item, plan, userState) {
  if (!item.fromState) return true
  return userState.leftovers.filter(leftover => leftover.id === item.ingredientId && leftover.planId === plan.id).length === 0
}

</script>

<style>

.suggestion-banner {
  background-color: var(--tertiary);
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 800;
}

.fixed-bottom-right {
  position: absolute;
  bottom: 5px;
  right:5px;
}

.scrollable-list {
  max-height: 130px;
  overflow: scroll;
  overscroll-behavior: contain;
}

</style>
