<template>
  <div>
    <NavBar :plan="plan" />
    <b-container class="nav-padding" >
      <b-spinner v-if="!plan || !recipes" label="Spinning" id="test"></b-spinner>
      <div v-if="recipes && plan && ($route.name ==='RecipePage' || $route.name === 'Home' || $route.name === 'ListPage')" class="mb-2">
        <b-row id="current-plan-section" class="mb-2 clickable" @click="showPlanChooser()" align-h="center">
          <b-col  cols="12">
            <div class="inline-text text-left" >
            <h3 class="mr-3">Your Current Plan</h3>
            <p class="text-small">change</p>
          </div>
          </b-col>
        </b-row>
        <b-row  align-h="center" class="mb-5" v-if="recipes && plan && ($route.name ==='RecipePage' || $route.name === 'Home' || $route.name === 'ListPage' )">
          <b-col cols="12">
            <SmallRecipeCards :plan="plan" :hideTime="true" @recipeSelected="setActiveRecipe" :reloadCounter="reloadCounter" />
          </b-col>
        </b-row>
      </div>
      <b-row align-h="center" v-if="plan && $route.name ==='StatePage'">
        <b-col xl="12" sm="12">
          <StatePage />
        </b-col>
      </b-row>
    <b-row align-h="center" v-if="recipes && plan && userState && ($route.name ==='RecipePage' || $route.name === 'Home')">
        <b-col xl="6" sm="12">
          <RecipeList
          v-if="plans"
          :recipes="recipes"
          :plan="plan"
          :plans="plans" 
          :userState="userState"
          v-on:recipeSelected="setActiveRecipe"
          :reloadCounter="reloadCounter"
          @addRecipeToPlan="addRecipeToPlan"
          @removeRecipeFromPlan="removeRecipeFromPlan"
          @showAllRecipes="showAllRecipes"
          />
        </b-col>
        <b-col  xl="6" class="d-none d-xl-block">
          <ShoppingListView 
          :plans="plans"
          :reloadCounter="reloadCounter"
          :activePlan="plan"
          :userState="userState"
          @addRecipeToPlan="addRecipeToPlan"
          @recipeSelected="setActiveRecipe"
          @planChanged="setActivePlanFromUserPlanList" />
          
        </b-col>
    </b-row>
      <b-row align-h="center" v-if="plan && userState && plan.shoppingList && $route.name ==='ListPage'">
        <b-col xl="12" sm="12">
          <ShoppingListView 
          :plans="plans"
          :reloadCounter="reloadCounter"
          :activePlan="plan"
          :userState="userState"
          @addRecipeToPlan="addRecipeToPlan"
          @recipeSelected="setActiveRecipe"
          @planChanged="setActivePlanFromUserPlanList" />
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="plan && plan.shoppingList && $route.name ==='PlanPage'">
        <b-col xl="8" sm="12" >
          <PlanList 
            style="min-height: 100%" 
            :userState="userState"
            :reloadCounter="reloadCounter"
            :plans="plans"
            @addRecipeToPlan="addRecipeToPlan"
            @removeRecipeFromPlan="removeRecipeFromPlan"
            @archivePlan="archivePlan"
            @planStatusUpdated="setActivePlanFromUserPlanList"
            @setActivePlan="setActivePlan"
            @recipeSelected="setActiveRecipe"
            @createPlan = "createPlan"
          />
        </b-col>
      </b-row>
        <RecipeView
        id="recipe-view"
        v-if="activeRecipe"
        :recipe="activeRecipe"
        :userState = "userState"
        :plan="planForRecipeViewModal || plan"
        @addRecipeToPlan="addRecipeToPlan"
        @removeRecipeFromPlan="removeRecipeFromPlan"
        @showPlanChooser = "showPlanChooser"
        @updateServings = "updateServings"
        />
    </b-container>
    <AddRecipeModal  
      :recipe="recipeToBeAdded" 
      :reloadCounter="recipeAddedCount" 
      :userState="userState" 
      :plan="plan" 
      :openLoading="addRecipeLoading"
      :swap="swap"
      @stateUpdated="updateState(true)"
      @undoSwap="undoSwap"
      @redoSwap="redoSwap"
      @modalClosed="storeItemsModalClosed"/>
    <PlanChooserModal 
      :recipe="recipeToBeAdded"
      :activePlan="plan"
      :plans="plans"
      :reloadCounter="reloadCounter"
      @addRecipeToPlan="addRecipeToPlan" 
      @changeActivePlan="setActivePlan"
      @createPlan="createPlan"
    />
    <GuideModal />
  </div>
</template>

<script>
import RecipeList from './RecipeList'
import ShoppingListView from './ShoppingListView'
import PlanList from './PlanList'
import RecipeView from './RecipeView'
import GuideModal from './GuideModal'
import NavBar from './NavBar'
import Plan from '../models/Plan'
import PlanChooserModal from './PlanChooserModal'
import AddRecipeModal from './AddRecipeModal'
import SmallRecipeCards from './SmallRecipeCards'
import StatePage from './StatePage'
import User from '../models/User'
import {listWeeklyRecipes, loadPlans, getFullRecipe, getUserState, listRecipes} from '../clients/RecipeClient'
export default {
  name: 'RecipePage',
  components: {
    RecipeList, NavBar, StatePage, ShoppingListView, PlanList, RecipeView, AddRecipeModal, PlanChooserModal, SmallRecipeCards, GuideModal
  },
  data() {
    return {
      recipes: undefined,
      allRecipes: undefined,
      plan: undefined,
      plans: undefined,
      mode: 'recipe',
      reloadCounter: 0,
      recipeAddedCount:0,
      activeRecipe: undefined,
      addingRecipe: undefined,
      planView: false,
      planSynced: false,
      recipeToBeAdded: undefined,
      planForRecipeViewModal: undefined,
      recipesViewOverride: 0,
      userState: undefined,
      user: undefined,
      userLoaded: false,
      addRecipeLoading: false,
      swap: undefined
    }
  },
  methods: {
    async listRecipes() {
      this.recipes = await listWeeklyRecipes()
      this.recipes = this.recipes.sort((a,b) =>  new Date(b.created) - new Date(a.created))
      window.scrollTo(0,0);
    },
    async showAllRecipes() {
      this.recipes = undefined
      this.recipes = await listRecipes()
      this.recipes = this.recipes.sort((a,b) =>  new Date(b.created) - new Date(a.created))
      window.scrollTo(0,0);
    },
    async addRecipeToPlan(recipePlanEvent) {
      this.swap = undefined
      this.addRecipeLoading = true
      this.recipeToBeAdded = recipePlanEvent.recipe
      let planToAddTo = this.plan
      if (recipePlanEvent.plan) planToAddTo = this.plans.find(plan => plan.id === recipePlanEvent.plan.id)
      this.$bvModal.show('add-recipe-modal')
      await planToAddTo.addRecipe(this.recipeToBeAdded, this.userState)
      this.swap = await planToAddTo.getRecipeSwap(this.userState)
      await planToAddTo.performSwap(this.swap, this.userState)
      if (this.swap) this.recipeToBeAdded = this.swap.in
      await this.addAnyPlannedRecipesToList()
      this.reloadCounter++
      this.recipeAddedCount++
      this.addRecipeLoading = false
    },
    async undoSwap(plan) {
      this.addRecipeLoading = true
      await plan.removeRecipe(this.swap.in, this.userState)
      await plan.addRecipe(this.swap.out, this.userState)
      this.recipeToBeAdded = this.swap.out
      await this.addAnyPlannedRecipesToList()
      this.swap.undone = true
      this.reloadCounter++
      this.recipeAddedCount++
      this.addRecipeLoading = false
    },

    async redoSwap(plan) {
      this.addRecipeToPlan({plan, recipe: this.swap.out})
   
    },
    async removeRecipeFromPlan(removeRecipeEvent) {
      this.reloadCounter++
      let planToRemoveFrom = this.plan
      if (removeRecipeEvent.plan) planToRemoveFrom = this.plans.filter(plan => plan.id === removeRecipeEvent.plan.id)[0]
      await planToRemoveFrom.removeRecipe(removeRecipeEvent.recipe, this.userState)
      this.reloadCounter++
    },
    async createPlan(options) {
      const plan = new Plan()
      await plan.save()
      this.plans.push(plan)
      this.reloadCounter++
      if (!options) return
      if (options.recipe) await this.addRecipeToPlan({recipe: options.recipe, plan: plan})
      if (options.makeActive) this.setActivePlan(plan)
    },
    //TODO tests
    async updateServings(recipePlanEvent) {
      this.recipeToBeAdded = undefined
      let planToAddTo = this.plan
      if (recipePlanEvent.plan) planToAddTo = this.plans.filter(plan => plan.id === recipePlanEvent.plan.id)[0]
      await planToAddTo.updateServingsForRecipe(recipePlanEvent.recipe, this.userState)
      this.reloadCounter++
    },
    async archivePlan(plan) {
      await plan.archive()
      await this.loadPlans()
    },
   
    async showPlanChooser(recipe) {
      this.recipeToBeAdded = recipe
      this.$bvModal.show("plan-chooser-modal")
      
    },
    async setActivePlanFromUserPlanList() {
      this.plan = this.getActiveShoppingPlan(this.userState, true)
      if (!this.plan) {
        this.plan = new Plan()
        await this.plan.save()
        this.plans.push(this.plan)
      }
      this.reloadCounter++
    },
    async loadPlans() {
      const plans = await loadPlans()
      this.plans = plans
      this.setActivePlanFromUserPlanList()
      //Start of untested
      if (this.$route.name === "Landing") {
        try {
          var shareRedirect = localStorage.getItem('share_redirect')
          localStorage.removeItem('share_redirect')
          if (shareRedirect)  {
            this.$router.push({name: "Share", query: {content: shareRedirect}})
          }else {
            if (this.plan.recipes && this.plan.recipes.length > 0) {
              this.$router.push("/plan")
            }else {
              this.$router.push("/recipes")
            }
          }
        }catch(err) {
          if (this.plan.recipes && this.plan.recipes.length > 0) {
            this.$router.push("/plan")
          }else {
            this.$router.push("/recipes")
          }
        }


      }
      //End of untested

      await this.addAnyPlannedRecipesToList()

    },
    setActiveRecipe(options) {
      this.activeRecipe = options.recipe
      if (options.plan) this.planForRecipeViewModal = options.plan
      try {
        if (!this.activeRecipe) {
          this.$router.push({query: {}})
        }else {
          this.$router.push({query: {activeRecipe: this.activeRecipe.id}})
        }
      }catch (err) {
      } 
    },
    async addAnyPlannedRecipesToList() {
      if (!this.plan) return
      for (let plannedRecipe of this.plan.recipes) {
        if (this.recipes.filter(recipe => recipe.id === plannedRecipe.id).length === 0) {
          const loadedRecipe = await getFullRecipe(plannedRecipe.id)
          this.recipes.push(loadedRecipe)
          this.recipes = this.recipes.filter(recipe => !plannedRecipe.siblingIds || plannedRecipe.siblingIds.indexOf(recipe.id) === -1)
        }
      }

      this.recipes = this.recipes.sort((a,b) =>  new Date(b.created) - new Date(a.created))
    },
    getActiveShoppingPlan() {
      var plansInShopping = this.plans.filter(plan => plan.isInShopping(this.userState))
      return plansInShopping[0]
    },
    async setActivePlan(plan) {
      this.plan = plan
      this.reloadCounter++
    },
    
    async updateState(skipReload) {
      this.userState = await getUserState()
      if (!skipReload) this.reloadCounter++
    },
    async loadUser() {
      
      this.userLoaded = false
      this.user = new User()
      await this.user.loadAdditionalUserData()
      this.userLoaded = true
      if (!this.user.guideSeen) {
        this.$bvModal.show('guide-modal')
      }

    },
    async loadInitialPageData() {
      await this.loadUser()
      await this.listRecipes()
      await this.updateState()
      await this.loadPlans()
      
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === "recipe-modal") {
          this.planForRecipeViewModal = undefined
          this.setActiveRecipe({recipe: undefined})
        }
        if (modalId === "guide-modal") {
          this.user.setGuideSeen()
        }
       
      })
    },
    async storeItemsModalClosed() {
      this.$bvModal.hide('add-recipe-modal')
      this.userState = await getUserState()
      if(this.userState && this.plan.recipes && this.plan.recipes.length > 0 && this.userState.getPotentialStateItemsToUseForRecipes([this.recipeToBeAdded], this.plan).length > 0) {
        this.$bvModal.show("use-state-items-modal")
      }
    }
  },
  async mounted() {
    await this.loadInitialPageData()
  },
  async activated() {
    
    /*
    if (this.$route.query.reload) { //Set when sharing a plan
      await this.loadInitialPageData()
    }
    */
  },
  watch: {
    $route() {
      //Untested
      if (!this.$route.query.activeRecipe) {
        this.activeRecipe = undefined
        this.$bvModal.hide('ingredients-modal')
      }
      if (this.$route.name === "Landing") {
        if (this.plan && this.plan.recipes && this.plan.recipes.length > 0) {
          this.$router.push("/plan")
        }else {
          this.$router.push("/recipes")
        }
      }

      if (this.$route === 'recipes' || this.$route === 'list') this.updateState()
      //End of untested section
    },
    async plan() {
     // if (!this.plan.fullyLoaded && this.plan.recipes.length > 0) await this.plan.load()
    }
  }
}

</script>

<style>

</style>
