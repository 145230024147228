<template>
  <div>
    <h3 v-if="getPlanStatus() !== 'cooked'" class="capitalize mb-3">{{getPlanStatus()}}</h3>
      <b-collapse :id="`collapse-${plan.id}`" v-model="visible">
        <b-row v-if="plan.recipes.length > 0 && plan.isInShopping()" class="justify-content-center mb-2">
          <b-col cols="auto">
            <b-row class="justify-content-center" align-v="center" align-h="center">
              <b-col cols="auto" v-if="(suggestions && suggestions.length > 0) || suggestionLoading">
                <b-spinner v-if="suggestionLoading" small label="Spinning" variant="secondary" class="mt-2"></b-spinner>
                <div v-if="suggestions">
                  <b-button v-if="suggestions && suggestions.length > 0 && !hideSuggestions" class="btn-primary" v-b-modal.suggestions-modal @click="showSuggestions = !showSuggestions" >
                    <p class="text-primary-mine">{{showSuggestions? 'Hide Suggestions' : `See suggestions to use up leftovers`}}</p>
                  </b-button>
                </div>
              </b-col>
            </b-row>
        </b-col>
        </b-row>
        <SuggestionsListView
        @recipeSelected="recipeSelected" :userState ="userState"
        v-if="plan && showSuggestions" :suggestions="suggestions" :plan="plan" :reloadCounter="reloadCounter" v-on:addRecipeToPlan="addRecipeToPlan"
        />
        <b-spinner v-if="!planFullyLoaded"  label="Spinning" variant="light" class="mt-2"></b-spinner>
        <div v-if="!showSuggestions && visible && planFullyLoaded" class="recipe-list-scrollable">
          <div v-for="(day, index) of Object.keys(this.recipeMap).filter(key => this.recipeMap[key].length > 0)" v-bind:key="index" class="blach" >
            <div v-if="day === 'unplanned' && planHasDayPlannedRecipes()">
            <hr style="border-top: 1px solid #b5b5b5"/>
            </div>
              <div v-if="day !== 'unplanned'">{{day[0].toUpperCase() + day.substring(1)}}</div>

              <RecipeListCard
              :reloadCounter="recipeCardReloadCounter"
              class="clickable mb-2"
              v-for="recipe in recipeMap[day]"
              v-bind:key="recipe.id"
              :recipe="recipe"
              :plan="plan"
              :planView="true"
              v-on:recipeUpdated="recipesUpdated"
              v-on:recipeSelected="recipeSelected"
              v-on:removeFromPlan="removeFromPlan"
              v-on:addRecipeToPlan="addRecipeToPlan"
              />
            </div>
        </div>
        <b-button-group class="mt-3 mb-3">
          <div>
            <b-button v-if="getPlanStatus() !== 'cooking' && plan.recipes > 0" squared @click="$router.push('/recipes')">{{plan.recipes.length === 0? 'This plan is empty! Add some recipes' : 'Add more recipes'}}</b-button>
            <b-button v-if="getPlanStatus() !== 'cooking' && plan.recipes > 0" class="bold" squared variant="primary" @click="setPlanStatus('SHOPPING')"><i class="material-icons icon-middle text-primary-mine">shopping_cart</i></b-button>
            <b-button id="delete-1" v-if="getPlanStatus() !== 'cooking' && plan.recipes > 0" squared class="btn-negative" v-b-modal="`modal-plan-${plan.id}`"><i class="material-icons icon-middle">delete</i></b-button>

          </div>
        </b-button-group>
        </b-collapse>

        <b-row class="mt-1" style="" align-v="center">
          <b-col cols="12">
            <SmallRecipeCards :plan="plan" :hideTime="getPlanStatus() !== 'cooking'" v-if="!visible" @recipeSelected="recipeSelected" :reloadCounter="recipeCardReloadCounter"/>
          </b-col>

        </b-row>
        <b-row class="mt-1" style="" align-v="center" v-if="getPlanStatus() === 'cooking'">
          <b-col cols="12">
            <b-button-group>
              <b-button class="mt-3 bold mr-1" variant="secondary" @click="setPlanStatus('SHOPPING')"><i class="material-icons icon-middle text-secondary-mine">shopping_cart</i> Back to Planning</b-button>
              <b-button class="mt-3 bold mr-1" variant="primary" @click="showLeftoverModal(plan)">Mark Done</b-button>
              <b-button id="delete-2" squared class=" mt-3 btn-negative" v-b-modal="`modal-plan-${plan.id}`"><i class="material-icons icon-middle">delete</i></b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <b-row class="mt-3" style="" align-v="center" v-if="getPlanStatus() === 'cooked'">
          <b-col cols="12">
            <b-button-group>
              <b-button v-if="getPlanStatus() === 'cooked'" class="bold" squared  variant="negative" @click="setPlanStatus('COOKING')"><i class="material-icons icon-middle text-grey">microwave</i> Back to Cooking</b-button>
            </b-button-group>
            <b-button v-if="plan.recipes.length > 0 && getPlanStatus() !== 'cooking' && getPlanStatus() !== 'cooked' && plan.recipes.length > 0" class="bold" squared  variant="primary" @click="setPlanStatus('COOKING')"><i class="material-icons icon-middle text-primary-mine">microwave</i> Start Cooking</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3" style="" align-v="center" v-if="getPlanStatus() === 'planning'">
          <b-col cols="12">
            <b-button-group>
              <b-button v-if="plan.recipes.length > 0 && getPlanStatus() !== 'cooking' && getPlanStatus() !== 'cooked' && plan.recipes.length > 0" class="bold" squared  variant="primary" @click="setPlanStatus('COOKING')"><i class="material-icons icon-middle text-primary-mine">microwave</i> Start Cooking</b-button>
              <b-button v-if="plan.recipes.length > 0 && getPlanStatus() !== 'cooking' && getPlanStatus() !== 'cooked' && plan.recipes.length > 0" id="delete-3" squared class="btn-negative" v-b-modal="`modal-plan-${plan.id}`"><i class="material-icons icon-middle">delete</i></b-button>

            </b-button-group>
          </b-col>
        </b-row>

        <div>
          <b-row class="mt-1" style="" align-v="center" >
          <b-col cols="12" style="padding: 0px !important;" class="text-center">
            <b-button v-b-toggle="`collapse-${plan.id}`" class="btn-transparent" v-if="plan.recipes.length > 0">
              <i class="material-icons text-primary-mine" v-if="!visible">add</i>
              <i class="material-icons  text-primary-mine" v-if="visible" >remove</i>
            </b-button>
            <b-button-group class="mt-2 mb-2" v-if="getPlanStatus() !== 'cooking' && plan.recipes.length === 0">
              <b-button  squared @click="setActivePlan">{{plan.recipes.length === 0? 'This plan is empty! Add some recipes' : 'Add more recipes'}}</b-button>
              <b-button v-if="(!archiving && !hideDeleteOption)" id="delete-4" squared class="btn-negative" v-b-modal="`modal-plan-${plan.id}`"><i class="material-icons icon-middle">delete</i></b-button>
              <b-button v-if="archiving" squared class="btn-negative" v-b-modal="`modal-plan-${plan.id}`"><b-spinner small label="Spinning" variant="light" class="mt-2"></b-spinner></b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <b-modal centered :id="`modal-plan-${plan.id}`" size="sm" title="BootstrapVue" hide-header>
          <div class="px-5 pt-4 pb-2">
            Are you sure you want to archive this plan?
          </div>
          <template #modal-ok="">
            <div @click="archivePlan">Yes Please</div>
          </template>
          <template #modal-cancel="">
            No Thanks
          </template>
        </b-modal>
      </div>
    </div>
</template>

<script>
import RecipeListCard from './RecipeListCard'
import moment from 'moment'
import SuggestionsListView from './SuggestionsListView'
import SmallRecipeCards from './SmallRecipeCards'
import Plan from '../models/Plan'
export default {
  components: {
    RecipeListCard, SuggestionsListView, SmallRecipeCards
  },
  props: ['plan','reloadCounter', 'index', 'reloadCounter2', 'hideSuggestions', 'cookingButtonOnly', 'plans', 'userState', 'hideDeleteOption'],
  data() {
    return {
      recipes: [],
      showSuggestions: false,
      suggestions: undefined,
      suggestionLoading: false,
      visible: false,
      archiving: false,
      planFullyLoaded : false,
      recipeCardReloadCounter: 0,
      recipeMap: {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "unplanned": []
      }
    }
  },
  methods: {
    planHasDayPlannedRecipes() {
      return this.recipes.filter(obj => obj.day).length > 0
    },
    getCreatedDate(plan) {
      return moment(this.plan.created).fromNow()
    },
    getCompletedDate(plan) {
      return moment(this.plan.updated).fromNow()
    },
    recipeSelected(recipeSelectedEvent) {
      this.$emit('recipeSelected', {recipe: recipeSelectedEvent.recipe, plan: this.plan})
    },
    setActivePlan(){
      this.$emit('setActivePlan', this.plan)
      this.$router.push("/recipes")
    },
    removeFromPlan(obj) {
      this.$emit("removeFromPlan", obj)
    },
    async addRecipeToPlan(addRecipeEvent) {
      this.$emit("addRecipeToPlan", addRecipeEvent)
    },
    async archivePlan() {
      this.archiving = true
      this.$emit('archivePlan', this.plan)
    },
    getPlanStatus() {
      if (!this.plan.planStatuses) return "Planning"
      return this.plan.planStatuses[0].toLowerCase().replace("shopping", 'planning')
    },
    showLeftoverModal() {
      this.$emit("showLeftoverModal", this.plan)
    },
    async recipesUpdated() {
      this.showSuggestions = false
      if (this.plan) this.recipes = this.plan.recipes
      this.recipeMap = {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "unplanned": []
      }
      for (var recipe of this.recipes) {
        if (recipe.day) {
          this.recipeMap[recipe.day.toLowerCase()].push(recipe)
        }else {
          this.recipeMap.unplanned.push(recipe)
        }
      }
    },
    async loadSuggestions() {
      if (this.visible) {
        this.suggestionLoading = true
        this.suggestions = undefined
        try {
          if (this.plan) {
            this.suggestions = await this.plan.getSuggestions(this.userState)
          }
          if (!this.suggestions || this.suggestions.length === 0) this.showSuggestions = false
        }catch (err) {
          console.error(err)
        }
        this.suggestionLoading = false
      }
    },
    async setPlanStatus(status) {
      await this.plan.setStatus(status)
      this.$emit('planStatusUpdated')
    }
  },
  mounted() {
    this.recipesUpdated()
    this.loadSuggestions()
  },
  watch: {
    reloadCounter() {
      this.archiving = false
      this.recipesUpdated()
      this.loadSuggestions()
      this.recipeCardReloadCounter++
    },
    async visible() {
      this.loadSuggestions()
      await this.plan.load()
      this.planFullyLoaded = true
      this.recipesUpdated()
    }
  }
}

</script>

<style>

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.incorporated-suggestions-button {
  background-color: var(--warning) !important;
}

</style>
