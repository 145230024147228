export default class StateLeftover {
  constructor(leftover) {
    Object.keys(leftover).forEach(key => {
      this[key] = leftover[key]
    })
  }
  isReservedByPlan(planId) {
    return this.reservations && this.reservations.filter(reservation => reservation.planId === planId).length > 0
  }
  isFullyReserved() {
    return this.reservations && this.reservations.map(reservation => reservation.quantity).reduce((total, currentValue) => total + currentValue, 0) >= this.quantity
  }
  amountAfterReservations() {
    if (!this.reservations || this.reservations.length === 0) return this.quantity
    let reservedQuantity = this.reservations.map(obj => obj.quantity ).reduce((p, c) => p + c)
    return this.quantity - reservedQuantity
  }

  hasEnoughForIngredient(ingredient) {
    return this.amountAfterReservations() >= ingredient.quantity
  }

  getFullReservationsForPlan(planId) {
    return this.reservations.filter(reservation => reservation.planId === planId).map(reservation => new FullReservation(reservation, this))
  }
}

class FullReservation {
  constructor(reservation, leftover) {
    this.ingredientId = leftover.ingredient.id
    this.quantity = reservation.quantity
    this.planId = reservation.planId
    this.unit = leftover.unit
    this.originalPlanId = leftover.planId
    this.name = leftover.ingredient.name
  }
}
