<template>
  <div class="mb-5" id="shopping-list">

    <ShoppingListForPlan @addRecipeToPlan="addRecipeToPlan" :userState="userState" @recipeSelected="recipeSelected" v-if="activePlan && userState" :plan="activePlan" :reloadCounter="reloadCounter" @planChanged="planChanged" />
  </div>
</template>

<script>
import ShoppingListForPlan from './ShoppingListForPlan'
export default {
  components: {
    ShoppingListForPlan
  },
  props: ['plans', 'reloadCounter', 'activePlan', 'userState'],
  data() {
    return {
      subReloadCounter: 0,
      morePlans: undefined,
      previousPlan: false
    }
  },
  methods: {
    async planChanged() {
      this.$emit("planChanged")
    },
    recipeSelected(recipe) {
      this.$emit('recipeSelected', {recipe: recipe})
    },
    async addRecipeToPlan(addRecipeEvent) {
      this.$emit('addRecipeToPlan', addRecipeEvent)
    },
  },
  mounted() {
  },
  watch: {
    reloadCounter() {
    }
  }
}

</script>

<style>

.strikethrough {
  text-decoration: line-through;
}

.align-start {
  text-align: start
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>
