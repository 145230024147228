<template>
  <div>
    <h2>Add Ingredient</h2>
    <b-button-group>
       <b-button id="submit-button" class="mt-2" @click="onSubmit" variant="primary">Submit</b-button>
       <b-button id="next-ingredient-button" class="mt-2"  @click="nextIngredient(false)" variant="secondary">Next</b-button>
       <b-button id="submit-and-next-button" class="mt-2"  @click="nextIngredient(true)" variant="warning">SubmitAndNext</b-button>
     </b-button-group>
    <b-form  >
      <b-row v-if="form.id">
        <b-col cols="10">
         <p id="ingredient-id">{{form.id}}</p>
       </b-col>
     </b-row>
        <b-row>
          <b-col cols="2">
           <label for="input-1">Name</label>
         </b-col>
          <b-col cols="8">
           <b-form-input
             id="input-1"
             v-model="form.name"
             placeholder="Enter Name"
             required
           ></b-form-input>
         </b-col>
         <b-col cols="1">
          <label for="input-1">Store</label>
        </b-col>
         <b-col cols="1">
           <b-form-checkbox
             v-model="form.store"
           ></b-form-checkbox>
        </b-col>
       </b-row>
       <b-row>
         <b-col cols="2">
          <label for="aisle-input">Aisle</label>
        </b-col>
         <b-col cols="7">
           <b-form-select
             id="aisle-input"
             v-model="form.aisle"
             :options="aisles"
           ></b-form-select>
        </b-col>
        <b-col cols="2">
         <label for="input-1">Freezable</label>
       </b-col>
        <b-col cols="1">
          <b-form-checkbox
            v-model="form.freezable"
          ></b-form-checkbox>
       </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
         <label for="life-input">Life</label>
       </b-col>
        <b-col cols="5">
          <b-form-input
            id="life-input"
            type="number"
            v-model="form.life"
          ></b-form-input>
       </b-col>
       <b-col cols="4">
        <label for="can-be-store-input">Can Be Store</label>
      </b-col>
       <b-col cols="1">
         <b-form-checkbox
           v-model="form.canBeStore"
         ></b-form-checkbox>
      </b-col>
      
     </b-row>
     <b-row>
      <b-col>
        <b-form-group label="Allergens" >
      <b-form-checkbox-group v-on:keydown.enter.prevent="nextIngredient(true)" 
        id="allergen-checkbox-group"
        v-model="form.allergens"
        :options="allergenOptions"
        name="allergens"
      ></b-form-checkbox-group>
    </b-form-group>
      </b-col>
     </b-row>
       <hr/>
       <p>Calories</p>
       <b-row v-for="(caloriesObj, index2) of form.calories" v-bind:key="`calories-${index2}`" class="mt-2">
         <b-col cols="2">
          <label for="input-1">Unit</label>
        </b-col>
         <b-col cols="2">
           <b-form-select
             id="input-3"
             v-model="caloriesObj.unit"
             :options="units"
           ></b-form-select>
        </b-col>
       <b-col cols="4">
         <b-form-input
           id="input-2"
           v-model="caloriesObj.quantity"
           placeholder="Enter quantity"
           type="number"
           step="any"
         ></b-form-input>
      </b-col>
     <b-col cols="3">
       <b-form-input
         id="input-2"
         v-model="caloriesObj.calories"
         placeholder="cals"
         type="number"
         step="any"
       ></b-form-input>
    </b-col>
    </b-row>
    <hr/>
    <p>Amounts</p>
       <b-row v-for="(amount, index) of form.amounts" v-bind:key="index" class="mt-2">
         <b-col cols="2">
          <label for="input-1">Unit</label>
        </b-col>
         <b-col cols="3">
           <b-form-select
             id="input-3"
             v-model="amount.unit"
             :options="units"
           ></b-form-select>
        </b-col>
       <b-col cols="4">
         <b-form-input
           id="input-2"
           v-model="amount.quantity"
           placeholder="Enter quantity"
           type="number"
           step="any"
         ></b-form-input>
      </b-col>
      <b-col cols="1">
       <label for="input-1">Partial</label>
     </b-col>
      <b-col cols="1">
        <b-form-checkbox
          v-model="amount.partial"
        ></b-form-checkbox>
     </b-col>
      </b-row>



      <b-button-group>
       <b-button id="submit-button" class="mt-2" @click="onSubmit" variant="primary">Submit</b-button>
       <b-button id="next-ingredient-button" class="mt-2"  @click="nextIngredient(false)" variant="secondary">Next</b-button>
       <b-button id="submit-and-next-button" class="mt-2"  @click="nextIngredient(true)" variant="warning">SubmitAndNext</b-button>
     </b-button-group>
   </b-form>

  </div>
</template>

<script>

import {createIngredient} from '../clients/RecipeClient'
export default {
  props: ['inputIngredient'],
  data() {
      return {
        allergenOptions : [
            'gluten' ,'gluten_with_alternative','meat', 'animal_product'
          ],
        form: {
          name: '',
          store: false,
          aisle: undefined,
          freezable: false,
          canBeStore: false,
          life: undefined,
          allergens: [],
          amounts: [{},{},{},{},{},{},{},{},{}],
          
          calories:
            [ {
              unit: undefined,
              quantity: undefined,
              calories: undefined
            },
            {
              unit: undefined,
              quantity: undefined,
              calories: undefined
            }],
          id: undefined
        },
        units: ['units','ml','grams', 'bag', 'tsp', 'tbsp', 'cube', 'rashers', 'rolls', 'tin', 'slices', 'bunch', 'pinch'],
        aisles: ['fruit and veg', 'meat and fish', 'tins', 'cooked meat and fresh pasta', 'rice, pasta and pulses','bakery and eggs', 'cereal', 'chocolate and sweets', 'spices and oils', 'condiments', 'dairy', 'home baking', 'world foods', 'freezer', 'vegan & vegetarian (chilled)'],
        formDefaults: {
          name: '',
          store: false,
          freezable: false,
          amounts: [{},{},{},{},{},{},{},{},{}],
          calories:
            [ {
              unit: undefined,
              quantity: undefined,
              calories: undefined
            },
            {
              unit: undefined,
              quantity: undefined,
              calories: undefined
            }],
          id: undefined
        }
      }
    },
    methods: {
      async onSubmit(event) {
        if (event) event.preventDefault()
        await createIngredient(this.form)
        Object.keys(this.formDefaults).forEach(key => this.form[key] = this.formDefaults[key])
        this.$emit('ingredientCreated')

      },
      createFormFromIngredient(ingredient) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = ingredient[key]
        })
        if (!this.form.amounts) this.form.amounts = []
        if (!this.form.allergens) this.form.allergens = []
        while (this.form.amounts.length < 10) this.form.amounts.push({})
        if (!this.form.calories) this.form.calories = []
        while (this.form.calories.length < 2) this.form.calories.push({
          unit: undefined,
          quantity: undefined,
          calories: undefined
        })
      },
      async nextIngredient(submit) {
        if (submit) await this.onSubmit()
        this.$emit('nextIngredient')
      }
    },
    mounted() {
      if (this.inputIngredient) {
        this.createFormFromIngredient(this.inputIngredient)
      }
    },
    watch: {
      inputIngredient() {
        if (this.inputIngredient) {
          this.createFormFromIngredient(this.inputIngredient)
        }
      }
    }
}

</script>

<style>

</style>
