import {getUserState, updateStateItem} from '../clients/RecipeClient'
import Ingredient from './Ingredient'
export default class ShoppingList {
  constructor(shoppingList) {
    if (shoppingList) {
      Object.keys(shoppingList).forEach(key => {
        if (key === 'ingredients') {
          this.ingredients = shoppingList.ingredients.map(ingredient => new Ingredient(ingredient))
        }else {
          this[key] = shoppingList[key]
        }
      })
    }
    ensureShoppingListHasAllListTypes(this)
  }
  createList(recipes, userState) {
    this.ingredients = consolidateIngredients(recipes, this.ingredients)
    setStorePropertyOnIngredientsThatAreInUserStore(this.ingredients, userState)
  }
  moveStoreIngredientToList(ingredient) {
    var thisIngredient = this.ingredients.filter(obj => obj.name === ingredient.name)[0]
    this.storeIngredientsInList.push(ingredient.name)
  }
  removeStoreIngredientFromList(ingredient) {
    var thisIngredient = this.ingredients.filter(obj => obj.name === ingredient.name)[0]
    this.storeIngredientsInList.splice(this.storeIngredientsInList.indexOf(thisIngredient.name), 1)
  }
  getMainShoppingList(options) {
    if (!options) options = {}
    if (userStateIsProvided(options)) setStorePropertyOnIngredientsThatAreInUserStore(this.ingredients, options.userState)
    var mainList = ingredientsNotInStoreOrChecked(this.ingredients, this.storeIngredientsInList, options.hideBought)
    if (userStateIsProvided(options)) removeQuantitiesOfIngredientsWhereLeftoversAreBeingUsedUp(options.userState, options.plan, mainList)
    return ingredientsWithAnyQuantityRemaining(mainList).map(obj => new Ingredient(obj))
  }
  getStoreList(options) {
    return this.ingredients.filter(ingredient => (ingredient.store || (options && options.userState && options.userState.hasStoreItem(ingredient.id))) && this.storeIngredientsInList.indexOf(ingredient.name) === -1)
  }
  getLeftovers(options) {
    const mainList = this.getMainShoppingList(options)
    const leftovers = mainList.map(ingredient => ingredient.getLeftover()).filter(obj => obj)
    return leftovers
  }
  getNewStoreCupboardItems(options) {
    return this.getMainShoppingList(options).filter(obj => obj.canBeStore)
  }

  async checkOrUncheckItem(item, plan) {
    if (!item.unit || !item.id) throw new Error('Missing required parameter')
    let thisIngredient = this.ingredients.filter(obj => obj.id === item.id)[0]
    thisIngredient.checked = item.checked
    thisIngredient.addToStore = item.addToStore
    if (thisIngredient.hasLeftovers()) {
      item.checked && !item.addToStore? await addItemToState(thisIngredient, plan) : await removeItemFromState(thisIngredient, plan)
    }
  }

  getIngredientsByAisle(options) {
    let aislesDictionary = {unknown: []}
    for (var ingredient of this.getMainShoppingList(options)) {
      addIngredientToAislesDictionary(ingredient, aislesDictionary)
    }
    return convertAislesDictionaryToArray(aislesDictionary)
  }

  hasUncheckedItems(options) {
    return this.getMainShoppingList(options).filter(obj => !obj.checked).length > 0
  }
  async ensureStateLeftoversAreCorrect(plan, userState) {
    for (var ingredient of this.ingredients) {
      ingredient = new Ingredient(ingredient)
      if (ingredient.checked && ingredient.hasLeftovers() && leftoverInStateDoesntMatch(ingredient, userState, plan)) {
        await addItemToState(ingredient, plan)
      }
      if(ingredientDoesntHaveLeftoversButStateDoes(ingredient, userState, plan)) {
        await removeItemFromState(ingredient, plan)
      }
    }
    for (const leftover of userState.leftoversForPlan(plan.id)) {
      if (!leftoverIsInIngredientsList(leftover, this.ingredients)) await removeItemFromState(leftover.ingredient, plan)
    }

    //User state will have been modified here so need to reload it
  }
}

function leftoverIsInIngredientsList(leftover, ingredientsList) {
  return ingredientsList.filter(ingredient => ingredient.id === leftover.ingredient.id && ingredient.unit === leftover.unit).length > 0
}

function leftoverInStateDoesntMatch(ingredient, userState, plan) {
  const matchingLeftover = userState.matchingLeftoverForPlan(ingredient, plan.id)
  const ingredientLeftover = ingredient.getLeftover()
  return !matchingLeftover || (ingredientLeftover && matchingLeftover.quantity !== ingredientLeftover.quantity)
}

function ingredientDoesntHaveLeftoversButStateDoes(ingredient, state, plan) {
  return !ingredient.hasLeftovers() && state.matchingLeftoverForPlan(ingredient, plan.id)
}

function convertAislesDictionaryToArray(aislesDictionary) {
  let aislesArray = Object.keys(aislesDictionary).map(key => {
    return {
      name: key,
      ingredients: aislesDictionary[key]
    }
  })

  var filteredArray = aislesArray.filter(obj => obj.ingredients.length > 0)
  var sortedArray = filteredArray.sort((a,b)=> a.name > b.name? 1: -1)
  return sortedArray
}

function addIngredientToAislesDictionary(ingredient, aislesDictionary) {
  if (ingredient.aisle) {
    addAisleToDictionaryIfNotExists(ingredient.aisle, aislesDictionary)
    aislesDictionary[ingredient.aisle].push(ingredient)
  }else {
    aislesDictionary.unknown.push(ingredient)
  }
}

function addAisleToDictionaryIfNotExists(aisle, aislesDictionary) {
  if (!aislesDictionary[aisle]) aislesDictionary[aisle] = []
}

async function addItemToState(ingredient, plan) {
  var updateInput = {
    ingredient: ingredient,
    planId:plan.id,
    life: ingredient.life,
    action: "bought",
    unit: ingredient.unit,
  }
  var leftoverAmounts = ingredient.getLeftover()
  updateInput.quantity = leftoverAmounts.quantity
  await updateStateItem(updateInput)
}

async function removeItemFromState(ingredient, plan) {
  var updateInput = {
    ingredient:ingredient,
    planId:plan.id,
    action: "cancelled",
    unit: ingredient.unit
  }
  await updateStateItem(updateInput)
}

function userStateIsProvided(options) {
  return options && options.userState
}

function setStorePropertyOnIngredientsThatAreInUserStore (ingredients, userState) {
  for (var ingredient of ingredients) {
    if (ingredientHasBeenAddedToUsersStore(ingredient, userState)) {
      ingredient.store = true
    }
  }
}

function ingredientHasBeenAddedToUsersStore(ingredient, userState) {
  return userState && userState.storeItems && userState.storeItems.map(obj => obj.id).indexOf(ingredient.id) > -1
}
function ensureShoppingListHasAllListTypes(shoppingList) {
  if (!shoppingList.ingredients) shoppingList.ingredients = []
  if (!shoppingList.storeIngredientsInList) shoppingList.storeIngredientsInList = []
  if (!shoppingList.customItems) shoppingList.customItems = []
}

function removeQuantitiesOfIngredientsWhereLeftoversAreBeingUsedUp(userState, plan, ingredientsOnShoppingList) {
  var reservations = userState.getReservationsForPlan(plan)
  for (var reservation of reservations) {
    var ingredientWithReservation = ingredientsOnShoppingList.filter(item => reservation.ingredientId === item.id && reservation.unit === item.unit)[0]
    if (ingredientWithReservation) ingredientWithReservation.quantity = ingredientWithReservation.quantity - reservation.quantity
  }
}

function ingredientsWithAnyQuantityRemaining(list) {
  return list.filter(obj => obj.quantity > 0)
}

function ingredientsNotInStoreOrChecked (ingredients,storeIngredientsInList, hideBought) {
  return ingredients.filter(ingredient => ingredientIsNotInStoreOrChecked(ingredient, storeIngredientsInList, hideBought))
}
function ingredientIsNotInStoreOrChecked (ingredient, storeIngredientsInList, hideBought) {
  return (ingredient.store !== true || storeIngredientsInList.indexOf(ingredient.name) > -1) && (!hideBought || !ingredient.checked)
}

function consolidateIngredients (recipes, currentIngredients) {
  let ingredientsDictionary = {}
  for (let recipe of recipes) {
    addRecipeIngredientsToIngredientsDictionary(ingredientsDictionary, recipe)
  }

  const ingredientsList = convertIngredientsDictionaryToArray(ingredientsDictionary)
  markIngredientsAsCheckedIfAlreadyInListAndChecked(ingredientsList, currentIngredients)
  
  return ingredientsList
}

function markIngredientsAsCheckedIfAlreadyInListAndChecked (ingredientsList, currentIngredients) {
  for (var ingredient of ingredientsList) {
    const currentIngredient = currentIngredients.find(obj => obj.id === ingredient.id)
    ingredient.checked = currentIngredient && currentIngredient.checked && currentIngredient.unit === ingredient.unit && currentIngredient.quantity >= ingredient.quantity
  }
}

function convertIngredientsDictionaryToArray(ingredientsDictionary) {
  var ingredientsList = []
  Object.keys(ingredientsDictionary).forEach(ingredientName => {
      var ingredient = new Ingredient(ingredientsDictionary[ingredientName])
      ingredient.name = ingredientName
      Object.keys(ingredientsDictionary[ingredientName].units).forEach(unit => {
        ingredient.unit = unit
        ingredient.quantity = ingredientsDictionary[ingredientName].units[unit]
        ingredient.partial = ingredient.isPartial()
        ingredientsList.push(new Ingredient(ingredient))
      })
  })
  return ingredientsList
}

function addRecipeIngredientsToIngredientsDictionary (ingredientsObj, recipe) {
  for (var ingredient of recipe.ingredients) {
    addIngredientToDictionaryIfNotThere(ingredient, ingredientsObj)
    addIngredientInThisUnitToDictionaryIfNotThere(ingredient, ingredientsObj)
    addQuantityOfIngredientToDictionaryIngredient(ingredient, ingredientsObj, recipe)
  }
}

function addIngredientToDictionaryIfNotThere(ingredient, dictionary) {
  if (!dictionary[ingredient.name]) dictionary[ingredient.name] = new IngredientForDictionary(ingredient)
}

function addIngredientInThisUnitToDictionaryIfNotThere(ingredient, dictionary) {
  if (!dictionary[ingredient.name].units[ingredient.unit]) dictionary[ingredient.name].units[ingredient.unit] = 0
}

function addQuantityOfIngredientToDictionaryIngredient(ingredient, dictionary, recipe) {
  let quantity = ingredient.quantity
  if (recipe.servings) quantity = quantity / 2 * recipe.servings
  dictionary[ingredient.name].units[ingredient.unit] += quantity
}

class IngredientForDictionary {
  constructor(ingredient) {
    this.id = ingredient.id,
    this.units = {},
    this.store = ingredient.store,
    this.amounts = ingredient.amounts,
    this.aisle = ingredient.aisle,
    this.freezable = ingredient.freezable,
    this.life = ingredient.life,
    this.canBeStore = ingredient.canBeStore
  }
}
