<template>
  <div>
    <b-card @click="recipeSelected(recipe)" no-body style="border: 0px" :id="`recipe-card-${recipe.id}`"
    :class="`${variant === 'small'? 'card-body-small' : ''} card-small fill`" >
      <b-container id="new-recipe-banner" class="text-grey text-top pt-1 m-0" style="max-width: 1000px !important; background: var(--tertiary); font-weight: bold" v-if="recipe.isNew()" block>
        <b-row class="justify-content-center" no-gutters align-v="center">
          <b-col cols="auto">
            <i class="material-icons icon-middle text-primary-mine" >star</i>
          </b-col>
          <b-col cols="auto" align-v="middle">
            <p class="text-primary-mine">NEW</p>

          </b-col>
          <b-col cols="auto">
            <i class="material-icons icon-middle  text-primary-mine" style="color: var(--primo)" >star</i>
          </b-col>
        </b-row>
      </b-container>
      <b-row no-gutters class="fill" >
      <b-col :cols="'auto'" id="recipe-image" v-if="recipe.imageUrl">
        <b-card-img-lazy id="recipe-image" :src="recipe.imageUrl" alt="Image" :class="`rounded-0 recipe-card-image ${variant ==='small' || planView? 'recipe-card-image-small': ''}`"></b-card-img-lazy>
      </b-col>
      <b-col cols="" class="fill">
        <b-card-body class="fill ml-4 mt-2">
          <b-row>
            <h5 class="responsive-h5 mr-4" style="word-wrap: normal">{{recipe.title}}</h5>

          </b-row>
          <div :class="variant == 'small'? 'mt-1' : 'mt-2'">
            <b-row align-h="start">
              <b-col class="p-0" cols="auto" align-self="start">
                <div>
                <p class="grey-text" id="servings-text"><i class="material-icons grey-text icon-middle" style="font-size:22px !important"> people</i> {{ recipe.servings || 2 }}</p>
              </div>
              </b-col>
            </b-row>
            <b-row align-h="start" id="no-leftover-banner" v-if="plan && plan.recipes.length === 0 && !recipe.hasPartials()"> 
              <b-col class="p-0" cols="auto" align-self="start">
                <div>
                  <p class="grey-text"><i class="material-icons  icon-middle mr-1" style="font-size:22px !important;  color: var(--secondo)">star</i>No leftovers</p>
              </div>
              </b-col>
            </b-row>
            <b-row align-h="start">
              <b-col class="p-0" cols="auto" align-self="start">
                <p class="grey-text body-text">{{recipe.time}} mins</p>
              </b-col>
            </b-row>
            <b-row align-h="start">
              <b-col class="p-0"  cols="auto" align-self="start" id="recipe-calories" v-if="calories">
                <p class="grey-text body-text">{{calories.toFixed(0)}}cals</p>
              </b-col>
            </b-row>
          </div>
          <div class="bottom-right" v-if="!hideButton">
            <b-row no-gutters align-v="baseline">
              <b-col >
                <div v-if="plan && plan.planStatuses && plan.planStatuses.indexOf('COOKED') === -1">
                  <b-button v-if="!inList" @click.stop="addRecipeToPlan()" :id="`button-add-recipe-${recipe.id}`" squared variant="primary" size="sm" >Add to plan</b-button>
                  <b-button v-if="inList" @click.stop="removeRecipeFromPlan()" :id="`button-remove-recipe-${recipe.id}`" squared variant="negative" size="sm">Remove from plan</b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-col>
    </b-row>
    </b-card>
  </div>
</template>

<script>

export default {
  props: ['recipe', 'plan', 'planView', 'reloadCounter', 'hideButton', 'variant', 'plans'],
  data() {
      return {
        inList: false,
        calories: undefined
      }
    },
    methods: {

      recipeSelected(recipe) {
        this.$emit('recipeSelected', recipe)
      },
      addRecipeToPlan() {
        this.inList = true
        this.$emit('addRecipeToPlan', {plan: this.plan, recipe: this.recipe})
      },
      removeRecipeFromPlan() {
        this.inList = false
        this.$emit('removeRecipeFromPlan', {plan: this.plan, recipe: this.recipe})
      },
    },
    mounted() {
      this.inList = this.plan && this.plan.containsRecipe(this.recipe)
      this.calories = this.recipe.getCalories()
    },
    watch: {
      reloadCounter() {
        this.inList = this.plan && this.plan.containsRecipe(this.recipe)
        this.calories = this.recipe.getCalories()
      },
    }
}

</script>

<style>

.recipe-card-image {
  padding: 5px;
  height: 150px;
  width: 150px;
}

.recipe-card-image-small {
  height: 100px;
  width: 100px;
}

.card-body {
  padding: 0px !important;
  border-radius: 0px;
  border:0px;
  border-bottom: 0px solid #f8f8f8;
}

.card-body-small {
  padding: 0px !important;
  border-radius: 0px;
  border:0px;
  height: 10vh;
}

.fill {
  height: auto;
}

.card-padding {
  padding-left: 10px;
  padding-bottom: 0px !important;
  padding-top: 10px;
  padding-right: 0px !important
}

.shadow {
  padding: 10px;
  box-shadow: 5px 10px #888888;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px
}
.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 5px
}

.small-modal {
  width: 10% !important
}

</style>
