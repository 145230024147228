<template>
  <div>
      <div @click="optionSelected(leftover, option)" style="height:100%" :class="`text-center p-1 clickable ${selected? 'background-secondary': ''}`" >
        <i :class="`material-icons  ${selected? 'material-icons-light': ''} align-bottom text-center`" >{{option.icon}}</i>
        <p style="height: 100%" class="text-small icon-middle">{{option.text}}</p>
      </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: ['option', 'leftover', 'leftoverItemReloadCounter'],
  data() {
    return {
      selected: false
    }
  },
  methods: {
    optionSelected(leftover, option) {
      this.selected = true
      this.$emit("optionSelected", {leftover: leftover, option: option})
    },
    getClassForOption(leftover, option) {
      var className = leftover.option == option.name? 'background-primary' : ''
      return className
    },

  },
  mounted() {
    this.selected = this.leftover.option === this.option.name
  },
  watch: {
    leftoverItemReloadCounter() {
      this.selected = this.leftover.option === this.option.name
    }
  }
}

</script>

<style>
.blank-footer {
  background-color: #00000000;
  padding: 0px;
  margin-bottom: 5px;
  border-top: 0px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.undo-button {
}
</style>
