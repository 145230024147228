<template>
  <div>
    <b-card
      class="card-small no-border pt-3" >
      <div v-if="suggestion.recipes && leftovers && leftovers.length === 0" class="py-2 text-grey" style="font-weight: bold" block>
        <i class="material-icons align-bottom" style="color: var(--secondo)" >star</i>
        Add {{suggestion.recipes.length === 1? 'this' : 'these'}} and you'll have no leftovers!
        <i class="material-icons align-bottom" style="color: var(--secondo)" >star</i>
      </div>
      <div class="px-3 pb-2">
      <h5 class="m-2">Option {{index + 1}}</h5>
      <div>
        <RecipeListCard
        class="clickable mb-3 card-border"
        v-for="recipe of suggestion.recipes"
        :recipe="recipe"
        :plan="plan"
        v-bind:key="recipe.id"
        :hideButton="true"
        variant="small"
        @recipeSelected="recipeSelected"
        />
      </div>
      <b-col cols="auto" class="mb-2 mt-2">
        <b-button @click.stop="addRecipeToPlan" squared variant="primary" size="sm" class="bold">
          <div v-if="!loading">Add {{suggestion.recipes.length}} recipe{{suggestion.recipes.length === 1? '': 's'}} to plan</div>
          <b-spinner v-if="loading" small label="Spinning" class="mt-2"></b-spinner>
        </b-button>
      </b-col>
      <hr/>
      <p class="mb-1 text-grey">You'll have used up these leftovers:</p>
      <div>
        <ShoppingListItem v-for="leftover of usedLeftovers" :item="leftover" v-bind:key="leftover.id + leftover.life" />
      </div>
      <div class="mb-2" >
         <b-button v-if="leftovers && leftovers.length > 0" @click="showingAllLeftovers = !showingAllLeftovers" squared class="m-1  align-bottom">{{showingAllLeftovers? 'Hide':`Show`}} remaining leftovers  {{showingAllLeftovers? '':`(${leftovers? leftovers.length: ''})`}}
             <i class="material-icons nav-icon align-bottom" >{{showingAllLeftovers? 'expand_less' :'expand_more'}}</i>
          </b-button>
         <b-collapse id="leftovers-collapse" v-model="showingAllLeftovers" >
           <ShoppingListItem v-for="leftover of leftovers" :item="leftover" v-bind:key="leftover.id" :showLifeTooltip="true"/>
        </b-collapse>
      </div>
    </div>
    </b-card>
  </div>
</template>

<script>
import RecipeListCard from './RecipeListCard'
import ShoppingListItem from './ShoppingListItem'
export default {
  components: {
    RecipeListCard, ShoppingListItem
  },
  props: ['plan', 'suggestion', 'index', 'userState'],
  data() {
    return {
      leftovers: undefined,
      usedLeftovers: undefined,
      showingAllLeftovers: false,
      loading: false
    }
  },
  methods: {
    async addRecipeToPlan() {
      this.loading= true
      this.$emit('addRecipeToPlan', {recipe: this.suggestion.recipes[0]})
    },
    async createPlanAndAdd(recipe) {
      this.$emit('createPlanAndAdd', recipe)
    },
    async removeFromPlan(obj) {
      this.$emit('removeFromPlan', obj)
    },
    recipeSelected(recipe) {
      this.$emit('recipeSelected', recipe)
    },
    loadLeftoverLists() {
      this.usedLeftovers = this.plan.getLeftoverDifferenceWithSuggestion(this.suggestion, this.userState).usedUpLeftovers
      this.leftovers = this.suggestion.partials.map(obj => {
        obj.quantity = obj.leftoverQuantity
        if (this.userState && this.userState.leftovers) {
          var matchingStateItem = this.userState.leftovers.filter(stateItem => stateItem.ingredient.id === obj.ingredientId && stateItem.remainingLife === obj.life)[0]
          if (matchingStateItem && matchingStateItem.action === 'froze') obj.frozen = true
        }
        return obj
      }).filter(obj => !obj.life || obj.life < 91).filter(obj => stateItemIsNotForThisPlan(obj, this.plan, this.userState))
      this.loading = false
    }
  },
  mounted() {
    this.loadLeftoverLists()
  },
  watch: {
    reloadCounter() {
      this.loadLeftoverLists()
    },
    suggestion() {
      this.loadLeftoverLists()
    }
  }
}

function stateItemIsNotForThisPlan(item, plan, userState) {
  if (!item.fromState) return true
  return userState.leftovers.filter(leftover => leftover.id === item.ingredientId && leftover.planId === plan.id).length === 0
}

</script>

<style>

.strikethrough {
  text-decoration: line-through;
}

.align-start {
  text-align: start
}

.card-border {
  padding-bottom: 20px;
}

</style>
