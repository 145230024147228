import { Auth } from 'aws-amplify';
import {getUser, updateUser} from '../clients/RecipeClient'

export default class User {
  constructor() {
  }
  async loadUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      Object.keys (user).forEach(key => this[key] = user[key])
    }catch (err) {
      console.log(err)
    }
  }
  async signOut() {
    try {
        await Auth.signOut();
        window.location.href = '/'
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
  async loadAdditionalUserData() {
    var storedUser = await getUser()
    if (!storedUser) {
      storedUser = await updateUser({created: new Date()})
    }
    Object.keys (storedUser).forEach(key => this[key] = storedUser[key])
  }
  async isAdmin() {
    await this.loadUser()
    return this.signInUserSession.idToken.payload['cognito:groups'].indexOf("admin") > -1
  }
  async updateCookies() {
    await updateUser({cookiesEnabled: this.cookiesEnabled})
  }
  async setGuideSeen() {
    await updateUser({guideSeen: true})
  }

}
