<template>
  <div>
    <div class="full-page">
      <b-spinner class="mt-5" label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
export default {
  props: [],
  data() {
      return {

      }
    },
    methods: {
    },
    async mounted() {
      var signedIn = false
      //for (var i =0; i < 5; i++) {
      try {
        var user = await Auth.currentAuthenticatedUser()
        signedIn = true
        if (user) {
          this.$router.push('/landing')
        }
        //i = 5
      }catch(err) {
        console.log("NO USER AUTHENTICATED")
        console.error("Issue with authentication from Google.")
        this.$router.push("/login")
        //await sleep(1000);
      }
      if (!signedIn) Auth.federatedSignIn({provider: 'Google' })
    }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

</script>

<style>

</style>
