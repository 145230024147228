<template>
  <div>
    <b-container  fluid="lg"  >
      <b-row class="mb-4">
        <b-col>
          <h1 class="bold mt-lg-4 mt-2 text-grey" >How It Works</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col >
          <div v-for="page of pages" v-bind:key="page.title">
            <b-card class="mb-3 p-4">
              <div class="d-block d-md-none">
              <b-row >
                <b-col>
                  <h3 class="mb-3 text-grey">{{page.title}}</h3>
                </b-col>
              </b-row>
              <b-row align-v="center">
                <b-col cols="12" class="d-block d-md-none">
                  <b-img center class="mb-4" rounded="true" style="max-height:300px" fluid :src="require(`../assets/images/${page.image}`)"/>
                </b-col>
                <b-col cols="12" md="6">
                  <p class="text-grey text-sm-left text-center" style="font-size:16px" v-html="page.text"></p>
                </b-col>
            </b-row>
          </div>
        <div class="d-none d-md-block">
            <b-row >
              <b-col cols="6">
                <b-row align-v="center">
                  <b-col cols="12">
                    <h3 class="mb-3 text-grey">{{page.title}}</h3>
                  </b-col>
                </b-row>

                  <b-row style="height:80%" align-v="center">
                  <b-col cols="12" >
                    <p class="text-grey text-sm-left text-center d-block d-md-none" style="font-size:16px" v-html="page.text"></p>
                    <p class="text-grey text-sm-left text-center d-none d-md-block" style="font-size:18px" v-html="page.text"></p>
                  </b-col>
                </b-row>

              </b-col>
              <b-col cols="6">
                  <b-img center class="mb-4" rounded="true" style="max-height:400px" fluid :src="require(`../assets/images/${page.image}`)"/>
              </b-col>
            </b-row>
          </div>
          </b-card>

          </div>
        </b-col>

      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
  },
  data() {
    return {
      pages :[
        {
          title: "Get Inspired",
          text: "Scroll through our specially selected list of recipes. Every week you'll have a new list of recipes to choose from, so you'll never get bored! <br><br> Our recipes are designed to be easy to make mid-week and use only what is widely available in supermarkets.",
          image: "guide-recipe-scroll.gif",
        },
        {
          title: "Pick your recipes for every shop",
          text: "Create plans and add as many or as few recipes as you like. <br><br> Add recipes based on when you're going shopping. If you're shopping for 1 meal, add 1 meal to the plan, if it's for the whole week, add more.",
          image: "guide-add-recipe.gif",
        },
        {
          title: "Plan smart",
          text: "Look out for our recommendations on recipes you can add to reduce how many leftovers you have. Our recipes use sensible amounts of packet sizes you get in supermarkets so if you have leftovers we can match things easily for you.",
          image: "guide-suggestion.png",
        },
        {
          title: "Shop",
          text: "We'll create your shopping list for you and tell you where in the shop to find them. When you buy your ingredients you can tick them off. <br><br>You can even add your own items, so you don't need to manage multiple lists",
          image: "guide-shopping.gif",
        },
        {
          title: "Cook",
          text: "When you're ready to cook, go to your plan page, select your recipe and we'll walk you through the steps",
          image: "guide5.png",
        },
        {
          title: "Track your leftovers",
          text: "If you do have leftovers, we'll ask you what you did with them so we can help you use up anything you have left",
          image: "guide6.png",
        }

      ]
    }
  },
  methods: {


  },
  mounted() {

  }

}

</script>

<style>

</style>
