<template>
  <div>
    <NavBarPublic />
    <b-container  class="pb-5 fill-screen nav-padding-top" style="background-color: white"  >
        <Login v-if="$route.name==='Login'"/>
        <HowItWorks v-if="$route.name==='HowItWorks'" />
        <PublicRecipes v-if="$route.name==='PublicRecipes'" />
        <Blog v-if="$route.name==='WhyIsFoodWasteImportant'" />
        <div v-if="$route.name === 'Home'">
          <b-row >
            <b-col cols="12" class="mb-3 mt-lg-4">

                <b-row align-h="center" class="my-5">
                  <b-col cols="12" md="6">
                    <p class="pop-text text-center " style="font-size:25px; ">Goodbye recipe boxes</p>
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-col cols="12" md="6">
                    <p class="text-center text-grey p-3" style="font-size:16px">Try the recipe planning tool that gives you a curated list of delicious recipes every week and helps you plan your shopping in a waste conscious way.</p>
                  </b-col>
                </b-row>

            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mb-3  mt-1 text-center">
            <b-button to="/login" pill size="lg" variant="second"><p >Sign Up Now</p></b-button>
            </b-col>
          </b-row>
          <b-row >
            <b-col cols="12" lg="4" class="mb-3 ">
              <b-card class="p-4" style="height: 600px">
                <b-row>
                  <b-col>
                    <h3 class="mb-1 text-center text-grey" style="font-size:26px !important">Eat Well</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="mb-3  text-center text-grey">Choose from our delicious, mid-week suitable recipes</p>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col cols="12" >
                    <b-img center class="mb-4" rounded="true" style="max-height:400px" fluid :src="require(`../assets/images/guide-add-recipe.gif`)"/>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-grey text-center d-none " style="font-size:18px" ></p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col  cols="12" lg="4" class="mb-3">
              <b-card class="p-4" style="height: 600px">
                <b-row>
                  <b-col>
                    <h3 class="mb-3 text-center text-grey" style="font-size:26px !important">Shop Confidently</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="mb-3  text-center text-grey">Know what you're buying and where it is</p>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col cols="12" >
                    <b-img center class="mb-4" rounded="true" style="max-height:400px" fluid :src="require(`../assets/images/guide-shopping.gif`)"/>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-grey text-center d-none " style="font-size:18px" ></p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4" class="mb-3 ">
              <b-card class="p-4" style="height: 600px">
                <b-row>
                  <b-col>
                    <h3 class="mb-3 text-center text-grey" style="font-size:26px !important">Waste Less</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="mb-3  text-center text-grey">Build your plan around recipes that mean you'll have nothing to throw away at the end of the week</p>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col cols="12" >
                    <b-img center class="mb-4" rounded="true" style="max-height:400px" fluid :src="require(`../assets/images/guide-suggestion.gif`)"/>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-grey text-center d-none " style="font-size:18px" ></p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

        </div>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import Login from './Login'
import BootstrapVue from 'bootstrap-vue'
import User from '../models/User'
import NavBarPublic from './NavBarPublic'
import HowItWorks from './HowItWorks'
import Blog from './Blog'
import PublicRecipes from './PublicRecipes'
Vue.use(BootstrapVue)

export default {
  name: 'App',
  components: {
    Login, NavBarPublic, HowItWorks, PublicRecipes, Blog
  },
  data() {
    return {
      user: undefined,
      loaded: false,
      activePage: undefined
    }
  },
  methods: {
    async loadUser() {
      this.loaded = false
      this.user = new User()
      await this.user.loadUser()
      if (this.user.signInUserSession) {
        this.$router.push("/landing")
      }
      this.loaded = true
    }
  },

  async created() {
    await this.loadUser()
  },
  mounted() {
    if (window.location.hostname.indexOf("www") == 0) {
      window.location = window.location.href.replace("www.","");
    }
  },
  watch: {
  },
}

function convertErrorMessage(message) {
  if (message === 'User already exists' || message === "An account with the given email already exists.") return 'It looks like you already have an account , please try signing in'
  if (message.indexOf("1 validation error detected: Value at 'password' failed to satisfy constraint") > -1 || message.indexOf("Password did not conform with policy") > -1) return "Your password must be at least 8 characters long, contain upper and lower case characters and at least 1 special character"
  if (message === 'Incorrect username or password.') return 'No user found with those details - if you registered an account before 6th July you may need to create a new one!'
  if (message === 'User is not confirmed.') return ''
  return message
}

</script>

<style>

.nav-fill {
  width:100%;
  margin-left: 20px;
  margin-right: 20px
}
.fade-in { -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 4s; /* Firefox < 16 */
        -ms-animation: fadein 4s; /* Internet Explorer */
         -o-animation: fadein 4s; /* Opera < 12.1 */
            animation: fadein 4s;

}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.white-background {
  background: white;

}

.fill-screen {
  min-height: 100vh;
  min-width: 100vw;
}

.logo-main {
  height: fit-content;
  width: fit-content;
  border: calc(0.18em) solid var(--text-secondary);
  background-color: transparent;
  color: var(--text-secondary);
  font-weight: 900;
  font-size: calc(2vw + 0.5em) ;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.2em;
  font-size: 30px
}

.pop-text {
  color: var(--text-secondary);
  font-weight: 900;
  font-size: 20px ;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.15em;
  padding: 20px;
}

.logo-home-page {
  height: fit-content;
  width: fit-content;
  background-color: transparent;
  color: var(--text-secondary);
  font-weight: 900;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.2em;
  font-size: 30px
}

.logo-small {
  height: fit-content;
  width: fit-content;
  border: calc(0.5em) solid var(--text-secondary);
  background-color: transparent;
  color: var(--text-secondary);
  font-weight: 900;
  font-size: calc(2vw + 0.2em) ;
  line-height: 1.5;
  font-family: Tahoma, sans-serif;
  letter-spacing: 0.2em;
}

.about-text-area {
  max-height: 80vh;
  overflow-y: scroll;
}

.about-text-area {

    /* mask fade distance, for use in mask calculations */
    --mask-height: 20px;

    /* If content exceeds height of container, overflow! */
    padding-bottom: var(--mask-height);
    padding-top: 15px;

    /* Keep some space between content and scrollbar */
    padding-right: 20px;

    /* The CSS mask */

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );

    /* Here we scale the content gradient to the width of the container
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - 40px) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), 40px;

    /* Position the content gradient in the top left, and the
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
}

.line-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--text-secondary);
  line-height: 0.1em;
}

.line-with-text span {
  padding: 0 10px;
  font-weight: bold;
  color: var(--text-secondary);
  background-color: white;
}

.sign-in-with-google-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.learn-more-button {
  width:100vw;
  height: 80px;
  position: fixed;
  bottom: 0%
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}


@keyframes hideAnimation {
  to   { visibility: hidden; }
}

</style>
