import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import mitt from 'mitt';
const emitter = mitt();
import router from '@/router'
import VueApexCharts from 'vue-apexcharts'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import VueGtag from "vue-gtag";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

awsconfig.Auth.oauth.redirectSignIn = process.env.VUE_APP_AMPLIFY_REDIRECTSIGNIN
awsconfig.Auth.oauth.redirectSignOut = process.env.VUE_APP_AMPLIFY_REDIRECTSIGNOUT
Amplify.configure(awsconfig)
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueGtag, {
  config: { id: "G-YVFHZGET9K" },
  enabled: false
});
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
